import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { toaster } from '@teladochealth/components';

import { ApiError, Button, PageHeader, Translation } from '../../../components';
import { service } from '../../../services';
import { api, urlSchema, useFetch } from '../../../utils';
import { Patient } from '../../../models';
import validationSchema from '../../../models/Patient/validationSchema';

const Update = ({ history }) => {
  const { patientId } = useParams();
  const { formatMessage } = useIntl();
  const [patient, isPatientLoading] = useFetch(api.patients.get(patientId));

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.patients.put(patientId), {
        ...values,
        phoneNumber: values.phoneNumber
          ? values.phoneNumber.replace('+', '00')
          : '',
        gender: Number(values.gender),
        mobileNumber: values.mobileNumber.replace('+', '00'),
        birthdate: moment(values.birthdate).format('YYYY-MM-DD'),
      })
      .then(({ data }) => {
        if (data.succeeded === false) {
          toaster({
            title : <Translation id='Toasts.Request.Fail' />,
            text: (
              <ul className='m-0 pl-3'>
                {data.errorSet.map((error, index) => (
                  <li key={index} className='m-0'>
                    {error.description}
                  </li>
                ))}
              </ul>
            ),
            type: 'error'
          });
          setSubmitting(false);
          return;
        }
        toaster({
          text: (
            <Translation
              id='Toasts.Patients.Update.Success'
              values={{ name: `${values.firstname} ${values.lastname}` }}
            />
          ),
          type: 'success'
        });
        history.push(`${urlSchema.patients.readMd}/${patientId}`);
      })
      .catch((error) => {
        toaster({
          text: <ApiError {...{ error }} />,
          type: 'error'
        });
        setSubmitting(false);
      });
  };

  if (isPatientLoading) return <Spinner color='primary' size='lg' />;

  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.Patients.Update.Title'
          values={{ name: `${patient?.firstname} ${patient?.lastname}` }}
          tagName='h1'
        />
      </PageHeader>
      <div className='row'>
        <div className='col-8'>
          <section className='section'>
            <div className='section__body'>
              <Formik
                initialValues={{
                  ...patient,
                  googleAddress: '',
                  infosPatient: {
                    ...patient.infosPatient,
                  },
                  birthdate: moment(patient.birthdate).format('YYYY-MM-DD'),
                  gender: patient.gender.toString(),
                }}
                validationSchema={yup.object().shape({
                  ...validationSchema(formatMessage),
                })}
                {...{ onSubmit }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Patient />
                    <div className='text-right'>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Update'
                        size='lg'
                        type='submit'
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Update;
