import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input, Col, Container, Row } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import { useIntl } from 'react-intl';
import qs from 'qs';
import { historyShape } from 'react-router-props';
import { Form, Formik, Field } from 'formik';
import { toaster } from '@teladochealth/components';

import {
  Button,
  FormattedDate,
  LinksList,
  PageHeader,
  RoleManager,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, urlSchema } from 'utils';

const List = ({ history }) => {
  const intl = useIntl();
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);

  const getResults = (
    values = {
      searchString: null,
    },
    callback,
  ) => {
    setIsLoading(true);

    service
      .get(api.manager.getClients, {
        params: {
          ...values,
          ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
        },
      })
      .then(({ data }) => {
        setClients(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: <Translation id='Message.Api.Error' />,
          type: 'error',
        });
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    getResults(values, () => {
      setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <>
          <Translation id='Screens.Customers.List.Title' tagName='h1' />
          <RoleManager entity='Clients' right='CRUD'>
            <Link
              to={urlSchema.clients.create}
              className='btn btn-primary btm-sm'
            >
              <Translation id='Buttons.Create' />
            </Link>
          </RoleManager>
        </>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          <Formik
            initialValues={{
              searchString: '',
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className='w-100'>
                <Row>
                  <Col md='8' lg='9' xl='10'>
                    <Field type='text' name='searchString'>
                      {({ field }) => {
                        const { onChange } = field;
                        return (
                          <Input
                            {...field}
                            id='search-string'
                            onChange={(event) => {
                              onChange(event);
                              debouncedCallback(() => handleSubmit());
                            }}
                            placeholder={intl.formatMessage({
                              id: 'Screens.Customers.List.PlaceholderResearch',
                              defaultMessage:
                                'Error.Screens.Customers.List.PlaceholderResearch',
                            })}
                          />
                        );
                      }}
                    </Field>
                  </Col>
                  <Col className='ml-auto' md='auto'>
                    <Button
                      data-tracking='ClientsListBtnSearch'
                      color='primary'
                      isProcessing={isSubmitting}
                      label='Research'
                      type='submit'
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          <header className='research-table__header'>
            <Container fluid>
              <Row>
                <Col md='3' lg='2'>
                  <Translation tagName='h5' id='Customers.Customers' />
                </Col>
                <Col md='3'>
                  <Translation tagName='h5' id='Customers.Organization' />
                </Col>
                <Col lg='2' className='d-none d-lg-block'>
                  <Translation tagName='h5' id='Customers.Creation' />
                </Col>
                <Col sm='8' md='auto' className='d-none d-sm-block'>
                  <Translation tagName='h5' id='Customers.Products' />
                </Col>
              </Row>
            </Container>
          </header>
          {isLoading ? (
            <Spinner size='lg' />
          ) : (
            <ul className='research-table__results'>
              {clients.map((item) => {
                return (
                  <li key={item.idClient}>
                    <Container fluid>
                      <Row className='align-items-center'>
                        <Col md='3' lg='2'>
                          <h5 className='mb-0'>{item.label}</h5>
                          <small className='d-block text-secondary'>
                            {item.code}
                          </small>
                        </Col>
                        <Col md='3'>
                          <Link
                            to={`${urlSchema.universes.read}/${item.refUniverse}`}
                          >
                            {item.universeDisplayLabel}
                          </Link>
                        </Col>
                        <Col lg='2' className='d-none d-lg-block'>
                          <small>
                            <FormattedDate value={item.dateCreation} />
                          </small>
                        </Col>
                        <Col sm='8' md='auto' className='d-none d-sm-block'>
                          <LinksList
                            color='warning'
                            data={item.products}
                            idKey={(element) => element.id}
                            labelKey={(element) => element.label}
                            limit={2}
                            urlBase={`${urlSchema.products.read}`}
                          />
                        </Col>
                        <Col md='1' className='ml-auto'>
                          <div className='research-table__actions'>
                            <RoleManager entity='Products' right='CRUD'>
                              <Link
                                to={`${
                                  urlSchema.products.create
                                }?${qs.stringify({
                                  refClientSellsy: item.refClientSellsy,
                                  labelClientSellsy: item.labelClientSellsy,
                                  idClient: item.idClient,
                                })}`}
                                aria-label={intl.formatMessage({
                                  id: 'Buttons.CreateProduct',
                                  defaultMessage: 'Error.Buttons.CreateProduct',
                                })}
                                title={intl.formatMessage({
                                  id: 'Buttons.CreateProduct',
                                  defaultMessage: 'Error.Buttons.CreateProduct',
                                })}
                              >
                                <i
                                  aria-hidden
                                  className='uil uil-shopping-bag uil--md'
                                />
                              </Link>
                            </RoleManager>
                            <RoleManager entity='Clients' right='CRUD'>
                              <Link
                                aria-label={intl.formatMessage({
                                  id: 'Buttons.Edit',
                                  defaultMessage: 'Error.Buttons.Edit',
                                })}
                                title={intl.formatMessage({
                                  id: 'Buttons.Edit',
                                  defaultMessage: 'Error.Buttons.Edit',
                                })}
                                to={`${urlSchema.clients.update}/${item.idClient}`}
                              >
                                <i
                                  aria-hidden
                                  className='uil uil-pen uil--md'
                                />
                              </Link>
                            </RoleManager>
                            <RoleManager entity='Clients' right='Reader'>
                              <Link
                                aria-label={intl.formatMessage({
                                  id: 'Buttons.Read',
                                  defaultMessage: 'Error.Buttons.Read',
                                })}
                                title={intl.formatMessage({
                                  id: 'Buttons.Read',
                                  defaultMessage: 'Error.Buttons.Read',
                                })}
                                to={`${urlSchema.clients.read}/${item.idClient}`}
                              >
                                <i
                                  aria-hidden
                                  className='uil uil-eye uil--md'
                                />
                              </Link>
                            </RoleManager>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

List.propTypes = { history: historyShape.isRequired };

export default List;
