import React from 'react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import { Specialty } from 'models';
import validationSchema from 'models/Specialty/validationSchema';
import { ButtonsWrapper } from 'themes/jupiter/elements';

const Update = ({ history, match }) => {
  const intl = useIntl();
  const { refSpecialty } = match.params;
  const [specialty, isSpecialtyLoading] = useFetch(
    api.admin.getSpecialty(refSpecialty),
  );

  const onSubmit = (values, { setSubmitting }) => {
    // TODO: "label" field added as french translation for backward compatibility. Remove it when no need
    const frenchLabel = values.name.filter(
      (element) => element.languageCode === 'FR',
    );

    service
      .put(`${api.admin.postSpecialty}/${refSpecialty}`, {
        ...values,
        // TODO: "label" field added as french translation for backward compatibility. Remove it when no need
        label: frenchLabel[0].content,
      })
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          toaster({
            title: <Translation id='Shared.Toast.Update.Fail' />,
            text: data.errorSet[0].description,
            type: 'error',
          });
        } else {
          toaster({
            text: <Translation id='Shared.Toast.Update.Success' />,
            type: 'success',
          });
          history.push(`${urlSchema.specialties.read}/${refSpecialty}`);
        }
      })
      .catch(() => {
        toaster({
          text: <Translation id='Shared.Toast.Update.Fail' />,
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation id='Specialties.Update.Title' tagName='h1' />
      </PageHeader>
      <Row>
        <Col md='10' lg='8'>
          <Section>
            <SectionBody>
              {isSpecialtyLoading ? (
                <Spinner size='lg' />
              ) : (
                <Formik
                  initialValues={specialty}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema(
                    specialty.name.length,
                    intl,
                  )}
                >
                  {({ isSubmitting, ...properties }) => (
                    <Form>
                      <Specialty isUpdating {...properties} />
                      <ButtonsWrapper bottom>
                        <Button
                          color='primary'
                          label='Update'
                          isProcessing={isSubmitting}
                          size='lg'
                        />
                      </ButtonsWrapper>
                    </Form>
                  )}
                </Formik>
              )}
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Update;
