import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';
import { urlSchema } from 'utils';

// Screens
import Create from './Create';
import Update from './Update';
import List from './List';
import Details from './Details';

const Specialties = () => {
  const routes = [
    {
      path: urlSchema.specialties.create,
      component: Create,
      right: 'CRUD',
    },
    {
      path: `${urlSchema.specialties.update}/:refSpecialty`,
      component: Update,
      right: 'CRUD',
    },
    {
      path: `${urlSchema.specialties.read}/:specialtyId`,
      component: Details,
      right: 'Reader',
    },
    { path: '/', component: List, right: 'CRUD' },
  ];

  return (
    <Switch>
      {routes.map((item) => (
        <ProtectedRoute
          key={item.path}
          component={item.component}
          entity='Specialties'
          path={item.path}
          right={item.right}
        />
      ))}
    </Switch>
  );
};

export default Specialties;
