import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { toaster } from '@teladochealth/components';

import {
  LinksList,
  PageHeader,
  RoleManager,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, urlSchema } from 'utils';

const List = () => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [organisations, setOrganisation] = useState(undefined);

  const getResults = (callback) => {
    setIsLoading(true);
    service
      .get(api.admin.getOrganizationConfigs)
      .then(({ data }) => {
        setOrganisation(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: <Translation id='Message.Api.Error' />,
          type: 'error',
        });
      });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <>
          <Translation
            id='Screens.OrganizationConfigs.List.Title'
            tagName='h1'
          />
          <RoleManager entity='OrganizationConfigs' right='CRUD'>
            <Link
              className='btn btn-primary btm-sm'
              to={urlSchema.createOrganizationConfigs}
            >
              <Translation id='Buttons.Create' />
            </Link>
          </RoleManager>
        </>
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <header className='research-table__header'>
            <Container fluid>
              <Row>
                <Col md='4'>
                  <Translation tagName='h5' id='OrganizationConfigs.Code' />
                </Col>
                <Col md='3' lg='2'>
                  <Translation tagName='h5' id='OrganizationConfigs.Profils' />
                </Col>
                <Col lg='2' className='d-none d-md-none d-lg-block'>
                  <Translation
                    tagName='h5'
                    id='OrganizationConfigs.Specialty'
                  />
                </Col>
              </Row>
            </Container>
          </header>
          {isLoading ? (
            <Spinner size='lg' />
          ) : (
            <ul className='research-table__results'>
              {organisations.map((item) => {
                return (
                  <li key={item.idOrganizationConfig}>
                    <Container fluid>
                      <Row className='align-items-center'>
                        <Col md='4'>
                          <h5 className='mb-0'>{item.code}</h5>
                          <p className='m-0 small text-secondary'>
                            {item.label}
                          </p>
                        </Col>
                        <Col md='3' lg='2'>
                          {item.profiles.length <= 0 ? (
                            <Translation id='Shared.NoElement' />
                          ) : (
                            <Link
                              to={`${urlSchema.profiles.list}?refOrganizationConfig=${item.idOrganizationConfig}`}
                              className='btn btn-outline-primary btn-sm'
                            >
                              <Translation
                                id='Buttons.ReadProfiles'
                                values={{
                                  profilesLength: item.profiles.length,
                                }}
                              />
                            </Link>
                          )}
                        </Col>
                        <Col lg='2' className='d-none d-md-none d-lg-block'>
                          {item.specialties.length <= 0 ? (
                            <Translation id='Shared.NoElement' />
                          ) : (
                            <LinksList
                              color='warning'
                              data={item.specialties}
                              idKey={(element) => element.id}
                              labelKey={(element) => element.label}
                              limit='3'
                              urlBase={urlSchema.specialties.read}
                            />
                          )}
                        </Col>
                        <Col className='ml-auto'>
                          <div className='research-table__actions'>
                            <RoleManager entity='Universes' right='Reader'>
                              <Link
                                to={`${urlSchema.universes.list}?refOrganizationConfig=${item.idOrganizationConfig}`}
                                className='btn btn-outline-primary btn-sm'
                              >
                                <Translation id='Buttons.ReadOrganizationConfigs' />
                              </Link>
                            </RoleManager>
                            <RoleManager
                              entity='OrganizationConfigs'
                              right='Reader'
                            >
                              <Link
                                aria-label={intl.formatMessage({
                                  id: 'Buttons.Read',
                                  defaultMessage: 'Error.Buttons.Read',
                                })}
                                title={intl.formatMessage({
                                  id: 'Buttons.Read',
                                  defaultMessage: 'Error.Buttons.Read',
                                })}
                                to={`${urlSchema.detailsOrganizationConfigs}/${item.idOrganizationConfig}`}
                              >
                                <i
                                  aria-hidden
                                  className='uil uil-eye uil--md'
                                />
                              </Link>
                            </RoleManager>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
