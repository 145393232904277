import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { runInAction } from 'mobx';
import { observer, Observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { toaster } from '@teladochealth/components';

import { Checkbox, PageHeader, Spinner, Translation } from 'components';
import validationSchema from './validationShema';
import Store from '../store';

const componentDidMount = (voucherId) => {
  Store.retrieveVoucherInfos(voucherId);
};

//Component - Ternary wrapper
const Suspense = observer((properties) =>
  Store.voucherInfos.name === 'xxx' ||
  Store.voucherDetails[0].dateExpiration === 'xxx' ? (
    <div className='py-3 container-fluid'>
      <Spinner color='primary' size={properties.size} />
    </div>
  ) : (
    properties.children
  ),
);

const onUseTypeChange = ({ target: { value } }) => {
  runInAction(() => {
    const copy = Object.assign(Store.voucherDetails, {});
    if (value === '2' && copy[0].useLimit <= 1) {
      value = 1;
    }
    copy[0].useType = Number(value);
    Store.voucherDetails = copy;
  });
};

const onChangeUsageNumber = ({ target: { value } }) => {
  runInAction(() => {
    const copy = Object.assign(Store.voucherDetails, {});
    copy[0].useLimit = Number(value) < 1 ? 1 : Number(value);
    copy[0].useType = 2;
    Store.voucherDetails = copy;
  });
};

const CustomForm = () => (
  <Formik
    enableReinitialize={false}
    validationSchema={validationSchema}
    initialValues={{
      idVoucherGroup: Store.voucherInfos.idVoucherGroup,
      dateExpiration: moment(Store.voucherDetails[0].dateExpiration).format(
        'yyyy-MM-DD',
      ),
      useType: Store.voucherDetails[0].useType,
      useLimit: Store.voucherDetails[0].useLimit,
      valid: Store.voucherDetails[0].valid,
      description: Store.voucherInfos.description,
      productTag: Store.voucherDetails[0].productTag,
    }}
    onSubmit={(values, { setSubmitting }) => {
      if (Store.voucherDetails[0].useType < 3)
        values.useType =
          Store.voucherDetails[0].useLimit === null ||
          Store.voucherDetails[0].useLimit < 2
            ? 1
            : 2;
      else values.useType = 3;
      values.useLimit =
        Store.voucherDetails[0].useType === 2
          ? Store.voucherDetails[0].useLimit
          : null;

      Store.updateVoucherGroup(values)
        .then(() => {
          setSubmitting(false);
          toaster({
            title: <Translation id='Screens.VoucherGroups.edit.successTitle' />,
            text: <Translation id='Screens.VoucherGroups.add.successBody' />,
            type: 'success',
          });
        })
        .catch(() => {
          setSubmitting(false);
          toaster({
            title: <Translation id='Screens.VoucherGroups.edit.errorTitle' />,
            text: <Translation id='Screens.VoucherGroups.add.errorBody' />,
            type: 'error',
          });
        });
    }}
  >
    {({ isSubmitting }) => (
      <Form>
        {/* Description */}
        <div className='form-group'>
          <label htmlFor='description'>
            <Translation id='Screens.VoucherGroups.details.description' />
          </label>
          <Field
            type='text'
            name='description'
            as='textarea'
            className='form-control'
            rows='3'
          />
        </div>

        {/* productTag */}
        <div className='form-group'>
          <label htmlFor='productTag'>
            <Translation id='Screens.VoucherGroups.add.productTag' />
          </label>
          <Field name='productTag'>
            {({ field }) => {
              return (
                <Observer>
                  {() => (
                    <input
                      id='productTag'
                      type='text'
                      className='form-control'
                      {...field}
                    />
                  )}
                </Observer>
              );
            }}
          </Field>
        </div>

        {/* expirationDate */}
        <div className='form-group'>
          <label htmlFor='expirationDate' className='required'>
            <Translation id='Screens.VoucherGroups.add.expirationDate' />
          </label>
          <Field type='date' name='dateExpiration' className='form-control' />
          <ErrorMessage name='dateExpiration'>
            {(error) => (
              <div className='invalid-feedback d-block fw-bold'>{error}</div>
            )}
          </ErrorMessage>
        </div>

        {/* useType */}
        <div className='form-group'>
          <label className='required'>
            <Translation id='Screens.VoucherGroups.edit.usage' />
          </label>
          <div className='row text-center py-3'>
            <div className='col-6'>
              <Field type='radio' name='useType'>
                {() => {
                  return (
                    <>
                      <Observer>
                        {() => (
                          <input
                            type='radio'
                            name='useType'
                            onChange={onUseTypeChange}
                            checked={
                              Store.voucherDetails[0].useType === 1 ||
                              Store.voucherDetails[0].useType === 2
                            }
                            value='2'
                            id='useTypeWithNumber'
                          />
                        )}
                      </Observer>

                      <label htmlFor='useTypeWithNumber' className='d-block'>
                        <Observer>
                          {() => (
                            <div className='border py-3 rounded'>
                              <div>
                                <input
                                  defaultValue={
                                    Store.voucherDetails[0].useLimit === null
                                      ? 1
                                      : Store.voucherDetails[0].useLimit
                                  }
                                  value={
                                    Store.voucherDetails[0].useLimit > 0
                                      ? Store.voucherDetails[0].useLimit
                                      : '1'
                                  }
                                  onChange={onChangeUsageNumber}
                                  type='number'
                                  min={1}
                                  className='bg-transparent border-0 display-4 fw-bold h2 m-0 p-0 text-center text-primary w-75'
                                />
                              </div>
                              <Translation
                                id='Screens.VoucherGroups.edit.usagePlural'
                                values={{
                                  plural:
                                    Store.voucherDetails[0].useLimit > 1
                                      ? 's'
                                      : '',
                                }}
                              />
                            </div>
                          )}
                        </Observer>
                      </label>
                    </>
                  );
                }}
              </Field>
            </div>
            <div className='col-6'>
              <Observer>
                {() => (
                  <input
                    type='radio'
                    name='useType'
                    onChange={onUseTypeChange}
                    checked={Store.voucherDetails[0].useType === 3}
                    value={'3'}
                    id='useTypeWithInfinite'
                  />
                )}
              </Observer>

              <label htmlFor='useTypeWithInfinite' className='d-block'>
                <Observer>
                  {() => (
                    <div className='border py-3 rounded'>
                      <h2 className='display-4 text-primary fw-bold'>∞</h2>
                      <Translation id='Screens.VoucherGroups.edit.unlimitedUsage' />
                    </div>
                  )}
                </Observer>
              </label>
            </div>
          </div>
        </div>

        {/* valid */}
        <div className='form-group'>
          <Field name='valid'>
            {({ field, form }) => (
              <Checkbox
                {...field}
                checked={form.values.valid}
                id='form-control-valid'
              >
                <Translation id='Screens.VoucherGroups.edit.valid' />
              </Checkbox>
            )}
          </Field>
        </div>

        <div className='form-group text-right'>
          <button
            className='btn btn-primary btn-lg'
            type='submit'
            disabled={isSubmitting}
          >
            <Translation id='Screens.VoucherGroups.edit.edit' />
          </button>
        </div>
      </Form>
    )}
  </Formik>
);

const Edit = () => {
  let { voucherId } = useParams();

  useEffect(() => {
    componentDidMount(voucherId);
  }, [voucherId]);

  return (
    <Suspense>
      <PageHeader>
        <Observer>
          {() => (
            <h1>
              <Translation
                id='Screens.VoucherGroups.edit.title'
                values={{ name: Store.voucherInfos.name }}
              />
            </h1>
          )}
        </Observer>
      </PageHeader>
      <section className='section voucherGroupsEdit'>
        <div className='section__body'>
          <CustomForm />
        </div>
      </section>
    </Suspense>
  );
};

export default Edit;
