import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FieldArray, Formik, Form } from 'formik';
import { Spinner } from 'reactstrap';
import cn from 'classnames';
import { toaster } from '@teladochealth/components';

import { ApiError, Button, PageHeader, Translation } from 'components';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';

const EditProfiles = ({ history }) => {
  const { idUser } = useParams();
  const [doctor, isDoctorLoading] = useFetch(api.doctors.getDetails(idUser));
  // WARNING: /Profiles/ListItems (include a Valid criteria) don't give same results as /Profiles
  const [profiles, setProfiles] = useState([]);

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.doctors.putProfiles(values.idUser), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          toaster({
            text: <Translation id='Toasts.Profiles.Fail' />,
            type: 'error',
          });
          return;
        }
        toaster({
          text: <Translation id='Toasts.Profiles.Success' />,
          type: 'success',
        });
        history.push(`${urlSchema.doctors.read}/${values.idUser}`);
      })
      .catch((error) => {
        toaster({
          title: <Translation id='Toasts.Profiles.Fail' />,
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (!isDoctorLoading) {
      service
        .get(api.profiles.getListItems, {
          params: { refUniverse: doctor.refUniverse, userType: 'PRACTITIONER' },
        })
        .then(({ data }) => {
          setProfiles(data);
        });
    }
  }, [isDoctorLoading]);

  if (isDoctorLoading || profiles.length === 0)
    return <Spinner color='primary' size='lg' />;

  return (
    <>
      <PageHeader>
        <Translation
          tagName='h1'
          values={{
            doctor: `${doctor?.title} ${doctor?.firstname} ${doctor?.lastname}`,
          }}
          id='Screens.Doctors.EditProfiles.Title'
        />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <Formik
            initialValues={{
              idUser,
              selectedProfiles: doctor.usersProfiles,
            }}
            {...{ onSubmit }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <FieldArray
                  name='selectedProfiles'
                  render={({ push, remove }) => (
                    <>
                      {profiles.map((profile) => {
                        const indexProfileFound =
                          values.selectedProfiles.findIndex(
                            (id) => id === profile.id,
                          );
                        return (
                          <button
                            className={cn('combined-btn', {
                              'combined-btn--success': indexProfileFound >= 0,
                            })}
                            key={profile.id}
                            onClick={() => {
                              if (indexProfileFound >= 0) {
                                remove(indexProfileFound);
                              } else push(profile.id);
                            }}
                            type='button'
                          >
                            <span>{profile.code}</span>
                            {indexProfileFound >= 0 ? (
                              <i
                                aria-hidden
                                className='uil uil-check uil--md'
                              />
                            ) : (
                              <i aria-hidden className='uil uil-plus uil--md' />
                            )}
                          </button>
                        );
                      })}
                    </>
                  )}
                />
                <div className='mt-3 text-right'>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    isProcessing={isSubmitting}
                    label='Validate'
                    size='lg'
                    type='submit'
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

EditProfiles.displayName = 'Doctors_Edit_Profiles';

export default EditProfiles;
