import React from 'react';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { Col, Row, Alert } from 'reactstrap';
import {
  Section,
  SectionHeader,
  SectionBody,
  FormInput,
  Button,
} from 'components';

import messages from './messages';

const CheckEligibility = () => {
  const { dirty, resetForm, isSubmitting, setFieldValue, values, submitForm } =
    useFormikContext();
  const { formatMessage } = useIntl();

  const handleSubmit = () => {
    if (values.birthdate) {
      setFieldValue('birthdate', moment(values.birthdate).format('YYYY-MM-DD'));
      setFieldValue(
        'formattedBirthdate',
        moment(values.birthdate).format('YYYY-MM-DD'),
      );
    }
    submitForm();
  };

  return (
    <Row>
      <Col lg='8' className='ml-auto mr-auto'>
        <Section>
          <SectionHeader>
            <FormattedMessage {...messages.Title} tagName='h1' />
            <Button
              className='ml-auto'
              type='button'
              size='sm'
              color='secondary'
              outline
              label='Reset'
              onClick={() => resetForm()}
            />
          </SectionHeader>
          <SectionBody>
            {values.isNotEligible && dirty && (
              <Alert color='danger'>
                <FormattedMessage {...messages.ErrorMessage} />
              </Alert>
            )}
            <FormInput
              id='userCode'
              label={formatMessage(messages.EligibilityCode)}
              placeholder={formatMessage(messages.EligibilityCode)}
              name='userCode'
              type='text'
              required
            />
            <Row>
              <Col md='6'>
                <FormInput
                  id='firstname'
                  placeholder={formatMessage(messages.Firstname)}
                  label={formatMessage(messages.Firstname)}
                  name='firstname'
                  type='text'
                  required
                />
              </Col>
              <Col md='6'>
                <FormInput
                  id='lastname'
                  label={formatMessage(messages.Lastname)}
                  placeholder={formatMessage(messages.Lastname)}
                  name='lastname'
                  type='text'
                  required
                />
              </Col>
            </Row>
            <FormInput
              label={formatMessage(messages.Birthdate)}
              type='date'
              name='birthdate'
              id='birthdate'
              required
            />
            <div className='text-right'>
              <Button
                isProcessing={isSubmitting}
                type='button'
                onClick={() => handleSubmit()}
                color='primary'
                label='Screens.Patients.Create.CheckEligibility.TestEligibilityCTA'
                customLabel
              />
            </div>
          </SectionBody>
        </Section>
      </Col>
    </Row>
  );
};

export default CheckEligibility;
