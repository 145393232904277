import * as yup from 'yup';

const fieldRequiredString = 'Model.FieldRequired';

export default yup.object().shape({
  code: yup.string().required(fieldRequiredString),
  label: yup.string().required(fieldRequiredString),
  url: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!',
    )
    .required(fieldRequiredString),
});
