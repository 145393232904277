import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useIntl } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import { Input, Container, Col, Row } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  RoleManager,
  Spinner,
  Translation,
} from 'components';

import { service } from 'services';
import { api, urlSchema } from 'utils';

const List = () => {
  const intl = useIntl();
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);
  const [isLoading, setIsLoading] = useState(true);
  const [productTerms, setProductTerms] = useState([]);

  const getResults = (
    values = {
      searchString: '',
    },
    callback,
  ) => {
    setIsLoading(true);
    service
      .get(`${api.admin.getProductTerms}?SearchString=${values.searchString}`)
      .then(({ data }) => {
        setProductTerms(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(({ data }) => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: (
            <>
              {data.errorSet.map((error) => (
                <p key={error.code}>
                  {error.code}: {error.description}
                </p>
              ))}
            </>
          ),
          type: 'error',
        });
      });
  };

  const onSubmit = (values, properties) => {
    getResults(values, () => {
      properties.setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <Translation id='Screens.ProductTerms.List.Title' tagName='h1' />
        <RoleManager entity='ProductTerms' right='Reader'>
          <Link
            aria-label={intl.formatMessage({
              id: 'Buttons.Create',
              defaultMessage: 'Error.Buttons.Create',
            })}
            title={intl.formatMessage({
              id: 'Buttons.Create',
              defaultMessage: 'Error.Buttons.Create',
            })}
            className='btn btn-primary'
            to={urlSchema.productTerms.create}
          >
            <Translation id='Buttons.Create' />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <section className='section__body'>
          <Formik
            initialValues={{
              searchString: '',
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form>
                <Row>
                  <Col md='8' lg='9' xl='10'>
                    <Field type='text' name='searchString'>
                      {({ field }) => {
                        const { onChange } = field;
                        return (
                          <Input
                            {...field}
                            id='search-string'
                            onChange={(event) => {
                              onChange(event);
                              debouncedCallback(() => handleSubmit());
                            }}
                            placeholder={intl.formatMessage({
                              id: 'Screens.ProductTerms.List.Placeholder',
                              defaultMessage:
                                'Error.Screens.ProductTerms.List.Placeholder',
                            })}
                          />
                        );
                      }}
                    </Field>
                  </Col>
                  <Col className='ml-auto' md='auto'>
                    <Button
                      data-tracking='ProductTermsListBtnSearch'
                      color='primary'
                      isProcessing={isSubmitting}
                      label='Research'
                      type='submit'
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <div className='research-table__header mt-3'>
            <Container fluid>
              <Row>
                <Col md='3'>
                  <h5>
                    <Translation id='ProductTerms.Code' />
                  </h5>
                </Col>
              </Row>
            </Container>
          </div>
          {isLoading ? (
            <Spinner size='lg' />
          ) : (
            <ul className='research-table__results'>
              {productTerms.map((item) => {
                return (
                  <li key={item.idProductTerm}>
                    <Container fluid>
                      <Row className='align-items-center'>
                        <Col md='10'>
                          <h5 className='m-0'>{item.code}</h5>
                          <p className='m-0 small text-secondary'>
                            {item.label}
                          </p>
                        </Col>
                        <Col md='2' className='ml-auto'>
                          <div className='research-table__actions'>
                            <RoleManager entity='ProductTerms' right='Reader'>
                              <Link
                                to={`${urlSchema.productTerms.read}/${item.idProductTerm}`}
                                aria-label={intl.formatMessage({
                                  id: 'Buttons.Read',
                                  defaultMessage: 'Error.Buttons.Read',
                                })}
                                title={intl.formatMessage({
                                  id: 'Buttons.Read',
                                  defaultMessage: 'Error.Buttons.Read',
                                })}
                              >
                                <i
                                  aria-hidden
                                  className='uil uil-eye uil--md'
                                />
                              </Link>
                            </RoleManager>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          )}
        </section>
      </section>
    </>
  );
};

export default List;
