import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { historyShape, matchShape } from 'react-router-props';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  Translation,
} from 'components';
import { Profile } from 'models';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import validationSchema from 'models/Profile/validationSchema';
import { Methods } from '../../../constants';

const Update = ({ history, match }) => {
  const intl = useIntl();
  const { profileId } = match.params;
  const [profile, isProfileLoading] = useFetch(
    `${api.profiles.get}/${profileId}`,
  );

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.profiles.put(profileId), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          toaster({
            title: <Translation id='Toasts.Profiles.Update.Error' />,
            text: data.errorSet[0].description,
            type: 'error',
          });
        } else {
          toaster({
            text: <Translation id='Toasts.Profiles.Update.Success' />,
            type: 'success',
          });
          history.push(urlSchema.profiles.list);
        }
      })
      .catch(() => {
        toaster({
          text: <Translation id='Toasts.Profiles.Update.Error' />,
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation
          tagName='h1'
          id='Screens.Profiles.Update.Title'
          values={{ label: profile?.label || '' }}
        />
        <Link
          data-tracking='ProfilesBtnEditRoles'
          className='btn btn-primary'
          to={`${urlSchema.profiles.editRoles}/${profileId}`}
        >
          {intl.formatMessage({
            id: 'Buttons.EditRoles',
            defaultMessage: 'Error.Buttons.EditRoles',
          })}
        </Link>
      </PageHeader>
      <Row>
        <Col md='6'>
          <Section>
            <SectionBody>
              {isProfileLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Formik
                  initialValues={profile}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema(intl)}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Profile method={Methods.PUT} />
                      <div className='text-right'>
                        <Button
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Update'
                          size='lg'
                          type='submit'
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

Update.propTypes = {
  history: historyShape.isRequired,
  match: matchShape.isRequired,
};

export default Update;
