import React from 'react';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import { historyShape, matchShape } from 'react-router-props';
import { toaster } from '@teladochealth/components';

import { ApiError, Button, PageHeader, Translation } from 'components';
import { WebServiceConfiguration } from 'models';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import validationSchema from 'models/WebServiceConfiguration/validationSchema';

const Update = ({ history, match }) => {
  const { formatMessage } = useIntl();
  const { idWebServiceConfiguration } = match.params;
  const [webServiceConfiguration, isWebServiceConfigurationLoading] = useFetch(
    `${api.webServices.getConfiguration}/${idWebServiceConfiguration}`,
  );

  const onSubmit = (values) => {
    service
      .put(api.webServices.putConfiguration(idWebServiceConfiguration), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          toaster({
            title: (
              <Translation
                id='Toasts.WebServicesConfiguration.Update.Fail'
                values={{ name: values.name }}
              />
            ),
            text: data.errorSet[0].description,
            type: 'error',
          });
        } else {
          toaster({
            text: (
              <Translation
                id='Toasts.WebServicesConfiguration.Update.Success'
                values={{ name: values.name }}
              />
            ),
            type: 'success',
          });
          history.push(
            `${urlSchema.webServices.configuration.read}/${values.idWebServiceConfig}`,
          );
        }
      })
      .catch((error) => {
        toaster({
          title: (
            <Translation
              id='Toasts.WebServicesConfiguration.Update.Fail'
              values={{ name: values.name }}
            />
          ),
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
      });
  };

  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.WebServicesConfiguration.Update.Title'
          tagName='h1'
          values={{ name: webServiceConfiguration.name || '' }}
        />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          {isWebServiceConfigurationLoading ? (
            <div className='py-3 text-center'>
              <Spinner color='primary' size='lg' />
            </div>
          ) : (
            <Formik
              initialValues={{
                ...webServiceConfiguration,
                refWebService: '',
                userBirthDate: webServiceConfiguration.userBirthDate
                  ? webServiceConfiguration.userBirthDate.split('T')[0]
                  : '',
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema(formatMessage)}
            >
              {(formikProperties) => (
                <Form>
                  <WebServiceConfiguration {...formikProperties} />
                  <Button
                    data-tracking='WebServicesConfigurationUpdateBtn'
                    color='primary'
                    isProcessing={formikProperties.isSubmitting}
                    label='Update'
                    size='lg'
                  />
                </Form>
              )}
            </Formik>
          )}
        </div>
      </section>
    </>
  );
};

Update.propTypes = {
  history: historyShape.isRequired,
  match: matchShape.isRequired,
};

export default Update;
