import React from 'react';
import { Formik, Form } from 'formik';
import { toaster } from '@teladochealth/components';

import { ProductTerm } from 'models';
import { Button, PageHeader, Spinner, Translation } from 'components';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import validationSchema from './validationSchema';

const Edit = ({ history, match }) => {
  const { params } = match;
  const [productTerms, isProductTermsLoading] = useFetch(
    api.manager.getProductTermsDetails(params.idProductTerm),
  );

  const onSubmit = (values, { setSubmitting }) => {
    let newValues = {
      code: values.code,
      label: values.label,
      valid: values.valid,
      idProductTerm: params.idProductTerm,
      optional: values.optional,
    };
    service
      .put(api.manager.putProductTerms, newValues)
      .then(({ data }) => {
        if (data.succeeded === false) {
          toaster({
            title: <Translation id='Message.Error' />,
            text: (
              <>
                {data.errorSet.map((error) => (
                  <p key={error.code}>
                    {error.code}: {error.description}
                  </p>
                ))}
              </>
            ),
            type: 'error',
          });
          return;
        }
        toaster({
          title: <Translation id='Message.Success' />,
          text: <Translation id='Screens.ProductTerms.Edit.SuccessBody' />,
          type: 'success',
        });
        history.push(`${urlSchema.productTerms.read}/${params.idProductTerm}`);
      })
      .catch(({ data }) => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: (
            <>
              {data.errorSet.map((error) => (
                <p key={error.code}>
                  {error.code}: {error.description}
                </p>
              ))}
            </>
          ),
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {isProductTermsLoading ? (
        <Spinner size='lg' />
      ) : (
        <>
          <PageHeader>
            <Translation
              id='Screens.ProductTerms.Edit.Title'
              values={{
                productTermsCode: productTerms.code,
              }}
              tagName='h1'
            />
          </PageHeader>
          <section className='section'>
            <section className='section__body'>
              <Formik
                initialValues={{
                  ...productTerms,
                  code: productTerms.code,
                  label: productTerms.label,
                  optional: productTerms.optional,
                  valid: productTerms.valid,
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <ProductTerm productTerms={productTerms} />
                    <ButtonsWrapper bottom>
                      <Button
                        color='primary'
                        disabled={isSubmitting}
                        isProcessing={isSubmitting}
                        label='Update'
                        size='lg'
                        type='submit'
                      />
                    </ButtonsWrapper>
                  </Form>
                )}
              </Formik>
            </section>
          </section>
        </>
      )}
    </>
  );
};

export default Edit;
