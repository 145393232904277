import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useIntl } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import { Badge, Col, Container, Input, Row } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import {
  Button,
  LinksList,
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { ListWrapper } from 'themes/jupiter/elements';
import { api, urlSchema } from 'utils';

const List = () => {
  const intl = useIntl();
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState(undefined);

  const getResults = (
    values = {
      searchString: '',
    },
    callback,
  ) => {
    setIsLoading(true);
    service
      .get(`${api.admin.getProducts}/${values.searchString}`)
      .then(({ data }) => {
        setProducts(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: <Translation id='Message.Api.Error' />,
          type: 'error',
        });
      });
  };

  const onSubmit = (values, properties) => {
    getResults(values, () => {
      properties.setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <>
          <Translation id='Screens.Products.List.Title' tagName='h1' />
          <RoleManager entity='Products' right='CRUD'>
            <Link to={urlSchema.products.create} className='btn btn-primary'>
              <Translation id='Buttons.Create' />
            </Link>
          </RoleManager>
        </>
      </PageHeader>
      <Section>
        <SectionBody>
          <ListWrapper>
            <ListWrapper.ResearchWrapper>
              <Formik
                initialValues={{
                  searchString: '',
                }}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col md='8' lg='9' xl='10'>
                        <Field type='text' name='searchString'>
                          {({ field }) => {
                            const { onChange } = field;
                            return (
                              <Input
                                {...field}
                                id='search-string'
                                onChange={(event) => {
                                  onChange(event);
                                  debouncedCallback(handleSubmit);
                                }}
                                placeholder={intl.formatMessage({
                                  id: 'Screens.Products.List.PlaceholderResearch',
                                  defaultMessage:
                                    'Error.Screens.Products.List.PlaceholderResearch',
                                })}
                              />
                            );
                          }}
                        </Field>
                      </Col>
                      <Col className='ml-auto' md='auto'>
                        <Button
                          data-tracking='ProductsListBtnSearch'
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Research'
                          type='submit'
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </ListWrapper.ResearchWrapper>
            <ListWrapper.Header>
              <Container fluid>
                <Row>
                  <Col md='3'>
                    <ListWrapper.ColumnTitle>
                      <Translation id='Products.Code' />
                    </ListWrapper.ColumnTitle>
                  </Col>
                  <Col md='3' className='d-none d-md-block'>
                    <ListWrapper.ColumnTitle>
                      <Translation id='Products.Customer' />
                    </ListWrapper.ColumnTitle>
                  </Col>
                  <Col md='3' className='d-none d-md-block'>
                    <ListWrapper.ColumnTitle>
                      <Translation id='Products.Specialty' />
                    </ListWrapper.ColumnTitle>
                  </Col>
                </Row>
              </Container>
            </ListWrapper.Header>
            {isLoading ? (
              <Spinner size='lg' />
            ) : (
              <ul className='research-table__results'>
                {products.map((item) => {
                  return (
                    <ListWrapper.Item key={item.idProduct}>
                      <Container fluid>
                        <Row>
                          <Col xs='6' sm='8' md='3'>
                            <h6 className='m-0'>{item.code}</h6>
                            <small className='d-block'>
                              <Translation
                                id='Products.Via'
                                values={{
                                  value:
                                    item.refVerificationTypeNavigation.code,
                                }}
                              />
                            </small>
                            {item.nbVouchers > 0 && (
                              <Badge
                                tag={Link}
                                to={`${urlSchema.vouchers}?RefProduct=${item.idProduct}`}
                                color='info'
                              >
                                <i
                                  aria-hidden
                                  className='align-middle uil uil-tag-alt uil--sm'
                                />
                                <span className='d-inline-block align-middle ml-2 font-weight-bold'>
                                  {item.nbVouchers}
                                </span>
                              </Badge>
                            )}
                          </Col>
                          <Col md='3' className='d-none d-md-block'>
                            <Link
                              to={`${urlSchema.clients.read}/${item.refClient}`}
                              className='small'
                            >
                              {item.clientLabel}
                            </Link>
                          </Col>
                          <Col md='3' className='d-none d-md-block'>
                            <LinksList
                              color='warning'
                              data={item.productsSpecialties}
                              idKey={(element) => element.refSpecialty}
                              labelKey={(element) => element.specialtyLabel}
                              limit={3}
                              urlBase={urlSchema.specialties.read}
                            />
                          </Col>
                          <Col xs='6' sm='4' md='3' className='ml-auto'>
                            <ListWrapper.Actions>
                              <RoleManager entity='VoucherGroups' right='CRUD'>
                                <a
                                  href={`${urlSchema.vouchersGroupsCreate}?idProduct=${item.idProduct}`}
                                  aria-label={intl.formatMessage({
                                    id: 'Buttons.CreateProductCode',
                                    defaultMessage:
                                      'Error.Buttons.CreateProductCode',
                                  })}
                                  title={intl.formatMessage({
                                    id: 'Buttons.CreateProductCode',
                                    defaultMessage:
                                      'Error.Buttons.CreateProductCode',
                                  })}
                                >
                                  <i
                                    aria-hidden
                                    className='uil uil-tag-alt uil--md'
                                  />
                                </a>
                              </RoleManager>
                              <RoleManager entity='Products' right='CRUD'>
                                <Link
                                  aria-label={intl.formatMessage({
                                    id: 'Buttons.Edit',
                                    defaultMessage: 'Error.Buttons.Edit',
                                  })}
                                  title={intl.formatMessage({
                                    id: 'Buttons.Edit',
                                    defaultMessage: 'Error.Buttons.Edit',
                                  })}
                                  to={`${urlSchema.products.update}/${item.idProduct}`}
                                >
                                  <i
                                    aria-hidden
                                    className='uil uil-pen uil--md'
                                  />
                                </Link>
                              </RoleManager>
                              <RoleManager entity='Products' right='Reader'>
                                <Link
                                  aria-label={intl.formatMessage({
                                    id: 'Buttons.Read',
                                    defaultMessage: 'Error.Buttons.Read',
                                  })}
                                  title={intl.formatMessage({
                                    id: 'Buttons.Read',
                                    defaultMessage: 'Error.Buttons.Read',
                                  })}
                                  to={`${urlSchema.products.read}/${item.idProduct}`}
                                >
                                  <i
                                    className='uil uil-eye uil--md'
                                    aria-hidden
                                  />
                                </Link>
                              </RoleManager>
                            </ListWrapper.Actions>
                          </Col>
                        </Row>
                      </Container>
                    </ListWrapper.Item>
                  );
                })}
              </ul>
            )}
          </ListWrapper>
        </SectionBody>
      </Section>
    </>
  );
};

export default List;
