import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'components';

const Breadcrumbs = ({ currentStep, isValid, setStep }) => {
  const [checkedSteps, setCheckedSteps] = useState([0]);
  useEffect(() => {
    if (currentStep === 2) {
      setCheckedSteps([0, 1]);
    }
  }, [currentStep]);

  const links = () => [
    {
      active: currentStep === 0,
      checked: Boolean(checkedSteps.includes(0)),
      onClick: () => setStep(0),
      index: 1,
      label: (
        <FormattedMessage
          id='Screens.Offers.CreateOffer.Link.HealthJourney'
          defaultMessage='Error.Screens.Offers.CreateOffer.Link.HealthJourney'
          tagName='span'
        />
      ),
    },
    {
      active: currentStep === 1,
      checked: Boolean(checkedSteps.includes(1)),
      onClick: () => (isValid ? setStep(1) : null),
      index: 2,
      label: (
        <FormattedMessage
          id='Screens.Offers.CreateOffer.Link.Rooms'
          defaultMessage='Error.Screens.Offers.CreateOffer.Link.Rooms'
          tagName='span'
        />
      ),
      isLast: true,
    },
  ];

  return <Breadcrumb links={links()} />;
};

Breadcrumbs.propTypes = {
  currentStep: PropTypes.number.isRequired,
  isValid: PropTypes.bool,
  setStep: PropTypes.func,
};

export default Breadcrumbs;
