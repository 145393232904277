import * as yup from 'yup';

import { combinedPasswordRegularExpression } from '@teladochealth/auth';

const validationSchema = (formatMessage) => {
  return yup.object().shape({
    confirmPassword: yup
      .string()
      .required(
        formatMessage({
          id: 'Artifact.Auth.Input.Required',
          defaultMessage: 'Error.Artifact.Auth.Input.Required',
        }),
      )
      .oneOf(
        [yup.ref('newPassword'), null],
        formatMessage({
          id: 'Artifact.Auth.Input.ConfirmPasswordNotMatching',
          defaultMessage:
            'Error.Artifact.Auth.Input.ConfirmPasswordNotMatching',
        }),
      ),
    newPassword: yup
      .string()
      .required(
        formatMessage({
          id: 'Artifact.Auth.Input.Required',
          defaultMessage: 'Error.Artifact.Auth.Input.Required',
        }),
      )
      .matches(
        combinedPasswordRegularExpression,
        formatMessage({
          id: 'Artifact.Auth.Input.PasswordInvalid',
          defaultMessage: 'Error.Artifact.Auth.Input.PasswordInvalid',
        }),
      ),
    oldPassword: yup.string().required(
      formatMessage({
        id: 'Artifact.Auth.Input.Required',
        defaultMessage: 'Error.Artifact.Auth.Input.Required',
      }),
    ),
  });
};

export default validationSchema;
