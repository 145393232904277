import { urlSchema } from 'utils';

export default [
  {
    id: 1,
    title: 'alerts',
    iconMenu: 'traffic-light',
    links: [
      {
        name: 'webServicesStatus',
        url: urlSchema.webServices.status,
        entity: 'WebServices',
        role: 'Reader',
      },
    ],
  },
  {
    id: 1,
    title: 'marketing',
    iconMenu: 'chart-pie',
    links: [
      {
        name: 'marketingSearch',
        url: urlSchema.marketing.search,
        entity: 'Marketing',
        role: 'CRUD',
      },
    ],
  },
  {
    id: 2,
    title: 'clients',
    iconMenu: 'suitcase',
    links: [
      {
        name: 'applications',
        url: urlSchema.applications.list,
        entity: 'Applications',
        role: 'Reader',
      },
      {
        name: 'organisationConfigs',
        url: urlSchema.organizationConfigs.list,
        entity: 'OrganizationConfigs',
        role: 'Reader',
      },
      {
        name: 'universes',
        url: urlSchema.universes.list,
        entity: 'Universes',
        role: 'CRUD',
      },
      {
        name: 'clients',
        url: urlSchema.clients.list,
        entity: 'Clients',
        role: 'Reader',
      },
      {
        name: 'EligiblePartners',
        url: urlSchema.partners,
        entity: 'EligiblePartners',
        role: 'Reader',
      },
    ],
  },
  {
    id: 3,
    title: 'products',
    iconMenu: 'shopping-bag',
    links: [
      {
        name: 'products',
        url: urlSchema.products.list,
        entity: 'Products',
        role: 'CRUD',
      },
      {
        name: 'productTerms',
        url: urlSchema.productTerms.list,
        entity: 'ProductTerms',
        role: 'Reader',
      },
      {
        name: 'specialties',
        url: urlSchema.specialties.list,
        entity: 'Specialties',
        role: 'Reader',
      },
      {
        name: 'voucherGroups',
        url: urlSchema.vouchersGroups,
        entity: 'VoucherGroups',
        role: 'Reader',
      },
      {
        name: 'vouchers',
        url: urlSchema.vouchers,
        entity: 'Vouchers',
        role: 'Reader',
      },
    ],
  },
  {
    id: 4,
    title: 'security',
    iconMenu: 'shield',
    links: [
      {
        name: 'profiles',
        url: urlSchema.profiles.list,
        entity: 'Profiles',
        role: 'Reader',
      },
      {
        name: 'roles',
        url: urlSchema.roles.list,
        entity: 'Roles',
        role: 'Reader',
      },
    ],
  },
  {
    id: 5,
    title: 'users',
    iconMenu: 'users-alt',
    links: [
      {
        name: 'patients',
        url: urlSchema.patients.list,
        entity: 'Patients',
        role: 'Reader',
      },
      {
        name: 'doctors',
        url: urlSchema.doctors.list,
        entity: 'Doctors',
        role: 'Reader',
      },
      {
        name: 'administrators',
        url: urlSchema.administrators.list,
        entity: 'Admins',
        role: 'Reader',
      },
      {
        name: 'identitiesVerification',
        url: urlSchema.identitiesVerification,
        entity: 'IdentityVerifications',
        role: 'Reader',
      },
      {
        name: 'identitiesValidationsNhi',
        url: urlSchema.identityValidationsNhi,
        entity: 'NHIs',
        role: 'Reader',
      },
      {
        name: 'fileImports',
        url: urlSchema.fileImports,
        entity: 'FileImports',
        role: 'Reader',
      },
      {
        name: 'fileImportConfigs',
        url: urlSchema.fileImportConfigs,
        entity: 'FileImportConfigs',
        role: 'Reader',
      },
      {
        name: 'fileImportClients',
        url: urlSchema.fileImportClients.list,
        entity: 'FileImportClients',
        role: 'Reader',
      },
    ],
  },
  {
    id: 6,
    title: 'settings',
    iconMenu: 'setting',
    links: [
      {
        name: 'webservices',
        url: urlSchema.webServices.list,
        entity: 'WebServices',
        role: 'CRUD',
      },
      {
        name: 'webservicesConfig',
        url: urlSchema.webServices.configuration.list,
        entity: 'WebServices',
        role: 'CRUD',
      },
      {
        name: 'messagesTemplate',
        url: urlSchema.templateMessages.list,
        entity: 'TemplateMessages',
        role: 'CRUD',
      },
      {
        name: 'news',
        url: urlSchema.actualities.list,
        entity: 'Actualities',
        role: 'Reader',
      },
      {
        name: 'banners',
        url: urlSchema.banners.list,
        entity: 'Banners',
        role: 'Reader',
      },
      {
        name: 'hangfire',
        url: urlSchema.hangfire,
        entity: 'Hangfire',
        role: 'CRUD',
      },
      {
        name: 'offers',
        url: urlSchema.offers,
        entity: 'Offers',
        role: 'CRUD',
      },
    ],
  },
  {
    id: 7,
    title: 'dialogues',
    iconMenu: 'comments',
    links: [
      {
        name: 'surveys',
        url: urlSchema.surveys,
        entity: 'Questionnaires',
        role: 'Reader',
      },
    ],
  },
  {
    id: 8,
    title: 'partnerOperations',
    iconMenu: 'adjust-circle',
    links: [
      {
        name: 'partnerOperationsDeposit',
        url: urlSchema.partnerOperations,
        entity: 'PartnerOperations',
        role: 'CRUD',
        isRoleExclusive: true,
      },
    ],
  },
  {
    id: 9,
    title: 'billing',
    iconMenu: 'wallet',
    links: [
      {
        name: 'feeNotes',
        url: urlSchema.feesNotes,
        entity: 'Fees',
        role: 'CRUD',
        isRoleExclusive: true,
      },
      {
        name: 'Sepa',
        url: urlSchema.sepa,
        entity: 'Fees',
        role: 'CRUD',
        isRoleExclusive: true,
      },
    ],
  },
];
