import React, { useState, useEffect } from 'react';

import { ProtectedRoute, Translation } from 'components';
import { CreateEditModal } from './CreateEditModal';
import List from './List';

import { service } from 'services';
import { urlSchema, api } from 'utils';
import { useLocation } from 'react-router-dom';
import { toaster } from '@teladochealth/components';

const Banners = () => {
  const location = useLocation();
  const [banners, setBanners] = useState({ data: [], isLoading: true });

  const getBanners = async () => {
    setBanners({ data: [], isLoading: true });
    try {
      const { data } = await service.get(api.banners.getAll);

      if (data.succeeded === false) {
        throw new Error(data.errorSet[0].code);
      }

      let bannersArray = [];

      if (data.length > 0) {
        bannersArray = data.sort((itemA, itemB) =>
          itemA.valid === itemB.valid ? 0 : itemA.valid ? -1 : 1,
        );
      }

      setBanners({
        data: bannersArray,
        isLoading: false,
      });
    } catch (error) {
      console.log(`An unexpected error happened on banner call.`, error);
      toaster({
        title: <Translation id='Message.Error' />,
        text: <Translation id='Message.Api.Error' />,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (location?.state?.reload) {
      getBanners();
    }
  }, [location.key]);

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <>
      <List banners={banners} />
      <ProtectedRoute
        entity={'Banners'}
        path={`${urlSchema.banners.update()}`}
        component={CreateEditModal}
        right='CRUD'
      />
      <ProtectedRoute
        entity={'Banners'}
        path={`${urlSchema.banners.create()}`}
        right='CRUD'
        component={() => <CreateEditModal getBanners={getBanners} />}
      />
    </>
  );
};

export default Banners;
