import { defineMessages } from 'react-intl';

export default defineMessages({
  Title: {
    id: 'Screens.Patients.Create.CheckEligibility.Title',
    defaultMessage: 'Error.Screens.Patients.Create.CheckEligibility.Title',
  },
  ErrorMessage: {
    id: 'Screens.Patients.Create.CheckEligibility.ErrorMessage',
    defaultMessage:
      'Error.Screens.Patients.Create.CheckEligibility.ErrorMessage',
  },
  Birthdate: {
    id: 'Shared.Birthdate',
    defaultMessage: 'Error.Shared.Birthdate',
  },
  Lastname: {
    id: 'Shared.Lastname',
    defaultMessage: 'Error.Shared.Lastname',
  },
  Firstname: {
    id: 'Shared.Firstname',
    defaultMessage: 'Error.Shared.Firstname',
  },
  EligibilityCode: {
    id: 'Screens.Patients.Create.CheckEligibility.EligibilityCode',
    defaultMessage:
      'Error.Screens.Patients.Create.CheckEligibility.EligibilityCode',
  },
});
