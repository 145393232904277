import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { toaster } from '@teladochealth/components';

import { PageHeader, Translation } from 'components';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';

const deleteAccount = (patientId) =>
  service.delete(api.patients.delete(patientId));

const Delete = () => {
  const history = useHistory();
  const { patientId } = useParams();
  const { formatMessage } = useIntl();
  const [patient] = useFetch(api.patients.get(patientId));

  return (
    <>
      <PageHeader>
        <h1>
          <Translation
            id='Screens.Patients.Delete.Title'
            values={{ name: `${patient.lastname} ${patient.firstname}` }}
          />
        </h1>
      </PageHeader>
      <div className='row'>
        <div className='col-6'>
          <div className='section'>
            <div className='section__body'>
              <p className='m-0'>
                <Translation
                  id='Screens.Patients.Delete.Description'
                  values={{
                    br: (
                      <>
                        <br />
                        <br />
                      </>
                    ),
                  }}
                />
              </p>
              <p className='m-0 d-flex align-items-center mt-3'>
                <i
                  aria-hidden='true'
                  className='uil uil-exclamation-triangle mr-2'
                />
                <Translation id='Screens.Patients.Delete.Warning' tagName='b' />
              </p>
              <div className='mt-3 text-right'>
                <button
                  className='btn btn-primary'
                  type='button'
                  onClick={async () => {
                    if (
                      window.confirm(
                        formatMessage({
                          id: 'Screens.Patients.Delete.Confirm',
                          defaultMessage:
                            'Error.Screens.Patients.Delete.Confirm',
                        }),
                      )
                    ) {
                      try {
                        await deleteAccount(patientId);
                        toaster({
                          title: <Translation id='Shared.Success' />,
                          text: (
                            <Translation
                              id='Toasts.Patients.Delete.Success'
                              values={{
                                name: `${patient.lastname} ${patient.firstname}`,
                              }}
                            />
                          ),
                          type: 'success',
                        });
                        history.push(urlSchema.patients.list);
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }}
                >
                  <Translation id='Buttons.DeleteAccount' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Delete.displayName = 'Screens_Patients_Delete';

export default Delete;
