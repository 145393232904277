import React from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useParams } from 'react-router-dom';
import { Row, Col, FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import * as Yup from 'yup';
import { toaster } from '@teladochealth/components';

import { Button, FormPlacesAutoComplete, Translation } from 'components';
import { service } from 'services';
import { api } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';

const UpdateAddressTab = ({ address, setPrerequisitesTrigger }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { patientId } = useParams();

  const validationSchema = Yup.object().shape({
    address1: Yup.string().required(
      formatMessage({
        id: 'Screens.Dialogues.Create.UpdateAddress.AddressError',
        defaultMessage:
          'Error.Screens.Dialogues.Create.UpdateAddress.AddressError',
      }),
    ),
    address2: Yup.string(),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const { data } = await service.put(
        api.admin.updatePatientAddress(patientId),
        values,
      );

      if (data.succeeded === false) {
        throw new Error(data.errorSet[0].code);
      }

      setPrerequisitesTrigger(Math.random());
      toaster({
        title: <Translation id='Shared.Success' />,
        text: <Translation id='Shared.Toast.Update.Success' />,
        type: 'success',
      });
    } catch (error) {
      toaster({
        title: <Translation id='Shared.Error' />,
        text: <Translation id='Shared.Toast.Update.Fail' />,
        type: 'error',
      });
      console.log(
        'An unexpected error happened while updating patient adress',
        error.message,
      );
    } finally {
      setSubmitting(false);
    }
  };

  const validate = ({ city, zipCode, codeCountry }) => {
    if (!city || !zipCode || !codeCountry) {
      return {
        address1: formatMessage({
          id: 'Screens.Dialogues.Create.UpdateAddress.AddressError',
          defaultMessage:
            'Error.Screens.Dialogues.Create.UpdateAddress.AddressError',
        }),
      };
    }
    return {};
  };

  return (
    <>
      <Formik
        initialValues={{
          ...address,
          googleAddress: '',
          address1: address.address1 ?? '',
          address2: address.address2 ?? '',
        }}
        validationSchema={validationSchema}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <Row>
              <Col md='12'>
                <Field
                  name='address1'
                  placeholder={formatMessage({
                    id: 'Screens.Dialogues.Create.UpdateAddress.AddressPlaceholder',
                    defaultMessage:
                      'Error.Screens.Dialogues.Create.UpdateAddress.AddressPlaceholder',
                  })}
                  label={
                    <Translation id='Screens.Dialogues.Create.UpdateAddress.AddressLabel' />
                  }
                  component={FormPlacesAutoComplete}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <Field name='address2'>
                  {({ field }) => (
                    <FormGroup>
                      <Label for={field.name}>
                        <Translation id='Screens.Dialogues.Create.UpdateAddress.Address2Label' />
                      </Label>
                      <Input
                        placeholder={formatMessage({
                          id: 'Screens.Dialogues.Create.UpdateAddress.Address2Placeholder',
                          defaultMessage:
                            'Error.Screens.Dialogues.Create.UpdateAddress.Address2Placeholder',
                        })}
                        {...field}
                        invalid={!!errors[field.name] && !!touched[field.name]}
                      />
                      <ErrorMessage name={field.name}>
                        {(message) => (
                          <FormFeedback valid={false}>{message}</FormFeedback>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  )}
                </Field>
              </Col>
            </Row>
            <ButtonsWrapper bottom>
              <Button
                type='submit'
                color='primary'
                label='Confirm'
                disabled={isSubmitting}
              />
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateAddressTab;
