import { create } from 'zustand';
import { InsDocumentValidationStatus } from './types';

type IdentityVerificationNhiStore = {
  parameters: {
    SearchString: string;
    DocumentStatusSelected: InsDocumentValidationStatus;
  };
  selectedPatientId: number;
  setParameters: ({
    SearchString,
    DocumentStatusSelected,
  }: {
    SearchString?: string;
    DocumentStatusSelected?: InsDocumentValidationStatus;
  }) => void;
  setSelectedPatientId: (patientId?: number) => void;
};

const defaultStoreValues = {
  parameters: {
    DocumentStatusSelected: InsDocumentValidationStatus.Pending,
    SearchString: '',
  },
  selectedPatientId: undefined,
};

export const useIdentityVerificationNhiStore =
  create<IdentityVerificationNhiStore>()((set) => ({
    ...defaultStoreValues,

    setParameters: (newParameters) => {
      set((store) => ({
        parameters: {
          ...store.parameters,
          ...newParameters,
        },
      }));
    },

    setSelectedPatientId: (selectedPatientId) => {
      set(() => ({
        selectedPatientId,
      }));
    },
  }));
