import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { toaster } from '@teladochealth/components';

import {
  ApiError,
  Button,
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  Translation,
} from 'components';
import { Product } from 'models';
import validationSchema from 'models/Product/validationSchema';
import ConfigurationStore from 'stores/Configuration';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';

const Update = ({ history, match }) => {
  const intl = useIntl();
  const { params } = match;
  const [product, isProductLoading] = useFetch(
    api.manager.getProductDetails(params.productId),
  );
  const [logo, setLogo] = useState(null);

  const onSubmit = (values, { setSubmitting }) => {
    delete values.rawFile;

    service
      .put(api.manager.putProduct(params.productId), values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          toaster({
            title: <Translation id='Message.Error' />,
            text: (
              <>
                <Translation
                  id='Screens.Products.Update.ToastFail'
                  values={{ label: values.label }}
                  tagName='p'
                />
                {data.errorSet.map((error) => (
                  <p key={error.code}>
                    {error.code}: {error.description}
                  </p>
                ))}
              </>
            ),
            type: 'error',
          });
          return;
        }
        if (logo === null) {
          toaster({
            title: <Translation id='Message.Success' />,
            text: (
              <Translation
                id='Screens.Products.Update.ToastSuccess'
                values={{ label: values.label }}
              />
            ),
            type: 'success',
          });
          history.push(urlSchema.products.list);
        } else {
          service
            .post(api.manager.uploadProductLogo(params.productId), logo)
            .then(() => {
              toaster({
                title: <Translation id='Message.Success' />,
                text: (
                  <Translation
                    id='Screens.Products.Update.ToastSuccess'
                    values={{ label: values.label }}
                  />
                ),
                type: 'success',
              });
              history.push(urlSchema.products.list);
            });
        }
      })
      .catch((error) => {
        // TODO: Need to fix API sending 500
        toaster({
          title: <Translation id='Message.Error' />,
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation id='Screens.Products.Update.Title' tagName='h1' />
      </PageHeader>
      <Section>
        <SectionBody>
          {isProductLoading ||
          ConfigurationStore.verificationsTypes.length === 0 ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <Formik
              initialValues={{
                ...product,
                dateExpiration: product.dateExpiration
                  ? moment(product.dateExpiration).format('YYYY-MM-DD')
                  : '',
                refVerificationType:
                  product?.refVerificationTypeNavigation?.idVerificationType ??
                  '',
                refProductTerm:
                  product?.refProductTermNavigation?.idProductTerm ?? '',
              }}
              {...{ onSubmit }}
              validationSchema={validationSchema(
                ConfigurationStore.getRefWebServiceType(),
                intl,
              )}
            >
              {({ isSubmitting, ...properties }) => (
                <Form>
                  <Product setLogo={setLogo} {...properties} />
                  <ButtonsWrapper bottom>
                    <Button
                      color='primary'
                      isProcessing={isSubmitting}
                      label='Edit'
                      size='lg'
                      type='submit'
                    />
                  </ButtonsWrapper>
                </Form>
              )}
            </Formik>
          )}
        </SectionBody>
      </Section>
    </>
  );
};

export default Update;
