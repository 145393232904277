import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormattedDate as RIFormattedDate } from 'react-intl';
import { toaster } from '@teladochealth/components';

import { service } from 'services';
import { Button, FormattedDate, RoleManager, Translation } from 'components';
import { api, urlSchema } from 'utils';
import InformationWrapper from './elements';

const patientData = (data) => [
  { id: 'data-email', title: 'Shared.Email', value: data.email },
  { id: 'data-mobile', title: 'Shared.Mobile', value: data.mobileNumber },
  {
    id: 'data-birthdate',
    title: 'Shared.Birthdate',
    value: <RIFormattedDate value={data.birthdate} />,
  },
  {
    id: 'data-notification',
    title: 'Shared.NotificationPreference',
    value: data.notification,
  },
];

const PatientInformation = ({ patient, refresh, logs }) => {
  const lastLogin =
    logs
      .sort((logA, logB) => logA.createdAt > logB.createdAt)
      .find((log) => log.code === 'USER_CONNEXION') || null;

  const sendNewPasswordEmail = () => {
    service
      .put(api.manager.forgotPassword(patient.idUser))
      .then(() => {
        toaster({
          title: <Translation id='Message.Success' />,
          text: (
            <Translation id='Screens.Patients.Read.PatientInformation.NewPasswordSuccess' />
          ),
          type: 'success',
        });
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: (
            <Translation id='Screens.Patients.Read.PatientInformation.NewPasswordError' />
          ),
          type: 'error',
        });
      });
  };
  const switchIdentityValidation = (valid) => {
    if (valid) {
      service
        .put(api.manager.validateUser(patient.idUser))
        .then(() => {
          refresh(Math.random());
          toaster({
            title: <Translation id='Message.Success' />,
            text: (
              <Translation
                id='Screens.Patients.Read.PatientInformation.ValidIdentitySuccess'
                values={{ name: `${patient.firstname} ${patient.lastname}` }}
              />
            ),
            type: 'success',
          });
        })
        .catch(() => {
          toaster({
            title: <Translation id='Message.Error' />,
            text: (
              <Translation
                id='Screens.Patients.Read.PatientInformation.ValidIdentityError'
                values={{ name: `${patient.firstname} ${patient.lastname}` }}
              />
            ),
            type: 'error',
          });
        });
    } else {
      service
        .put(api.manager.unvalidateUser(patient.idUser))
        .then(() => {
          refresh(Math.random());
          toaster({
            title: <Translation id='Message.Success' />,
            text: (
              <Translation
                id='Screens.Patients.Read.PatientInformation.InvalidIdentitySuccess'
                values={{ name: `${patient.firstname} ${patient.lastname}` }}
              />
            ),
            type: 'success',
          });
        })
        .catch(() => {
          toaster({
            title: <Translation id='Message.Error' />,
            text: (
              <Translation
                id='Screens.Patients.Read.PatientInformation.ValidIdentityError'
                values={{ name: `${patient.firstname} ${patient.lastname}` }}
              />
            ),
            type: 'error',
          });
        });
    }
  };

  return (
    <>
      {lastLogin && (
        <InformationWrapper.LastSeenText>
          <strong>
            <Translation id='Screens.Patients.Read.PatientInformation.LastSeen' />
          </strong>
          <FormattedDate value={lastLogin.createdAt} />
        </InformationWrapper.LastSeenText>
      )}
      <InformationWrapper.StatusWrapper>
        <>
          {patient.valid ? (
            <Badge size='xl' color='primary'>
              <Translation id='Shared.Valid' />
            </Badge>
          ) : (
            <Badge size='xl' color='danger'>
              <Translation id='Shared.Invalid' />
            </Badge>
          )}
          {patient.infosPatient.isIdentityValidationApproved ? (
            <Badge color='primary'>
              <Translation id='Screens.Patients.Read.PatientInformation.IdentityValid' />
            </Badge>
          ) : (
            <Badge color='danger'>
              <Translation id='Screens.Patients.Read.PatientInformation.IdentityInvalid' />
            </Badge>
          )}
        </>
      </InformationWrapper.StatusWrapper>
      <InformationWrapper.ButtonsWrapper>
        <Button
          onClick={() => sendNewPasswordEmail()}
          outline
          size='sm'
          color='primary'
          label='GeneratePassword'
        />
        {patient.infosPatient.isIdentityValidationApproved ? (
          <Button
            onClick={() => switchIdentityValidation(false)}
            outline
            size='sm'
            color='danger'
            label='InvalidateIdentity'
          />
        ) : (
          <Button
            onClick={() => switchIdentityValidation(true)}
            outline
            size='sm'
            color='primary'
            label='ValidateIdentity'
          />
        )}
        <RoleManager entity='Patients' right='CRUD'>
          <Link
            className='btn btn-outline-danger btn-sm'
            to={`${urlSchema.patients.delete}/${patient.idUser}`}
          >
            <Translation id='Buttons.DeleteAccount' />
          </Link>
        </RoleManager>
      </InformationWrapper.ButtonsWrapper>
      <Row>
        <Col md='6'>
          <InformationWrapper.SectionTitle>
            <Translation id='Screens.Patients.Read.PatientInformation.UserInformation' />
          </InformationWrapper.SectionTitle>
          {patientData(patient).map((element) => (
            <div className='model__property' key={element.id}>
              <h5 className='model__title'>
                <Translation id={element.title} />
              </h5>
              <p className='model__value'>{element.value}</p>
            </div>
          ))}
          {patient.address1 && (
            <div className='model__property'>
              <h5 className='model__title'>
                <Translation id='Shared.Address' />
              </h5>
              <p className='model__value'>
                {patient.address1}, {patient.zipCode}, {patient.city}
              </p>
            </div>
          )}
          {patient.address2 && (
            <div className='model__property'>
              <h5 className='model__title'>
                <Translation id='Shared.AdditionalAddress' />
              </h5>
              <p className='model__value'>{patient.address2} </p>
            </div>
          )}
        </Col>
        <Col md='6'>
          <InformationWrapper.SectionTitle>
            <Translation id='Screens.Patients.Read.PatientInformation.AccountInformation' />
          </InformationWrapper.SectionTitle>
          <div className='model__property'>
            <h5 className='model__title'>
              <Translation id='Shared.AccountCreationDate' />
            </h5>
            <p className='model__value'>
              <FormattedDate origin='fr' value={patient.dateCreation} />
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

PatientInformation.propTypes = {
  patient: PropTypes.shape().isRequired,
  refresh: PropTypes.func.isRequired,
};

export default PatientInformation;
