import { useQuery } from '@tanstack/react-query';
import { STALE_TIMES } from './constants';
import { getDocument } from 'screens/IdentityValidationsNhi/services/getDocument';

export const identityValidationsNhiDocumentQueryKeys = {
  all: ['identityValidationsNhiDocument'],
  forDocument: (idDocument: string) => [
    ...identityValidationsNhiDocumentQueryKeys.all,
    { idDocument },
  ],
};

export const useIdentityValidationNhiDocument = (idDocument: string) =>
  useQuery({
    queryKey: identityValidationsNhiDocumentQueryKeys.forDocument(idDocument),
    queryFn: () => getDocument(idDocument),
    staleTime: STALE_TIMES.EXTRA_LONG,
  });
