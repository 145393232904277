import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { Spinner } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import { ApiError, Button, FormList, Translation } from 'components';
import { service } from 'services';
import { api, useFetch } from 'utils';

const Clients = ({ data }) => {
  const { partnerOperatorId } = useParams();
  const { formatMessage } = useIntl();
  const [clients, areClientsLoading] = useFetch(api.clients.getListItems);

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.partnerOperators.putClients(values.idUser), values)
      .then((result) => {
        if (result.data.succeeded === false) {
          toaster({
            text: (
              <Translation id='Toasts.PartnerOperators.Update.Clients.Fail' />
            ),
            type: 'error',
          });
          return;
        }
        toaster({
          text: (
            <Translation id='Toasts.PartnerOperators.Update.Clients.Success' />
          ),
          type: 'success',
        });
      })
      .catch((error) => {
        toaster({
          title: (
            <Translation id='Toasts.PartnerOperators.Update.Clients.Fail' />
          ),
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  if (areClientsLoading) return <Spinner color='primary' size='lg' />;

  return (
    <Formik
      initialValues={{
        idUser: partnerOperatorId,
        selectedClients: data,
      }}
      onSubmit={onSubmit}
    >
      {(formikProperties) => (
        <Form>
          <FormList
            data={clients}
            itemLabelKey='label'
            itemValueKey='id'
            name='selectedClients'
            researchOptions={{
              isEnabled: true,
              placeholder: formatMessage({
                id: 'Components.FormList.ClientsPlaceholder',
                defaultMessage: 'Error.Components.FormList.ClientsPlaceholder',
              }),
            }}
          />
          <Button
            color='primary'
            isProcessing={formikProperties.isSubmitting}
            label='Save'
            type='submit'
          />
        </Form>
      )}
    </Formik>
  );
};

export default Clients;
