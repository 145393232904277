import PropTypes from 'prop-types';
import React from 'react';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import { Button, PageHeader, Translation } from 'components';
import { WebService } from 'models';
import { service } from 'services';
import { api, urlSchema } from 'utils';
import ValidationSchema from './ValidationSchema';

const Create = ({ history }) => {
  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.webServices.postWebService, values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          toaster({
            title: (
              <Translation
                id='Toasts.WebServices.Update.Fail'
                values={{ name: values.name }}
              />
            ),
            text: data.errorSet[0].description,
            type: 'error',
          });
        } else {
          toaster({
            text: (
              <Translation
                id='Toasts.WebServices.Update.Success'
                values={{ name: values.name }}
              />
            ),
            type: 'success',
          });
          history.push(`${urlSchema.webServices.read}/${data.idWebService}`);
        }
      })
      .catch((error) => {
        toaster({
          title: (
            <Translation
              id='Toasts.WebServices.Update.Fail'
              values={{ name: values.name }}
            />
          ),
          text: (
            <>
              <span className='m-0 small'>
                code: {error?.response?.status} ({error?.response?.statusText})
              </span>
              <br />
              <span className='m-0 small'>
                URL: {error?.response?.config?.url}
              </span>
            </>
          ),
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    name: '',
    className: '',
    isValid: false,
  };

  return (
    <>
      <PageHeader>
        <h1>
          <Translation id='Screens.WebServices.Create.Title' />
        </h1>
      </PageHeader>
      <Row>
        <Col md='6'>
          <section className='section'>
            <div className='section__body'>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={ValidationSchema}
              >
                {(formikProperties) => (
                  <Form>
                    <WebService {...formikProperties} />
                    <div className='text-right'>
                      <Button
                        color='primary'
                        isProcessing={formikProperties.isSubmitting}
                        label='Create'
                        size='lg'
                        type='submit '
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

Create.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Create;
