import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import qs from 'qs';
import { toaster } from '@teladochealth/components';

import {
  Button,
  FormSelect,
  PageHeader,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';

import { api, removeKeysWithEmptyString, urlSchema, useFetch } from 'utils';

const url = new URL(window.location.href);
const parameters = new URLSearchParams(url.search);

const List = () => {
  const intl = useIntl();
  const [partner, isPartnerLoading] = useFetch(api.admin.getClientsCode);
  const [isLoading, setIsLoading] = useState(false);
  const [fileImports, setFileImports] = useState([]);
  const [partnerArray, setPartnerArray] = useState([]);

  const setWindowLocationSearch = (values) => {
    const nextURL = `/FileImports?${qs.stringify(values, {
      skipNulls: true,
    })}`;
    window.history.replaceState({}, null, nextURL);
  };

  const getResults = (values = {}, callback) => {
    const parameters = qs.stringify(
      removeKeysWithEmptyString({ ...values, Limit: 100 }),
      {
        skipNulls: true,
      },
    );
    setIsLoading(true);
    service
      .get(`${api.admin.getFileImports}?${parameters}`)
      .then(({ data }) => {
        setFileImports(data);
        setIsLoading(false);
      })
      .catch(({ data }) => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: (
            <>
              {data.errorSet.map((error) => (
                <p key={error.code}>
                  {error.code}: {error.description}
                </p>
              ))}
            </>
          ),
          type: 'error',
        });
      })
      .then(() => {
        if (typeof callback === 'function') callback();
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    setWindowLocationSearch(values);
    getResults(values, () => {
      setSubmitting(false);
    });
  };

  useEffect(() => {
    const consolidatedPartners = [];
    if (!isPartnerLoading)
      partner.map((element) => {
        consolidatedPartners.push({
          label: element,
          value: element,
        });
      });
    setPartnerArray(consolidatedPartners);
  }, [isPartnerLoading]);

  useEffect(() => {
    getResults(qs.parse(window.location.search, { ignoreQueryPrefix: true }));
  }, []);

  return (
    <>
      <PageHeader>
        <Translation id='Screens.FileImports.List.Title' tagName='h1' />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <Formik
            initialValues={{
              SearchCode: parameters.get('SearchCode') || null,
              SearchFirstname: parameters.get('SearchFirstname') || null,
              SearchLastname: parameters.get('SearchLastname') || null,
              SearchBirthdate: parameters.get('SearchBirthdate') || null,
              PartnerCodes: parameters.get('PartnerCodes') || null,
            }}
            {...{ onSubmit }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row className='align-items-center'>
                  <Col md='2'>
                    <Field
                      className='form-control'
                      id='search-code'
                      placeholder={intl.formatMessage({
                        id: 'Screens.FileImports.List.Code',
                        defaultMessage: 'Error.Screens.FileImports.List.Code',
                      })}
                      type='text'
                      name='SearchCode'
                    />
                  </Col>
                  <Col md='2'>
                    <Field
                      className='form-control'
                      id='search-firstname'
                      placeholder={intl.formatMessage({
                        id: 'Screens.FileImports.List.Firstname',
                        defaultMessage:
                          'Error.Screens.FileImports.List.Firstname',
                      })}
                      type='text'
                      name='SearchFirstname'
                    />
                  </Col>
                  <Col md='2'>
                    <Field
                      className='form-control'
                      id='search-lastname'
                      placeholder={intl.formatMessage({
                        id: 'Screens.FileImports.List.Lastname',
                        defaultMessage:
                          'Error.Screens.FileImports.List.Lastname',
                      })}
                      type='text'
                      name='SearchLastname'
                    />
                  </Col>
                  <Col md='2'>
                    <Field
                      className='form-control'
                      id='search-date'
                      type='date'
                      name='SearchBirthdate'
                    />
                  </Col>
                  <Col md='2' className='mt-3'>
                    {partnerArray.length > 0 ? (
                      <Field name='PartnerCodes' className='mb-0'>
                        {({ form, meta, field }) => (
                          <FormSelect
                            {...{ meta }}
                            placeholder={
                              <Translation id='Screens.FileImports.List.Placeholder.Partners' />
                            }
                            hasError={Boolean(meta.touched && meta.error)}
                            name={field.name}
                            onChange={(opt) => {
                              form.setFieldValue(field.name, opt.value);
                            }}
                            options={[
                              { label: '-', value: '' },
                              ...partnerArray,
                            ]}
                            required
                          />
                        )}
                      </Field>
                    ) : (
                      <Spinner color='primary' />
                    )}
                  </Col>
                  <Col className='ml-auto' md='auto'>
                    <Button
                      data-tracking='FileImportsListBtnSearch'
                      color='primary'
                      isProcessing={isSubmitting}
                      label='Research'
                      type='submit'
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <header className='research-table__header mt-3'>
            <Container fluid>
              <Row>
                <Col md='2'>
                  <h5>
                    <Translation id='Screens.FileImports.List.Code' />
                  </h5>
                </Col>
                <Col md='2'>
                  <h5>
                    <Translation id='Screens.FileImports.List.LastFirstName' />
                  </h5>
                </Col>
                <Col md='2'>
                  <h5>
                    <Translation id='Screens.FileImports.List.Birthdate' />
                  </h5>
                </Col>
                <Col md='2'>
                  <h5>
                    <Translation id='Screens.FileImports.List.Partner' />
                  </h5>
                </Col>
                <Col md='2'>
                  <h5>
                    <Translation id='Screens.FileImports.List.Entity' />
                  </h5>
                </Col>
                <Col md='2'>
                  <h5>
                    <Translation id='Screens.FileImports.List.ProductAssociated' />
                  </h5>
                </Col>
              </Row>
            </Container>
          </header>
          {isLoading ? (
            <Spinner size='lg' />
          ) : (
            <ul className='research-table__results'>
              {fileImports.length > 0 ? (
                fileImports.map((item) => {
                  return (
                    <li key={item.idFileImport}>
                      <Container fluid>
                        <Row className='align-items-center'>
                          <Col md='2'>
                            <p className='m-0'>{item.registrationNumber}</p>
                          </Col>
                          <Col md='2'>
                            <p className='m-0'>{item.firstname}</p>
                            <p className='m-0'>{item.lastname}</p>
                          </Col>
                          <Col md='2'>
                            <p className='m-0'>
                              {moment(item.birthdate).format('LL')}
                            </p>
                          </Col>
                          <Col md='2'>
                            <p className='m-0'>{item.codePartner}</p>
                          </Col>
                          <Col md='2'>
                            <p className='m-0'>{item.codeEntity}</p>
                          </Col>
                          <Col md='2'>
                            <Link
                              to={`${urlSchema.products.read}/${item.refProduct}`}
                              className='m-0 btn btn-sm btn-outline-primary'
                            >
                              {item.productLabel}
                            </Link>
                          </Col>
                        </Row>
                      </Container>
                    </li>
                  );
                })
              ) : (
                <p className='text-secondary mt-3 mb-0'>
                  <Translation id='Shared.NoElement' />
                </p>
              )}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
