import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, useFetch, urlSchema } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { OrganizationConfig } from 'models';
import validationSchema from './validationShema';

export const Update = () => {
  const history = useHistory();
  const { idOrganizationConfig } = useParams();
  const [organizationConfig, isOrganizationConfigLoading] = useFetch(
    api.admin.getOrganizationConfigById(idOrganizationConfig),
  );

  const onSubmit = (values, { setSubmitting }) => {
    let newValues = {
      idOrganizationConfig: idOrganizationConfig,
      code: values.code,
      label: values.label,
    };

    service
      .put(api.admin.putOrganizationConfig(idOrganizationConfig), newValues)
      .then(() => {
        toaster({
          title: <Translation id='Message.Success' />,
          text: (
            <Translation
              id='Sreens.OrganizationConfigs.Update.SuccessBody'
              values={{
                organizationConfigCode: organizationConfig.code,
              }}
            />
          ),
          type: 'success',
        });
        setSubmitting(false);
        history.push(
          `${urlSchema.detailsOrganizationConfigs}/${idOrganizationConfig}`,
        );
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: (
            <Translation
              id='Sreens.OrganizationConfigs.Update.FailBody'
              values={{
                organizationConfigCode: organizationConfig.code,
              }}
            />
          ),
          type: 'error',
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <div className='m-2'>
          <Translation
            id='Screens.OrganizationConfigs.Edit.Title'
            values={{
              organizationConfigCode: organizationConfig.code || '',
            }}
            tagName='h1'
          />
        </div>
      </PageHeader>
      {isOrganizationConfigLoading ? (
        <Spinner size='lg' />
      ) : (
        <Row>
          <Col md='7'>
            <Section>
              <SectionBody>
                <Formik
                  initialValues={organizationConfig}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <OrganizationConfig />
                      <ButtonsWrapper bottom>
                        <Button
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Update'
                          size='lg'
                          data-tracking='ApplicationCreateSubmit'
                          type='submit'
                        />
                      </ButtonsWrapper>
                    </Form>
                  )}
                </Formik>
              </SectionBody>
            </Section>
          </Col>
        </Row>
      )}
    </>
  );
};
