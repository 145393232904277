import React, { useState } from 'react';
import { matchShape } from 'react-router-props';
import { Col, Row } from 'reactstrap';
import AceEditor from 'react-ace';
import { Formik, Form, FieldArray, FastField, Field } from 'formik';
import { useTheme } from 'styled-components';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-javascript';

import { toaster } from '@teladochealth/components';
import { service } from 'services';
import { api, useFetch, urlSchema } from 'utils';
import { validationSchema } from 'models/Surveys/validationSchema';

import {
  Button,
  Checkbox,
  FormInput,
  FormInputFeedback,
  FormInputLogo,
  FormList,
  PageHeader,
  Spinner,
  Translation,
} from 'components';

const Update = ({ match, history }) => {
  const { surveyId } = match.params;
  const theme = useTheme();

  const [survey, isSurveyLoading] = useFetch(
    api.questionnaires.getDetails(surveyId),
  );

  const [profiles, areProfilesLoading] = useFetch(api.profiles.get);
  const [products, areProductsLoading] = useFetch(api.admin.getProducts);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [specialties] = useFetch(api.admin.getSpecialties, {
    isValid: true,
    searchString: '',
    refOrganizationConfig: '',
  });

  const aceEditorStyle = {
    border: `1px solid ${theme.color('border')}`,
    borderRadius: theme.borderRadius,
    width: '100%',
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    const { rawFile, ...allOtherValues } = e;
    const postPicture = typeof rawFile === 'object' && rawFile !== null;
    const { idQuestionnaire } = survey;
    if (postPicture) {
      const newFormData = new FormData();
      newFormData.append(rawFile.name, rawFile);
      try {
        await service.post(
          api.questionnaires.upload(idQuestionnaire),
          newFormData,
        );
      } catch (error) {
        console.error(error);
      }
    }

    service
      .put(api.questionnaires.put(surveyId), {
        idQuestionnaire,
        ...allOtherValues,
      })
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          toaster({
            title: (
              <Translation id='Screens.Questionnaires.Update.Toasts.Profiles.Update.Error' />
            ),
            text: data.errorSet[0].description,
            type: 'error',
          });
        } else {
          toaster({
            text: (
              <Translation id='Screens.Questionnaires.Update.Toasts.Profiles.Update.Success' />
            ),
            type: 'success',
          });
          history.push(`${urlSchema.surveyDetails}/${idQuestionnaire}`);
        }
      })
      .catch(() => {
        toaster({
          text: (
            <Translation id='Screens.Questionnaires.Update.Toasts.Profiles.Update.Error' />
          ),
          type: 'error',
        });
      })
      .then(() => {
        setIsSubmitting(false);
      });
  };

  return isSurveyLoading ? null : (
    <Formik
      {...{ validationSchema }}
      onSubmit={handleSubmit}
      initialValues={{
        title: survey?.title,
        message: survey?.message,
        valid: survey.valid,
        isGenerateQuestion: survey.isGenerateQuestion,
        selectedProducts: survey.questionnairesProducts.map(
          (c) => c.refProduct,
        ),
        selectedProfiles: survey.questionnairesProfiles.map(
          (c) => c.refProfile,
        ),
        rawFile: survey.rawFile,
        refSpecialty: survey.refSpecialty,
        jsonContent: survey.jsonContent,
        jsContent: survey.jsContent,
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <PageHeader>
            <Translation
              tagName='h1'
              id='screens_questionnaire_update_title'
              values={{ name: survey?.title }}
            />
          </PageHeader>
          <Row>
            <Col lg='8'>
              <div className='section'>
                {isSurveyLoading ? (
                  <Spinner color='primary' size='lg' />
                ) : (
                  <>
                    <div className='section__body'>
                      <div className='model'>
                        <Row>
                          <Col md='12' className='d-flex'>
                            <FormInputLogo
                              defaultImage={
                                values.rawFile
                                  ? {
                                      contentType: 'image/*',
                                      rawFile: values.rawFile,
                                    }
                                  : undefined
                              }
                              name='rawFile'
                              setFormLogoData={(element) =>
                                setFieldValue('rawFile', element)
                              }
                            />
                          </Col>
                          <Col md='12'>
                            <div className='model__property'>
                              <FormInput
                                id='code'
                                label={<Translation id='shared_title' />}
                                name='title'
                                required
                                type='text'
                              />
                            </div>
                            <div className='model__property'>
                              <FormInput
                                id='message'
                                label={<Translation id='shared_description' />}
                                name='message'
                                required
                                type='textarea'
                              />
                            </div>
                          </Col>
                          <Col md='12'>
                            <div className='model__property'>
                              <h4 className='model__title'>
                                <Translation id='screens_questionnaire_JSON_structure_title' />
                              </h4>
                              <div className='form-group'>
                                <AceEditor
                                  defaultValue={values.jsonContent}
                                  showGutter
                                  mode='json'
                                  name='jsonContent'
                                  onChange={(value) => {
                                    setFieldValue('jsonContent', value);
                                  }}
                                  style={aceEditorStyle}
                                  theme='monokai'
                                  height='400px'
                                  wrapEnabled
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md='12'>
                            <div className='model__property'>
                              <h4 className='model__title'>
                                <Translation id='screens_questionnaire_javascript_title' />
                              </h4>
                              <AceEditor
                                defaultValue={values.jsContent}
                                onChange={(value) => {
                                  setFieldValue('jsContent', value);
                                }}
                                showGutter
                                mode='javascript'
                                id='jscontent'
                                name='jsContent'
                                style={aceEditorStyle}
                                theme='monokai'
                                height='150px'
                                wrapEnabled
                              />
                            </div>
                          </Col>

                          <Col md='12'>
                            <div className='section'>
                              {isSurveyLoading || areProfilesLoading ? (
                                <Spinner color='primary' size='lg' />
                              ) : (
                                <>
                                  <div className='section__header'>
                                    <h1>
                                      <Translation id='Buttons.Profiles' />
                                    </h1>
                                  </div>
                                  <div className='section__body'>
                                    <FieldArray
                                      name='selectedProfiles'
                                      render={({ push, remove }) => (
                                        <>
                                          {profiles.map((profile) => {
                                            const hasProfile =
                                              values.selectedProfiles.find(
                                                (t) => t === profile.idProfile,
                                              );
                                            const ico = hasProfile
                                              ? 'check'
                                              : 'plus';
                                            const buttonClass = hasProfile
                                              ? 'combined-btn combined-btn--success'
                                              : 'combined-btn';

                                            return (
                                              <button
                                                className={buttonClass}
                                                key={profile.idProfile}
                                                type='button'
                                                onClick={() => {
                                                  const result =
                                                    values.selectedProfiles.findIndex(
                                                      (element) =>
                                                        element ===
                                                        profile.idProfile,
                                                    );
                                                  if (
                                                    values.selectedProfiles.includes(
                                                      profile.idProfile,
                                                    )
                                                  ) {
                                                    remove(result);
                                                  } else {
                                                    push(profile.idProfile);
                                                  }
                                                }}
                                              >
                                                <div className='combined-btn__container'>
                                                  <span>{profile.label}</span>

                                                  <i
                                                    aria-hidden
                                                    className={`uil uil-${ico}`}
                                                  />
                                                </div>
                                              </button>
                                            );
                                          })}
                                        </>
                                      )}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </Col>
                          <Col md='12'>
                            <div className='model__property'>
                              <FastField name='isGenerateQuestion'>
                                {({ field, form }) => (
                                  <Checkbox
                                    {...field}
                                    checked={form.values.isGenerateQuestion}
                                    id='isGenerateQuestion'
                                  >
                                    <Translation id='screens_questionnaire_isGenerateQuestion_true' />
                                  </Checkbox>
                                )}
                              </FastField>
                              <FormInputFeedback name='isGenerateQuestion' />
                              {values.isGenerateQuestion && (
                                <>
                                  <h4 className='model__title'>
                                    <Translation
                                      id='shared_speciality'
                                      defaultMessage='error_shared_speciality'
                                    />
                                  </h4>
                                  <Field as='select' name='refSpecialty'>
                                    <option disabled>
                                      Selectionner une spécialité
                                    </option>
                                    {specialties.map((element) => (
                                      <option
                                        key={element.idSpecialty}
                                        value={element.idSpecialty}
                                        selected={
                                          element.idSpecialty ===
                                          survey.refSpecialty
                                        }
                                      >
                                        {element.label}
                                      </option>
                                    ))}
                                  </Field>
                                </>
                              )}
                            </div>
                            <div className='model__property'>
                              <FastField name='valid'>
                                {({ field, form }) => (
                                  <Checkbox
                                    {...field}
                                    checked={form.values.valid}
                                    id='valid'
                                  >
                                    <Translation id='Shared.Valid' />
                                  </Checkbox>
                                )}
                              </FastField>
                              <FormInputFeedback name='valid' />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Button
                color='primary'
                isProcessing={isSubmitting}
                label='Edit'
                size='lg'
                type='submit'
              />
            </Col>
            {/* Products */}
            <Col lg='4'>
              <div className='section'>
                {isSurveyLoading || areProductsLoading ? (
                  <Spinner color='primary' size='lg' />
                ) : (
                  <>
                    <div className='section__header'>
                      <h1>
                        <Translation id='Model.Profile.Products' />
                      </h1>
                    </div>
                    <div className='section__body'>
                      <FormList
                        data={products}
                        name='selectedProducts'
                        itemValueKey='idProduct'
                        itemLabelKey='code'
                        researchOptions={{
                          isEnabled: false,
                          placeholder: 'test',
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

Update.propTypes = { match: matchShape.isRequired };

export default Update;
