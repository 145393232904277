import React from 'react';
import { observer } from 'mobx-react-lite';
import { Services } from '../services';
import CsvStore from '../stores/CsvStore';
import { toaster } from '@teladochealth/components';

const errorEnums = {
  Unexpected: {
    header: 'Une erreur est survenue',
    body: 'Le traitement du fichier a échouer.',
  },
  HeaderNotValid: {
    header: 'Les en-têtes ne sont pas valides',
    body: 'Les en-têtes du fichier doivent être PARTENAIRE;ENTITE;NUMERO ADHERENT;NOM;PRENOM;DATE DE NAISSANCE;ProductTag.',
  },
  MissingField: {
    header: 'Champ manquant',
    body: 'Un élément est manquant dans le fichier.',
  },
  FieldNotValid: {
    header: 'Champ invalide',
    body: 'Le fichier contient des champs invalides.',
  },
  RowNotValid: {
    header: 'Ligne invalide',
    body: 'Le fichier contient des lignes invalides.',
  },
  PartnerOperationsMaxUploadSizeExceeded: {
    header: 'Taille du fichier incorrect',
    body: 'La taille du fichier ne dois pas dépasser 25mo.',
  },
};

const handleErrors = (apiResponse) => {
  const { data } = apiResponse;
  switch (true) {
    case Array.isArray(data) && data.length > 0: {
      const { validationResult } = data[0];
      const error = validationResult.errors[0];
      if (typeof error !== 'undefined') {
        const { errorCode } = error;
        toaster({
          title: <>{errorEnums[errorCode].header}</>,
          text: <>{errorEnums[errorCode].body}</>,
          type: 'error',
        });
      }
      break;
    }
    case typeof data.succeeded !== 'undefined' && data.succeeded === false: {
      toaster({
        title: <>{errorEnums.PartnerOperationsMaxUploadSizeExceeded.header}</>,
        text: <>{errorEnums.PartnerOperationsMaxUploadSizeExceeded.body}</>,
        type: 'error',
      });
      break;
    }
    default: {
      break;
    }
  }
};

const submitForm = () => {
  CsvStore.isProcessing = true;
  const formData = new FormData();
  const { filesToUpload } = CsvStore;
  Object.keys(filesToUpload).map((file) => {
    formData.append('partnersFiles', filesToUpload[file]);
  });
  Services.postPartnerOperationsCsv(CsvStore.selectedPartner, formData)
    .then((response) => {
      handleErrors(response);
      CsvStore.getPartnerOperationsCsv(CsvStore.selectedPartner);
      CsvStore.filesToUpload = {};
    })
    .finally(() => {
      CsvStore.isProcessing = false;
    });
};

export const SubmitFormButton = observer(() => {
  const noErrors = (errorLength) => errorLength === 0;
  const o = Object.keys(CsvStore.filesToUpload).map(
    (name) => CsvStore.filesToUpload[name].errors.length,
  );
  const isDisabled = o.length === 0 || !o.every(noErrors);
  return !isDisabled ? (
    <>
      <button
        disabled={CsvStore.isProcessing === true}
        className='btn btn-primary'
        onClick={submitForm}
      >
        <span className='fw-bold text-white'>Confirmer</span>
      </button>
    </>
  ) : null;
});
