import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { Button } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import { service } from 'services';
import { api } from 'utils';
import HealthPartnerWrapper from './elements';
import { Translation } from 'components';

const HealthPartner = ({ data, isInvalid, refresh }) => {
  const reactivateSubscription = () => {
    service
      .put(api.manager.reactivateSubscription(data.subscriptionId))
      .then(() => {
        refresh(Math.random());
        toaster({
          title: <Translation id='Message.Success' />,
          text: (
            <Translation
              id='Screens.Patients.Read.HealthPartnersList.HealthPartner.ReactivateSubscriptionSuccess'
              values={{ product: data.productCode }}
            />
          ),
          type: 'success',
        });
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: (
            <Translation
              id='Screens.Patients.Read.HealthPartnersList.HealthPartner.ReactivateSubscriptionError'
              values={{ product: data.productCode }}
            />
          ),
          type: 'error',
        });
      });
  };

  return (
    <HealthPartnerWrapper>
      <div>
        <img
          width='104'
          height='70'
          alt=''
          src={`data:image/gif;base64,${data.productLogoRawFile}`}
        />
        {isInvalid && (
          <>
            <Translation id='Screens.Patients.Read.HealthPartnersList.HealthPartner.ProductExpired' />
            <Button
              onClick={() => reactivateSubscription()}
              size='sm'
              type='button'
              color='primary'
              outline
            >
              <Translation id='Screens.Patients.Read.HealthPartnersList.HealthPartner.ReactivateSubscriptionButton' />
            </Button>
          </>
        )}
      </div>
      <div>
        <HealthPartnerWrapper.ProductTitle>
          {data.productCode}
        </HealthPartnerWrapper.ProductTitle>
        <HealthPartnerWrapper.ProductText>
          <Translation
            id='Screens.Patients.Read.HealthPartnersList.HealthPartner.Code'
            values={{ code: data.userCode }}
          />
        </HealthPartnerWrapper.ProductText>
        <HealthPartnerWrapper.ProductInfo>
          <Translation
            id='Screens.Patients.Read.HealthPartnersList.HealthPartner.SubscriptionDate'
            values={{
              date: <FormattedDate value={data.dateCreation} />,
            }}
          />
        </HealthPartnerWrapper.ProductInfo>
        <HealthPartnerWrapper.ProductInfo>
          <Translation
            id='Screens.Patients.Read.HealthPartnersList.HealthPartner.QuestionCount'
            values={{ count: data.questionCount }}
          />
        </HealthPartnerWrapper.ProductInfo>
      </div>
    </HealthPartnerWrapper>
  );
};

HealthPartner.defaultProps = {
  isValid: false,
  isInvalid: false,
  isPrincipal: false,
};

HealthPartner.propTypes = {
  data: PropTypes.shape({
    productCode: PropTypes.string.isRequired,
    subscriptionId: PropTypes.string.isRequired,
    dateCreation: PropTypes.string.isRequired,
    userCode: PropTypes.string.isRequired,
    questionCount: PropTypes.number.isRequired,
  }).isRequired,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  refresh: PropTypes.func.isRequired,
};

export default HealthPartner;
