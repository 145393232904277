import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { toaster } from '@teladochealth/components';

import { service } from 'services';
import { api, urlSchema } from 'utils';
import { Translation } from 'components';

import getValidationSchema from './validationSchema';
import CheckEligibility from './CheckEligibility';
import Information from './Information';
import initialValues from './initialValues';

const Create = () => {
  const { formatMessage } = useIntl();
  const validationSchema = getValidationSchema(formatMessage);
  const history = useHistory();

  const [step, setStep] = useState(0);

  const showError = (setFieldValue, setSubmitting) => {
    setSubmitting(false);
    toaster({
      title: <Translation id='Message.Error' />,
      text: <Translation id='Screens.Patients.Create.ElibilityError' />,
      type: 'error',
    });
    setFieldValue('isNotEligible', true);
  };

  const createPatient = (values, { setSubmitting }) => {
    service
      .post(api.manager.createPatient, {
        ...values,
        birthdate: moment(values.formattedBirthdate).format('YYYY-MM-DD'),
      })
      .then(({ data: createResponseData }) => {
        setSubmitting(false);
        if (createResponseData.succeeded === false) {
          throw createResponseData;
        }
        toaster({
          title: <Translation id='Message.Success' />,
          text: <Translation id='Screens.Patients.Create.CreationSuccess' />,
          type: 'success',
        });
        history.push(
          `${urlSchema.patients.readMd}/${createResponseData.idUser}`,
        );
      })
      .catch((error) => {
        const emailAlreadyUsed =
          error.errorSet[0].code === 'REGISTRATION_FAILED';
        toaster({
          title: <Translation id='Message.Error' />,
          text: emailAlreadyUsed ? (
            <Translation id='Screens.Patients.Create.AlreadyUsedEmailError' />
          ) : (
            <Translation id='Screens.Patients.Create.CreationError' />
          ),
          type: 'error',
        });
      });
  };

  const checkEligibility = (values, { setFieldValue, setSubmitting }) => {
    const { userCode, firstname, lastname } = values;

    const body = {
      userCode,
      firstname,
      lastname,
      birthdate: moment(values.formattedBirthdate).format('YYYY-MM-DD'),
    };

    service
      .post(api.identity.isSubscriptionAllowed, body)
      .then(({ data: subscriptionData }) => {
        if (subscriptionData.succeeded === false) {
          showError(setFieldValue, setSubmitting);
          return;
        }

        service
          .get(api.manager.getProduct(subscriptionData.idProduct))
          .then(({ data: productData }) => {
            if (productData.succeeded === false) {
              showError(setFieldValue, setSubmitting);
              return;
            }

            // success

            toaster({
              title: <Translation id='Message.Success' />,
              text: (
                <Translation
                  id='Screens.Patients.Create.ElibilitySuccess'
                  values={{
                    name: `${productData.code} (${productData.clientLabel})`,
                  }}
                />
              ),
              type: 'succes',
            });
            setFieldValue('refProduct', productData.idProduct);
            setFieldValue(
              'productLabel',
              `${productData.code} (${productData.clientLabel})`,
            );
            setFieldValue('isNotEligible', false);
            setSubmitting(false);
            setStep(1);
          })
          .catch(() => {
            showError(setFieldValue, setSubmitting);
          });
      })
      .catch(() => {
        showError(setFieldValue, setSubmitting);
      });
  };

  const handleSubmit = (values, properties) => {
    properties.setSubmitting(true);
    if (step === 0) {
      checkEligibility(values, properties);
      properties.setTouched({});
      return;
    }
    createPatient(values, properties);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema[step]}
    >
      {() => (
        <Form autoComplete='off'>
          {step === 0 ? <CheckEligibility /> : <Information />}
        </Form>
      )}
    </Formik>
  );
};

export default Create;
