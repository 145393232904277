import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toaster } from '@teladochealth/components';

import moment from 'moment-timezone';

import { runInAction } from 'mobx';
import { Observer, observer } from 'mobx-react-lite';

import { ErrorMessage, Field, Formik, Form } from 'formik';

import { Checkbox, PageHeader, Spinner, Translation } from 'components';
import { urlSchema } from 'utils';

import validationSchema from './validationShema';
import CreationStore from '../store/create';
import Store from '../store';

const componentDidMount = () => {
  Store.retrieveProducts().then(() => {
    CreationStore.form.refProduct = Store.products[0].id;
  });
};

const onChangeUsageNumber = ({ target: { value } }) => {
  runInAction(() => {
    CreationStore.form.useLimit = Number(value) < 1 ? 1 : Number(value);
    CreationStore.form.useType = 2;
  });
};

//Component - Ternary wrapper
const Suspense = observer((properties) =>
  Store.products[0].id === 'xxx' ? (
    <div className='py-3 container-fluid'>
      <Spinner color='primary' size={properties.size} />
    </div>
  ) : (
    properties.children
  ),
);

const onUseTypeChange = ({ target: { value } }) => {
  runInAction(() => {
    if (value === '2') {
      value = 1;
    }
    CreationStore.form.useType = Number(value);
  });
};

const CustomForm = () => {
  const history = useHistory();

  return (
    <Formik
      enableReinitialize={false}
      validationSchema={validationSchema}
      initialValues={{
        numberOfVouchers: 1,
        prefixe: '',
        description: '',
        productTag: '',
        refProduct: Store.products[0].id,
        dateExpiration: '',
        useLimit: '',
        useType: '',
        valid: false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        const {
          dateExpiration,
          description,
          numberOfVouchers,
          prefixe,
          productTag,
          refProduct,
          valid,
        } = values;

        let { useType, useLimit } = CreationStore.form;

        if (useType < 3) useType = useLimit === null || useLimit < 2 ? 1 : 2;
        useLimit = useType === 2 ? useLimit : null;

        const consolidated = {
          numberOfVouchers,
          voucherGroup: {
            description,
          },
          voucherInfo: {
            refProduct,
            dateExpiration: moment(dateExpiration).format(),
            prefixe,
            useType,
            useLimit,
            valid,
            productTag,
            dateCreation: moment().format(),
          },
        };

        Store.createVoucherGroup(consolidated)
          .then((result) => {
            if (
              typeof result.data.succeeded !== 'undefined' &&
              result.data.succeeded === false
            ) {
              toaster({
                title: (
                  <Translation id='Screens.VoucherGroups.edit.errorTitle' />
                ),
                text: <Translation id='Screens.VoucherGroups.add.errorBody' />,
                type: 'error',
              });
              return;
            }
            toaster({
              title: (
                <Translation id='Screens.VoucherGroups.edit.successTitle' />
              ),
              text: <Translation id='Screens.VoucherGroups.add.successBody' />,
              type: 'success',
            });
            history.push(`${urlSchema.vouchersGroupsDetails}/${result.data}`);
          })
          .catch(() => {
            toaster({
              title: <Translation id='Screens.VoucherGroups.edit.errorTitle' />,
              text: <Translation id='Screens.VoucherGroups.add.errorBody' />,
              type: 'error',
            });
          })
          .then(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          {/*Description */}
          <div className='form-group'>
            <label htmlFor='form-control-description'>
              <Translation id='Screens.VoucherGroups.details.description' />
            </label>

            <Field
              type='text'
              name='description'
              as='textarea'
              className='form-control'
              rows='3'
            />
          </div>

          {/* numberOfVouchers */}
          <div className='form-group'>
            <label htmlFor='form-control-numberOfVouchers' className='required'>
              <Translation id='Screens.VoucherGroups.add.voucherNumber' />
            </label>
            <Field
              type='text'
              name='numberOfVouchers'
              id='form-control-numberOfVouchers'
              className='form-control'
            />
            <ErrorMessage name='numberOfVouchers'>
              {(error) => (
                <div className='invalid-feedback d-block fw-bold'>{error}</div>
              )}
            </ErrorMessage>
          </div>

          <h5>
            <Translation id='Screens.VoucherGroups.add.informations.title' />
          </h5>

          {/* prefixe */}
          <div className='form-group'>
            <label htmlFor='form-control-prefixe' className='required'>
              <Translation id='Screens.VoucherGroups.add.prefix' />
            </label>
            <Field
              type='text'
              name='prefixe'
              id='form-control-prefixe'
              className='form-control'
            />
            <ErrorMessage name='prefixe'>
              {(error) => (
                <div className='invalid-feedback d-block fw-bold'>{error}</div>
              )}
            </ErrorMessage>
          </div>

          {/* refProduct */}
          <div className='form-group'>
            <label htmlFor='form-control-refProduct' className='required'>
              <Translation id='Screens.VoucherGroups.add.refProduct' />
            </label>
            <Field type='text' name='refProduct'>
              {({ field }) => {
                return (
                  <Observer>
                    {() => (
                      <select
                        id='form-control-refProduct'
                        type='text'
                        className='form-control'
                        {...field}
                      >
                        <option disabled>
                          <Translation id='Screens.VoucherGroups.add.selectAProduct' />
                        </option>
                        {Store.products.map((product) => (
                          <option value={product.id} key={product.id}>
                            {product.code}
                          </option>
                        ))}
                      </select>
                    )}
                  </Observer>
                );
              }}
            </Field>
            <ErrorMessage name='refProduct'>
              {(error) => (
                <div className='invalid-feedback d-block fw-bold'>{error}</div>
              )}
            </ErrorMessage>
          </div>

          {/* dateExpiration */}
          <div className='form-group'>
            <label htmlFor='form-control-dateExpiration' className='required'>
              <Translation id='Screens.VoucherGroups.add.expirationDate' />
            </label>

            <Field type='date' name='dateExpiration' className='form-control' />

            <ErrorMessage name='dateExpiration'>
              {(error) => (
                <div className='invalid-feedback d-block fw-bold'>{error}</div>
              )}
            </ErrorMessage>
          </div>

          {/* useType */}
          <div className='form-group'>
            <label
              htmlFor='form-control-useTypeWithNumber'
              className='required'
            >
              <Translation id='Screens.VoucherGroups.edit.usage' />
            </label>
            <div className='row text-center py-3'>
              <div className='col-6'>
                <Field type='radio' name='useType'>
                  {() => {
                    return (
                      <>
                        <Observer>
                          {() => (
                            <input
                              type='radio'
                              name='useType'
                              checked={
                                CreationStore.form.useType === 1 ||
                                CreationStore.form.useType === 2
                              }
                              value='2'
                              onChange={onUseTypeChange}
                              id='form-control-useTypeWithNumber'
                            />
                          )}
                        </Observer>

                        <label
                          htmlFor='form-control-useTypeWithNumber'
                          className='d-block'
                        >
                          <Observer>
                            {() => (
                              <div className='border py-3 rounded'>
                                <div>
                                  <input
                                    defaultValue={
                                      CreationStore.form.useLimit === null
                                        ? 1
                                        : CreationStore.form.useLimit
                                    }
                                    value={
                                      CreationStore.form.useLimit > 0
                                        ? CreationStore.form.useLimit
                                        : '1'
                                    }
                                    onChange={onChangeUsageNumber}
                                    type='number'
                                    min={1}
                                    className='bg-transparent border-0 display-4 fw-bold h2 m-0 p-0 text-center text-primary w-75'
                                  />
                                </div>
                                <Translation
                                  id='Screens.VoucherGroups.edit.usagePlural'
                                  values={{
                                    plural:
                                      CreationStore.form.useLimit > 1
                                        ? 's'
                                        : '',
                                  }}
                                />
                              </div>
                            )}
                          </Observer>
                        </label>
                      </>
                    );
                  }}
                </Field>
              </div>
              <div className='col-6'>
                <Observer>
                  {() => (
                    <input
                      type='radio'
                      name='useType'
                      checked={CreationStore.form.useType === 3}
                      onChange={onUseTypeChange}
                      value={'3'}
                      id='useTypeWithInfinite'
                    />
                  )}
                </Observer>

                <label htmlFor='useTypeWithInfinite' className='d-block'>
                  <div className='border py-3 rounded'>
                    <h2 className='display-4 text-primary fw-bold'>∞</h2>
                    <Translation id='Screens.VoucherGroups.edit.unlimitedUsage' />
                  </div>
                </label>
              </div>
            </div>
          </div>

          {/* productTag */}
          <div className='form-group'>
            <label htmlFor='productTag'>
              <Translation id='Screens.VoucherGroups.add.productTag' />
            </label>
            <Field type='text' name='productTag'>
              {({ field }) => {
                return (
                  <Observer>
                    {() => (
                      <input
                        id='productTag'
                        type='text'
                        className='form-control'
                        {...field}
                      />
                    )}
                  </Observer>
                );
              }}
            </Field>
          </div>

          {/*valid */}
          <div className='form-group'>
            <Field name='valid'>
              {({ field, form }) => {
                return (
                  <Observer>
                    {() => (
                      <Checkbox
                        {...field}
                        checked={form.values.valid}
                        id='form-control-valid'
                      >
                        <Translation id='Screens.VoucherGroups.edit.valid' />
                      </Checkbox>
                    )}
                  </Observer>
                );
              }}
            </Field>
          </div>

          <div className='form-group text-right'>
            <button
              className='btn btn-primary btn-lg'
              type='submit'
              disabled={isSubmitting}
            >
              <Translation id='Screens.VoucherGroups.add.create' />
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const Create = () => {
  useEffect(componentDidMount, []);

  return (
    <>
      <PageHeader>
        <h1>
          <Translation id='Screens.VoucherGroups.create.title' />
        </h1>
      </PageHeader>
      <section className='section voucherGroupsEdit'>
        <Suspense size='lg'>
          <div className='section__body'>
            <CustomForm />
          </div>
        </Suspense>
      </section>
    </>
  );
};

export default Create;
