import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Field, Formik, Form } from 'formik';
import qs from 'qs';
import { Col, Container, Input, Row, Spinner } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import { toaster } from '@teladochealth/components';

import { Button, PageHeader, RoleManager, Translation } from 'components';
import { service } from 'services';
import { api, urlSchema } from 'utils';

const List = () => {
  const intl = useIntl();
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);
  const [isLoading, setIsLoading] = useState(false);
  const [webServices, setWebServices] = useState([]);

  const getResults = (
    values = {
      searchString: null,
    },
    callback,
  ) => {
    setIsLoading(true);

    service
      .get(api.webServices.getConfiguration, {
        params: {
          ...values,
          ...qs.parse(window.location.search, { ignoreQueryPrefix: true }),
        },
      })
      .then(({ data }) => {
        setWebServices(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: <Translation id='Message.Api.Error' />,
          type: 'error',
        });
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    window.history.replaceState(
      null,
      document.title,
      `${window.location.pathname}?${qs.stringify(values)}`,
    );
    getResults(values, () => {
      setSubmitting(false);
    });
  };

  const renderWebServices = () =>
    webServices.length > 0 ? (
      <ul className='research-table__results'>
        {webServices.map((item) => (
          <li key={item.idWebServiceConfig}>
            <div className='d-flex align-items-center px-3'>
              <h4 className='m-0'>{item.name}</h4>
              <div className='research-table__actions ml-auto'>
                <RoleManager entity='WebServices' right='CRUD'>
                  <Link
                    to={`${urlSchema.webServices.configuration.update}/${item.idWebServiceConfig}`}
                    title={intl.formatMessage({
                      id: 'Buttons.Edit',
                      defaultMessage: 'Error.Buttons.Edit',
                    })}
                  >
                    <i aria-hidden className='uil uil-pen' />
                  </Link>
                </RoleManager>
                <RoleManager entity='WebServices' right='Reader'>
                  <Link
                    to={`${urlSchema.webServices.configuration.read}/${item.idWebServiceConfig}`}
                    title={intl.formatMessage({
                      id: 'Buttons.Read',
                      defaultMessage: 'Error.Buttons.Read',
                    })}
                  >
                    <i aria-hidden className='uil uil-eye' />
                  </Link>
                </RoleManager>
              </div>
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <p className='text-secondary mt-3 mb-0 small'>
        <Translation id='Shared.NoElement' />
      </p>
    );

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <Translation id='Menu.WebservicesConfig' tagName='h1' />
        <RoleManager entity='WebServices' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={urlSchema.webServices.configuration.create}
          >
            <Translation id='Buttons.Create' />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          <Formik
            initialValues={{
              searchString: '',
              ...qs.parse(window.location.search, { ignoreQueryPrefix: true }),
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className='w-100'>
                <Row>
                  <Col md='8' lg='9' xl='10'>
                    <Field type='text' name='searchString'>
                      {({ field }) => {
                        const { onChange } = field;
                        return (
                          <Input
                            {...field}
                            id='search-string'
                            onChange={(event) => {
                              onChange(event);
                              debouncedCallback(() => handleSubmit());
                            }}
                            placeholder={intl.formatMessage({
                              id: 'Screens.WebServicesConfiguration.Placeholder',
                              defaultMessage:
                                'Error.Screens.WebServicesConfiguration.Placeholder',
                            })}
                          />
                        );
                      }}
                    </Field>
                  </Col>
                  <Col className='ml-auto text-right'>
                    <Button
                      data-tracking='WebServicesConfigurationListBtnSearch'
                      color='primary'
                      isProcessing={isSubmitting}
                      label='Research'
                      type='submit'
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          <header className='research-table__header'>
            <Container fluid>
              <Row>
                <Col sm='3' md='3'>
                  <Translation
                    tagName='h5'
                    id='Model.WebServiceConfiguration.Name'
                  />
                </Col>
              </Row>
            </Container>
          </header>
          {isLoading ? (
            <div className='py-3 text-center'>
              <Spinner color='primary' size='lg' />
            </div>
          ) : (
            renderWebServices()
          )}
        </div>
      </section>
    </>
  );
};

export default List;
