import React from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { Button, Spinner } from 'reactstrap';
import { historyShape } from 'react-router-props';
import { toaster } from '@teladochealth/components';

import { ApiError, PageHeader, Translation } from 'components';
import { PartnerOperator } from 'models';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import validationSchema from 'models/PartnerOperator/validationSchema';
import Clients from './Clients';
import initialValues from './initialValues';
import { CodeOrganization } from '../../../constants';

const Create = ({ history }) => {
  const { formatMessage } = useIntl();
  const [universes, areUniversesLoading] = useFetch(api.universes.getListItems);

  const onSubmit = (values, { setSubmitting }) => {
    const formattedValues = values;
    formattedValues.infosAdmin.selectedClients = values.selectedClients;

    service
      .post(api.partnerOperators.post, formattedValues)
      .then(({ data }) => {
        if (data.succeeded === false) {
          setSubmitting(false);
          toaster({
            text: <Translation id='Toasts.PartnerOperators.Create.Fail' />,
            type: 'error',
          });
          return;
        }
        toaster({
          text: <Translation id='Toasts.PartnerOperators.Create.Success' />,
          type: 'success',
        });
        history.push(urlSchema.administrators.list);
      })
      .catch((error) => {
        toaster({
          title: <Translation id='Toasts.PartnerOperators.Create.Fail' />,
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <h1>
          <Translation id='Screens.PartnerOperators.Create.Title' />
        </h1>
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          {areUniversesLoading ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <Formik
              initialValues={{
                ...initialValues,
                refUniverse: universes.find(
                  (element) => element.code === CodeOrganization.MD,
                ).id,
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema(formatMessage)}
            >
              {(formikProperties) => (
                <Form>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <Translation id='Model.Administrator.CodeCompany' />
                        </h3>
                        <p className='model__value'>
                          {formikProperties.values.infosAdmin.codeCompany}
                        </p>
                      </div>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <Translation id='Model.User.RefUniverse' />
                        </h3>
                        <p className='model__value'>
                          {
                            universes.find(
                              (element) =>
                                element.id ===
                                formikProperties.values.refUniverse,
                            ).label
                          }
                        </p>
                      </div>
                      <PartnerOperator />
                    </div>
                    <div className='col-6'>
                      <Clients />
                    </div>
                  </div>
                  <Button color='primary' size='lg' type='submit'>
                    <Translation id='Buttons.Create' />
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </section>
    </>
  );
};

Create.propTypes = { history: historyShape.isRequired };

export default Create;
