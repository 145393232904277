import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, FieldArray } from 'formik';
import { useIntl } from 'react-intl';
import { Button, Spinner } from 'reactstrap';
import { toaster } from '@teladochealth/components';
import * as yup from 'yup';

import {
  ApiError,
  Checkbox,
  FormattedDate,
  FormSelect,
  PageHeader,
  Translation,
} from 'components';
import { service } from 'services';
import { api, useFetch } from 'utils';

const ProductTags = () => {
  const { formatMessage } = useIntl();
  const { idCustomer } = useParams();
  const [refreshing, setRefreshing] = useState(false);
  const [products, areProductsLoading] = useFetch(
    api.tags.getProducts,
    {
      clientId: idCustomer,
    },
    [refreshing],
  );
  const [tags, areTagsLoading] = useFetch(api.tags.getClients(idCustomer));

  const validationSchema = yup.object({
    clientId: yup.string().required(),
    productTagKeys: yup.array().min(1),
    statisticsLabel: yup.string().required(
      formatMessage({
        id: 'Shared.FieldRequired',
        defaultMessage: 'Error.Shared.FieldRequired',
      }),
    ),
  });

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    service
      .put(api.tags.putProducts, values)
      .then(() => {
        toaster({
          text: <Translation id='Toasts.Clients.ProductTags.Success' />,
          type: 'success',
        });
        setRefreshing(!refreshing);
        resetForm();
      })
      .catch((error) => {
        toaster({
          title: <Translation id='Toasts.Request.Fail' />,
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.Customers.ManageProductTags.Title'
          tagName='h1'
        />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <Formik
            initialValues={{
              clientId: idCustomer,
              productTagKeys: [],
              statisticsLabel: '',
            }}
            validateOnMount
            {...{ onSubmit }}
            {...{ validationSchema }}
          >
            {({ isSubmitting, isValid, setFieldValue, values }) => (
              <Form>
                <div className='row align-items-center mb-3'>
                  <div className='col-auto mr-auto'>
                    <h3 className='text-secondary m-0'>
                      <Translation
                        id='Screens.Clients.ProductTags.ClientTags'
                        values={{ number: products.length }}
                      />
                    </h3>
                  </div>
                  <div className='col-8'>
                    <div className='d-flex align-items-end gap-3'>
                      <div className='flex-fill'>
                        {areTagsLoading ? (
                          <Spinner color='primary' />
                        ) : (
                          <Field name='statisticsLabel'>
                            {({ field, meta, form }) => {
                              return (
                                <FormSelect
                                  {...{ meta }}
                                  className='m-0'
                                  name={field.name}
                                  placeholder={formatMessage({
                                    id: 'Screens.Clients.ProductTags.SelectPlaceholder',
                                    defaultMessage:
                                      'Error.Screens.Clients.ProductTags.SelectPlaceholder',
                                  })}
                                  hasError={Boolean(meta.touched && meta.error)}
                                  label={formatMessage({
                                    id: 'Screens.Clients.ProductTags.SelectLabel',
                                    defaultMessage:
                                      'Error.Screens.Clients.ProductTags.SelectLabel',
                                  })}
                                  onBlur={() =>
                                    form.setFieldTouched(field.name, true)
                                  }
                                  onChange={(opt) => {
                                    form.setFieldValue(field.name, opt.value);
                                  }}
                                  options={tags.map((element) => {
                                    return {
                                      label: element,
                                      value: element,
                                    };
                                  })}
                                  required
                                />
                              );
                            }}
                          </Field>
                        )}
                      </div>
                      <div className=''>
                        <Button
                          color='primary'
                          disabled={!isValid || isSubmitting}
                        >
                          <Translation id='Buttons.Validate' />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='product-tags-list'>
                  <div className='container-fluid'>
                    <header className='product-tags-list__header row'>
                      <div className='col-auto mr-auto'>
                        <Checkbox
                          checked={
                            values.productTagKeys.length === products.length &&
                            products.length > 0
                          }
                          id='select-all'
                          name='selectAll'
                          onChange={(event) => {
                            if (event.target.checked) {
                              setFieldValue(
                                'productTagKeys',
                                products.map((element) => ({
                                  productId: element.productId,
                                  productTag: element.productTag,
                                })),
                              );
                            } else {
                              setFieldValue('productTagKeys', []);
                            }
                          }}
                        />
                      </div>
                      <div className='col-3'>
                        <Translation
                          id='Screens.Clients.ProductTags.Product'
                          tagName='h3'
                        />
                      </div>
                      <div className='col-2'>
                        <Translation
                          id='Screens.Clients.ProductTags.CreationDate'
                          tagName='h3'
                        />
                      </div>
                      <div className='col-3'>
                        <Translation
                          id='Screens.Clients.ProductTags.ProductTag'
                          tagName='h3'
                        />
                      </div>
                      <div className='col-3'>
                        <Translation
                          id='Screens.Clients.ProductTags.LabelStats'
                          tagName='h3'
                        />
                      </div>
                    </header>
                  </div>
                  {areProductsLoading ? (
                    <div className='text-center py-3'>
                      <Spinner color='primary' size='lg' />
                    </div>
                  ) : products.length > 0 ? (
                    <FieldArray
                      name='productTagKeys'
                      render={(arrayHelpers) => (
                        <ul className='product-tags-list__items'>
                          {products.map((element, index) => {
                            const product = {
                              productId: element.productId,
                              productTag: element.productTag,
                            };
                            const idx = values.productTagKeys.findIndex(
                              (productTag) =>
                                productTag.productId === element.productId &&
                                productTag.productTag === element.productTag,
                            );

                            return (
                              <li
                                className='custom-checkbox'
                                key={`${element.productId}-${index}`}
                              >
                                <input
                                  checked={idx > -1}
                                  className='hidden-input'
                                  name={`productTagKeys.${element.productId}-${index}`}
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      arrayHelpers.push(product);
                                    } else {
                                      arrayHelpers.remove(idx);
                                    }
                                  }}
                                  value={element.productId}
                                  id={`productTagKeys-${element.productId}-${index}`}
                                  type='checkbox'
                                />
                                <label
                                  htmlFor={`productTagKeys-${element.productId}-${index}`}
                                >
                                  <div className='d-flex align-items-center w-100'>
                                    <div className='col-auto mr-auto'>
                                      <span className='custom-checkbox__box'>
                                        <i
                                          aria-hidden
                                          className='custom-checkbox__icon uil uil-check'
                                        />
                                      </span>
                                    </div>
                                    <div className='col-3'>
                                      <p>{element.productCode}</p>
                                    </div>
                                    <div className='col-2'>
                                      <p>
                                        <small>
                                          <FormattedDate
                                            value={element.createdOn}
                                          />
                                        </small>
                                      </p>
                                    </div>
                                    <div className='col-3'>
                                      <p>{element.productTag}</p>
                                    </div>
                                    <div className='col-3'>
                                      <p>{element.statisticsLabel}</p>
                                    </div>
                                  </div>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    />
                  ) : (
                    <p className='text-secondary my-2 px-3'>
                      <Translation id='Shared.NoElement' />
                    </p>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default ProductTags;
