import { useQuery } from '@tanstack/react-query';
import { getIdentityValidationsPaged } from 'screens/IdentityValidationsNhi/services/getIdentityValidationsPaged';
import { useIdentityVerificationNhiStore } from 'screens/IdentityValidationsNhi/store';
import { InsDocumentValidationStatus } from 'screens/IdentityValidationsNhi/types';
import { STALE_TIMES } from './constants';

export const identityValidationsNhiQueryKeys = {
  all: ['identityValidationsNhiPending'],
  filtered: (parameters: {
    SearchString: string;
    DocumentStatusSelected: InsDocumentValidationStatus;
  }) => [...identityValidationsNhiQueryKeys.all, { ...parameters }],
};

export const useIdentityValidationsNhiQuery = () => {
  const parameters = useIdentityVerificationNhiStore(
    (store) => store.parameters,
  );

  return useQuery({
    queryKey: ['identityValidationsNhiPending', { ...parameters }],
    queryFn: () =>
      getIdentityValidationsPaged({
        SearchString: parameters.SearchString,
        DocumentStatusSelected: parameters.DocumentStatusSelected,
      }),
    staleTime: STALE_TIMES.SHORT,
  });
};
