import React from 'react';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { toaster } from '@teladochealth/components';

import { ProductTermsResource } from 'models';
import { Button, PageHeader, Translation } from 'components';
import { service } from 'services';
import { api, urlSchema } from 'utils';
import initialValues from './initialValues';
import validationSchema from 'models/ProductTermsResource/validationSchema';

const Create = ({ history, match }) => {
  const intl = useIntl();
  const { params } = match;
  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.manager.postProductTermsResources, values)
      .then((response) => {
        if (Boolean(response?.data) && response?.data?.errorSet?.length) {
          if (
            response.data.errorSet[0].code ===
            'ProductTermsResourcesForThisLanguageAlreadyExist'
          ) {
            toaster({
              header: <Translation id='Message.Error' />,
              body: (
                <Translation id='Toasts.ProductTermsRessources.AlreadyExist' />
              ),
              type: 'error',
            });
            return;
          }
        }
        toaster({
          header: <Translation id='Message.Success' />,
          body: (
            <Translation id='Screens.ProductTermResources.Create.SuccessBody' />
          ),
          type: 'success',
        });
        history.push(`${urlSchema.productTerms.read}/${params.refProductTerm}`);
      })
      .catch(() => {
        toaster({
          header: <Translation id='Message.Error' />,
          body: <Translation id='Message.Api.Error' />,
          type: 'error',
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.ProductTermResources.Create.Title'
          tagName='h1'
        />
      </PageHeader>
      <Row>
        <Col md='8'>
          <section className='section'>
            <div className='section__body'>
              <Formik
                initialValues={{
                  ...initialValues,
                  idProductTermsResource:
                    '00000000-0000-0000-0000-000000000000',
                  refProductTerm: params.refProductTerm,
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema(intl)}
              >
                {({ isSubmitting, ...formikProperties }) => (
                  <Form>
                    <ProductTermsResource {...formikProperties} />
                    <div className='text-right'>
                      <Button
                        color='primary'
                        disabled={isSubmitting}
                        isProcessing={isSubmitting}
                        label='Create'
                        size='lg'
                        type='submit'
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

export default Create;
