import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import { Translation } from '../../../../components';

export const AccountInformation = ({ data }) => {
  return (
    <>
      <div className='mb-3'>
        <Translation id='Model.User.AccountInformation' tagName='h2' />
      </div>
      <Row>
        <Col md='12'>
          <div className='model__property'>
            <h3 className='model__title'>
              <Translation id='Shared.AccountCreationDate' />
            </h3>
            <p className='model__value'>
              {moment(data.dateCreation).format('LL')}
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

AccountInformation.displayName = 'Screens_Doctors_Read_Account_Information';
