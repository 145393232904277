import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { urlSchema } from 'utils';
import { ProtectedRoute } from 'components';
import List from './List';
import Read from './Read';
import Edit from './Edit';
import Create from './Create';

export const FileImportConfigs = () => (
  <Switch>
    <Route
      path={`${urlSchema.fileImportConfigs}/Details/:configId`}
      component={Read}
    />
    <ProtectedRoute
      entity='FileImportConfigs'
      right='CRUD'
      path={`${urlSchema.fileImportConfigs}/Edit/:configId`}
      component={Edit}
    />
    <ProtectedRoute
      entity='FileImportConfigs'
      right='CRUD'
      path={`${urlSchema.fileImportConfigs}/Create`}
      component={Create}
    />
    <Route path={'/'} component={List} />
  </Switch>
);
