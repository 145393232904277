import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { Col, Container, Input, Row } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import qs from 'qs';
import { historyShape } from 'react-router-props';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  RoleManager,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import ProfileItem from './ProfileItem';

const List = ({ history }) => {
  const intl = useIntl();
  const [organizationConfigs, areOrganizationConfigsLoading] = useFetch(
    api.organizationConfigs.getListItems,
  );
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);

  const getResults = (values = null, callback) => {
    setIsLoading(true);
    service
      .get(api.profiles.get, {
        params: {
          ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
          ...values,
        },
      })
      .then(({ data }) => {
        setProfiles(data);
      })
      .catch(() => {
        toaster({
          text: <Translation id='Toasts.Request.Fail' />,
          type: 'error',
        });
      })
      .then(() => {
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      });
  };
  const onSubmit = (values, { setSubmitting }) => {
    getResults(values, () => {
      setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <Translation tagName='h1' id='Screens.Profiles.List.Title' />
        <RoleManager entity='Profiles' right='CRUD'>
          <Link
            data-tracking='ProfilesListBtnCreate'
            className='btn btn-primary'
            to={urlSchema.profiles.create}
          >
            <Translation id='Buttons.Create' />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          <Formik
            initialValues={{
              searchString: '',
              refOrganizationConfig: '',
              refUniverse: null,
              ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className='w-100'>
                {areOrganizationConfigsLoading ? (
                  <Spinner color='primary' />
                ) : (
                  <Row>
                    <Col md='5'>
                      <Field name='searchString' type='text'>
                        {({ field }) => {
                          const { onChange } = field;
                          return (
                            <Input
                              {...field}
                              id='search-string'
                              onChange={(event) => {
                                onChange(event);
                                debouncedCallback(handleSubmit);
                              }}
                              placeholder={intl.formatMessage({
                                id: 'Screens.Profiles.List.Placeholder',
                                defaultMessage:
                                  'Error.Screens.Profiles.List.Placeholder',
                              })}
                            />
                          );
                        }}
                      </Field>
                    </Col>
                    <Col md='4'>
                      <Field name='refOrganizationConfig'>
                        {({ field, form }) => (
                          <select
                            className='form-control'
                            {...field}
                            onChange={(event) => {
                              field.onChange(event);
                              form.submitForm();
                            }}
                          >
                            <option value=''>
                              {intl.formatMessage({
                                id: 'Screens.Profiles.List.AllOrganizationsConfig',
                                defaultMessage:
                                  'Error.Screens.Profiles.List.AllOrganizationsConfig',
                              })}
                            </option>
                            {organizationConfigs.map((element) => (
                              <option key={element.id} value={element.id}>
                                {`${element.label} (${element.code})`}
                              </option>
                            ))}
                          </select>
                        )}
                      </Field>
                    </Col>
                    <Col className='ml-auto'>
                      <Button
                        color='primary'
                        data-tracking='ProfilesListBtnResearch'
                        isProcessing={isLoading || isSubmitting}
                        label='Research'
                        type='submit'
                      />
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          <header className='research-table__header'>
            <Container fluid>
              <Row>
                <Col md='3'>
                  <Translation tagName='h5' id='Screens.Profiles.List.Code' />
                </Col>
                <Col md='2'>
                  <Translation tagName='h5' id='Screens.Profiles.List.Offers' />
                </Col>
                <Col md='3'>
                  <Translation tagName='h5' id='Screens.Profiles.List.Roles' />
                </Col>
              </Row>
            </Container>
          </header>
          {isLoading ? (
            <Spinner color='primary' size='lg' />
          ) : profiles.length > 0 ? (
            <ul className='research-table__results'>
              {profiles.map((element) => (
                <li key={element.idProfile}>
                  <ProfileItem {...element} />
                </li>
              ))}
            </ul>
          ) : (
            <p className='text-secondary m-0'>
              <Translation id='Shared.NoElement' />
            </p>
          )}
        </div>
      </section>
    </>
  );
};

List.propTypes = { history: historyShape.isRequired };

export default List;
