import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { Col, Container, Input, Row } from 'reactstrap';
import qs from 'qs';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  Translation,
} from 'components';
import { ListWrapper } from 'themes/jupiter/elements';
import { service } from 'services';
import { api, urlSchema } from 'utils';

const List = ({ location }) => {
  const searchCodeInput = useRef(null);
  const { formatMessage } = useIntl();
  const [vouchers, setVouchers] = useState([]);

  const getResults = (
    values = {
      searchCode: null,
    },
    callback,
  ) => {
    const locationParameters = location.search
      ? qs.parse(location.search, { ignoreQueryPrefix: true })
      : {};
    const parsedParameters = { ...values, ...locationParameters };

    service
      .get(api.admin.getVouchers, { params: parsedParameters })
      .then(({ data }) => {
        setVouchers(data);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: <Translation id='Message.Api.Error' />,
          type: 'error',
        });
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    getResults(values, () => {
      setSubmitting(false);
    });
  };

  useEffect(() => {
    if (location.search)
      getResults(qs.parse(location.search, { ignoreQueryPrefix: true }));
  }, []);

  return (
    <>
      <PageHeader>
        <Translation id='Screens.Vouchers.List.Title' tagName='h1' />
      </PageHeader>
      <Section>
        <SectionBody>
          <ListWrapper>
            <ListWrapper.ResearchWrapper>
              <Formik initialValues={{ searchCode: '' }} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col md='8' lg='9' xl='10'>
                        <Field type='text' name='searchCode'>
                          {({ field }) => {
                            const { onChange } = field;
                            return (
                              <Input
                                {...field}
                                ref={searchCodeInput}
                                id='search-code'
                                onChange={(event) => {
                                  onChange(event);
                                }}
                                placeholder={formatMessage({
                                  id: 'Vouchers.Code',
                                  defaultMessage: 'Error.Vouchers.Code',
                                })}
                              />
                            );
                          }}
                        </Field>
                      </Col>
                      <Col className='ml-auto' md='auto'>
                        <Button
                          data-tracking='VouchersListBtnSearch'
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Research'
                          type='submit'
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </ListWrapper.ResearchWrapper>
            <ListWrapper.Header>
              <Container fluid>
                <Row>
                  <Col md='2'>
                    <ListWrapper.ColumnTitle>
                      <Translation id='Vouchers.Code' />
                    </ListWrapper.ColumnTitle>
                  </Col>
                  <Col className='d-none d-sm-block d-lg-block' md='3'>
                    <ListWrapper.ColumnTitle>
                      <Translation id='Vouchers.ProductDisplayLabel' />
                    </ListWrapper.ColumnTitle>
                  </Col>
                  <Col className='d-none d-sm-none d-lg-block' md='3'>
                    <ListWrapper.ColumnTitle>
                      <Translation id='Vouchers.DateExpiration' />
                    </ListWrapper.ColumnTitle>
                  </Col>
                  <Col className='d-none d-sm-none d-lg-block' md='auto'>
                    <ListWrapper.ColumnTitle>
                      <Translation id='Vouchers.DateCreation' />
                    </ListWrapper.ColumnTitle>
                  </Col>
                </Row>
              </Container>
            </ListWrapper.Header>
            <ul className='research-table__results'>
              {vouchers.length > 0 ? (
                vouchers.map((item) => {
                  return (
                    <ListWrapper.Item key={item.idVoucher}>
                      <Container fluid>
                        <Row>
                          <Col md='2'>
                            <h6 className='mb-0'>{item.code}</h6>
                          </Col>
                          <Col md='3'>
                            <Link
                              to={`${urlSchema.products.read}/${item.refProduct}`}
                              className='btn btn-sm btn-outline-primary'
                            >
                              {item.productDisplayLabel}
                            </Link>
                          </Col>
                          <Col md='3' className='d-none d-sm-none d-lg-block'>
                            <small className='mb-0'>
                              <Translation
                                id='Model.ExpirationDate'
                                values={{
                                  date: moment(item.dateExpiration).format(
                                    'LL',
                                  ),
                                }}
                              />
                            </small>
                          </Col>
                          <Col md='2' className='d-none d-sm-none d-lg-block'>
                            <small className='mb-0'>
                              <Translation
                                id='Model.CreationDate'
                                values={{
                                  date: moment(item.dateCreation).format('LL'),
                                }}
                              />
                            </small>
                          </Col>
                          <Col md='1' className='ml-auto'>
                            <ListWrapper.Actions>
                              <RoleManager entity='Vouchers' right='Reader'>
                                <Link
                                  aria-label={formatMessage({
                                    id: 'Buttons.Read',
                                    defaultMessage: 'Error.Buttons.Read',
                                  })}
                                  title={formatMessage({
                                    id: 'Buttons.Read',
                                    defaultMessage: 'Error.Buttons.Read',
                                  })}
                                  to={`${urlSchema.readVoucher}/${item.idVoucher}`}
                                >
                                  <i
                                    className='uil uil-eye uil--md'
                                    aria-hidden
                                  />
                                </Link>
                              </RoleManager>
                            </ListWrapper.Actions>
                          </Col>
                        </Row>
                      </Container>
                    </ListWrapper.Item>
                  );
                })
              ) : (
                <Translation id='Shared.NoElement' />
              )}
            </ul>
          </ListWrapper>
        </SectionBody>
      </Section>
    </>
  );
};

export default List;
