import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import qs from 'qs';
import { observer } from 'mobx-react-lite';
import { toaster } from '@teladochealth/components';

import { Product } from 'models';
import {
  ApiError,
  Button,
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, urlSchema } from 'utils';
import validationShema from 'models/Product/validationSchema';
import ConfigurationStore from 'stores/Configuration';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import initialValues from './initialValues';

const Create = observer(({ history, location }) => {
  const intl = useIntl();
  const [formLogoData, setFormLogoData] = useState(null);
  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.manager.postProduct, values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          toaster({
            title: <Translation id='Message.Error' />,
            text: (
              <>
                <Translation
                  id='Screens.Products.Create.ToastFail'
                  values={{ label: values.label }}
                  tagName='p'
                />
                {data.errorSet.map((error) => (
                  <p>
                    {error.code}: {error.description}
                  </p>
                ))}
              </>
            ),
            type: 'error',
          });
          return;
        }
        if (formLogoData) {
          service
            .post(api.manager.uploadProductLogo(data.idProduct), formLogoData)
            .then(() => {
              toaster({
                title: <Translation id='Message.Success' />,
                text: (
                  <Translation
                    id='Screens.Products.Create.ToastSuccess'
                    values={{ label: values.label }}
                  />
                ),
                type: 'success',
              });
              history.push(urlSchema.products.list);
            });
        } else {
          toaster({
            title: <Translation id='Message.Success' />,
            text: (
              <Translation
                id='Screens.Products.Create.ToastSuccess'
                values={{ label: values.label }}
              />
            ),
            type: 'success',
          });
          history.push(urlSchema.products.list);
        }
      })
      .catch((error) => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation id='Screens.Products.Create.Title' tagName='h1' />
      </PageHeader>
      <Section>
        <SectionBody>
          {ConfigurationStore.verificationsTypes.length === 0 ? (
            <Spinner color='primary' size='lg' />
          ) : (
            <Formik
              initialValues={initialValues(
                qs.parse(location.search, { ignoreQueryPrefix: true }),
              )}
              {...{ onSubmit }}
              validationSchema={validationShema(
                ConfigurationStore.getRefWebServiceType(),
                intl,
              )}
            >
              {({ isSubmitting, ...props }) => (
                <Form>
                  <Product setLogo={setFormLogoData} {...props} />
                  <ButtonsWrapper bottom>
                    <Button
                      color='primary'
                      isProcessing={isSubmitting}
                      label='Create'
                      size='lg'
                      type='submit'
                    />
                  </ButtonsWrapper>
                </Form>
              )}
            </Formik>
          )}
        </SectionBody>
      </Section>
    </>
  );
});

export default Create;
