import React from 'react';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  Section,
  SectionBody,
  Translation,
} from 'components';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { service } from 'services';
import { api, urlSchema } from 'utils';
import { FileImportClient } from 'models';
import initialValues from './initialValues';
import validationSchema from 'models/FileImportClient/validationShema';

const Create = () => {
  const intl = useIntl();
  const history = useHistory();
  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.admin.postFileImportClients, values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          toaster({
            title: <Translation id='Message.Error' />,
            text: (
              <>
                {data.errorSet.map((error) => (
                  <p key={error.code}>
                    {error.code}: {error.description}
                  </p>
                ))}
              </>
            ),
            type: 'error',
          });
          setSubmitting(false);
          return;
        }
        history.push(
          `${urlSchema.fileImportClients.read}/${data.idFileImportClient}`,
        );
        toaster({
          title: <Translation id='Message.Success' />,
          text: (
            <Translation id='Screens.FileImportsClients.Create.SuccessBody' />
          ),
          type: 'success',
        });
        setSubmitting(false);
      })
      .catch(({ data }) => {
        (
          <>
            {data.errorSet.map((error) => (
              <p key={error.code}>
                {error.code}: {error.description}
              </p>
            ))}
          </>
        ),
          setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.FileImportsClients.Create.Title'
          tagName='h1'
        />
      </PageHeader>
      <Row>
        <Col lg='6'>
          <Section>
            <SectionBody>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(intl)}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <FileImportClient />
                    <ButtonsWrapper bottom>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Create'
                        size='lg'
                        data-tracking='FileImportClientCreateSubmit'
                        type='submit'
                      />
                    </ButtonsWrapper>
                  </Form>
                )}
              </Formik>
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Create;
