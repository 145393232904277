import { defineMessages } from 'react-intl';

export default defineMessages({
  ButtonBack: {
    id: 'Buttons.BackToHome',
    defaultMessage: 'Error.Buttons.BackToHome',
  },
  Description: {
    id: 'Screens.AccessDenied.Description',
    defaultMessage: 'Error.Screens.AccessDenied.Description',
  },
  Subtitle: {
    id: 'Shared.AccessDenied',
    defaultMessage: 'Error.Shared.AccessDenied',
  },
});
