import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { Container, Input, Row, Col } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  RoleManager,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, urlSchema } from 'utils';
import { IsEligibleWrapper } from './elements';

const List = () => {
  const intl = useIntl();
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);
  const [isLoading, setIsLoading] = useState(true);
  const [partners, setPartners] = useState(undefined);

  const getResults = (
    values = {
      isEligible: '',
      searchString: '',
    },
    callback,
  ) => {
    setIsLoading(true);
    service
      .get(api.manager.getPartners, {
        params: values,
      })
      .then(({ data }) => {
        setPartners(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: <Translation id='Message.Api.Error' />,
          type: 'error',
        });
      });
  };

  const onSubmit = (values, properties) => {
    getResults(values, () => {
      properties.setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <PageHeader>
        <Translation id='Menu.EligiblePartners' tagName='h1' />
        <RoleManager entity='EligiblePartners' right='CRUD'>
          <Link
            data-tracking='PartnerListBtnCreate'
            className='btn btn-primary'
            to={urlSchema.createPartner}
          >
            <Translation id='Buttons.Create' />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          <Formik
            initialValues={{
              eligibleChecked: true,
              isEmpty: true,
              searchString: '',
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className='w-100'>
                <Row>
                  <Col md='6'>
                    <Field name='searchString' type='text'>
                      {({ field }) => {
                        const { onChange } = field;

                        return (
                          <Input
                            {...field}
                            id='search-string'
                            onChange={(event) => {
                              onChange(event);
                              debouncedCallback(handleSubmit);
                            }}
                            placeholder='Rechercher'
                          />
                        );
                      }}
                    </Field>
                  </Col>
                  <Col>
                    <Field name='isEligible'>
                      {({ field }) => {
                        const { onChange } = field;

                        return (
                          <Input
                            {...field}
                            id='is-eligible'
                            onChange={(event) => {
                              onChange(event);
                              handleSubmit();
                            }}
                            type='select'
                          >
                            <option value=''>
                              <Translation id='Shared.All' />
                            </option>
                            <option value='true'>
                              <Translation id='Shared.IsEligible' />
                            </option>
                            <option value='false'>
                              <Translation id='Shared.IsNotEligible' />
                            </option>
                          </Input>
                        );
                      }}
                    </Field>
                  </Col>
                  <Col md='auto'>
                    <Button
                      color='primary'
                      isProcessing={isSubmitting}
                      label='Research'
                      type='submit'
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          <div className='research-table__header'>
            <Container fluid>
              <Row>
                <Col md='3'>
                  <Translation tagName='h5' id='Partners.Name' />
                </Col>
                <Col className='d-none d-lg-block' md='3'>
                  <Translation tagName='h5' id='Partners.Client' />
                </Col>
                <Col className='d-none d-md-block d-lg-block' md='2'>
                  <Translation tagName='h5' id='Partners.EligibilityKey' />
                </Col>
                <Col className='d-none d-lg-block' md='2'>
                  <Translation tagName='h5' id='Partners.Keywords' />
                </Col>
              </Row>
            </Container>
          </div>
          {isLoading ? (
            <Spinner size='lg' />
          ) : (
            <ul className='research-table__results'>
              {partners.map((item) => {
                return (
                  <li key={item.idEligiblePartner}>
                    <Container fluid>
                      <Row className='align-items-center'>
                        <Col xs='3'>
                          <IsEligibleWrapper isEligible={item.isEligible}>
                            <i
                              className={`uil uil-${
                                item.isEligible ? 'check-circle' : 'circle'
                              }`}
                              aria-hidden
                            />
                            <div>
                              <p>{item.name}</p>
                            </div>
                          </IsEligibleWrapper>
                        </Col>
                        <Col className='d-none d-lg-block' md='3'>
                          {item.refClientNavigation ? (
                            <Link
                              to={`${urlSchema.clients.read}/${item.refClientNavigation.idClient}`}
                            >
                              <small>{item.refClientNavigation.label}</small>
                            </Link>
                          ) : (
                            <p>-</p>
                          )}
                        </Col>
                        <Col className='d-none d-md-block d-lg-block' md='2'>
                          <p className='m-0'>
                            {item.eligiblePartnersResources.length > 0
                              ? item.eligiblePartnersResources[0].eligibilityKey
                              : '-'}
                          </p>
                        </Col>
                        <Col className='d-none d-lg-block' md='2'>
                          {item.keywords && (
                            <p className='text-ellipsis m-0'>{item.keywords}</p>
                          )}
                        </Col>
                        <Col>
                          <div className='research-table__actions'>
                            <RoleManager entity='EligiblePartners' right='CRUD'>
                              <Link
                                aria-label={intl.formatMessage({
                                  id: 'Buttons.Update',
                                  defaultMessage: 'Error.Buttons.Update',
                                })}
                                title={intl.formatMessage({
                                  id: 'Buttons.Update',
                                  defaultMessage: 'Error.Buttons.Update',
                                })}
                                to={`${urlSchema.updatePartner}/${item.idEligiblePartner}`}
                              >
                                <i
                                  aria-hidden
                                  className='uil uil-pen uil--md'
                                />
                              </Link>
                            </RoleManager>
                            <RoleManager
                              entity='EligiblePartners'
                              right='Reader'
                            >
                              <Link
                                aria-label={intl.formatMessage({
                                  id: 'Buttons.Read',
                                  defaultMessage: 'Error.Buttons.Read',
                                })}
                                title={intl.formatMessage({
                                  id: 'Buttons.Read',
                                  defaultMessage: 'Error.Buttons.Read',
                                })}
                                to={`${urlSchema.readPartner}/${item.idEligiblePartner}`}
                              >
                                <i
                                  aria-hidden
                                  className='uil uil-eye uil--md'
                                />
                              </Link>
                            </RoleManager>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
