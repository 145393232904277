import React from 'react';
import { FastField, FieldArray, Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import { Input, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { toaster } from '@teladochealth/components';

import { ApiError, Button, PageHeader, Translation } from 'components';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';

const EditSpecialties = ({ history }) => {
  const { idUser } = useParams();
  const { formatMessage } = useIntl();
  const [doctor, isDoctorLoading] = useFetch(api.doctors.getDetails(idUser));
  const [specialties, areSpecialtiesLoading] = useFetch(api.specialties.get, {
    isValid: true,
  });

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.doctors.putSpecialties(values.idUser), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          toaster({
            text: <Translation id='Shared.Toast.Update.Fail' />,
            type: 'error',
          });
          return;
        }
        toaster({
          text: <Translation id='Shared.Toast.Update.Success' />,
          type: 'success',
        });
        history.push(`${urlSchema.doctors.read}/${idUser}`);
      })
      .catch((error) => {
        toaster({
          text: <ApiError {...{ error }} />,
          type: 'success',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  if (isDoctorLoading || areSpecialtiesLoading)
    return <Spinner color='primary' size='lg' />;

  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.Doctors.EditSpecialties.Title'
          tagName='h1'
          values={{
            doctor: `${doctor?.title} ${doctor?.firstname} ${doctor?.lastname}`,
          }}
        />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <Formik
            initialValues={{
              idUser,
              selectedSpecialties: doctor.usersSpecialties,
              searchString: '',
            }}
            {...{ onSubmit }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <FastField name='searchString'>
                  {({ field }) => (
                    <Input
                      className='mb-3'
                      placeholder={formatMessage({
                        id: 'Screens.Doctors.EditSpecialties.Placeholder',
                        defaultMessage:
                          'Error.Screens.Doctors.EditSpecialties.Placeholder',
                      })}
                      size='lg'
                      type='text'
                      {...field}
                    />
                  )}
                </FastField>
                <FieldArray
                  name='selectedSpecialties'
                  render={({ push, remove }) => (
                    <>
                      {specialties.map((specialty) => {
                        if (
                          specialty.label
                            .toLowerCase()
                            .includes(values.searchString.toLowerCase())
                        )
                          return (
                            <button
                              className={cn('combined-btn', {
                                'combined-btn--success':
                                  values.selectedSpecialties.findIndex(
                                    (id) => id === specialty.idSpecialty,
                                  ) >= 0,
                              })}
                              key={specialty.idSpecialty}
                              onClick={() => {
                                const index =
                                  values.selectedSpecialties.findIndex((id) => {
                                    return id === specialty.idSpecialty;
                                  });
                                if (index >= 0) {
                                  remove(index);
                                } else push(specialty.idSpecialty);
                              }}
                              type='button'
                            >
                              <span>{specialty.label}</span>
                              {values.selectedSpecialties.findIndex(
                                (element) => element === specialty.idSpecialty,
                              ) >= 0 ? (
                                <i
                                  aria-hidden
                                  className='uil uil-check uil--md'
                                />
                              ) : (
                                <i
                                  aria-hidden
                                  className='uil uil-plus uil--md'
                                />
                              )}
                            </button>
                          );

                        return null;
                      })}
                    </>
                  )}
                />
                <div className='mt-3 text-right'>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    isProcessing={isSubmitting}
                    label='Validate'
                    size='lg'
                    type='submit'
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

EditSpecialties.displayName = 'Doctors_Edit_Specialties';

export default EditSpecialties;
