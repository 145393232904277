import React, { useEffect } from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { toaster } from '@teladochealth/components';

import { Inputs } from '../inputs';
import { PageHeader, Spinner, Translation } from 'components';
import Store from '../store';
import validationSchema from '../validationShema';

const componentDidMount = (configId) => {
  window.scrollTo(0, 0);
  Store.retrieveFileImportConfigInfos(configId);
};
//Component - Ternary wrapper
const Suspense = observer((props) =>
  Store.importConfigInfo === null ? (
    <div className='py-3 container-fluid'>
      <Spinner color='primary' size={props.size} />
    </div>
  ) : (
    props.children
  ),
);

const CustomForm = () => (
  <Formik
    enableReinitialize={false}
    validationSchema={validationSchema}
    initialValues={{
      codeClient: Store.importConfigInfo.codeClient,
      dateLastProcess: Store.importConfigInfo.dateLastProcess,
      email: Store.importConfigInfo.email,
      filePath: Store.importConfigInfo.filePath,
      idFileConfig: Store.importConfigInfo.idFileConfig,
      status: Store.importConfigInfo.status,
    }}
    onSubmit={(values, { setSubmitting }) => {
      Store.updateFileImportConfig(values)
        .then(() => {
          setSubmitting(false);
          toaster({
            title: (
              <Translation id='Screens.FileImportConfigs.toast.edit.success.header' />
            ),
            text: (
              <Translation id='Screens.FileImportConfigs.toast.edit.success.body' />
            ),
            type: 'success',
          });
        })
        .catch(() => {
          setSubmitting(false);
          toaster({
            title: (
              <Translation id='Screens.FileImportConfigs.toast.edit.error.header' />
            ),
            text: (
              <Translation id='Screens.FileImportConfigs.toast.edit.error.body' />
            ),
            type: 'success',
          });
        });
    }}
  >
    {({ isSubmitting }) => (
      <Inputs
        {...{ isSubmitting }}
        submitLabel={'detail.update'}
        disabledStatus={false}
      />
    )}
  </Formik>
);

const Edit = () => {
  let { configId } = useParams();
  useEffect(() => {
    componentDidMount(configId);
  }, []);
  return (
    <Suspense>
      <PageHeader>
        <Observer>
          {() => <h1>{Store.importConfigInfo.codeClient}</h1>}
        </Observer>
      </PageHeader>

      <section className='section voucherGroupsEdit'>
        <div className='section__body'>
          <CustomForm />
        </div>
      </section>
    </Suspense>
  );
};

export default Edit;
