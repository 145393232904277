import React from 'react';
import { useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';

import { FormList } from 'components';
import { api, useFetch } from 'utils';

const Clients = () => {
  const { formatMessage } = useIntl();
  const [clients, areClientsLoading] = useFetch(api.clients.getListItems);

  if (areClientsLoading) return <Spinner color='primary' size='lg' />;

  return (
    <FormList
      data={clients}
      itemLabelKey='label'
      itemValueKey='id'
      label={formatMessage({
        id: 'Model.PartnerOperator.Clients',
        defaultMessage: 'Error.Model.PartnerOperator.Clients',
      })}
      name='selectedClients'
      researchOptions={{
        isEnabled: true,
        placeholder: formatMessage({
          id: 'Components.FormList.ClientsPlaceholder',
        }),
      }}
    />
  );
};

export default Clients;
