import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Col, Container, Input, Row } from 'reactstrap';
import { Form, Formik, Field } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { toaster } from '@teladochealth/components';
import qs from 'qs';

import {
  Button,
  PageHeader,
  RoleManager,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, useFetch, urlSchema } from 'utils';
import { TemplateMessagesType } from '../../../constants';

const List = ({ location }) => {
  const intl = useIntl();
  const [templateMessages, setTemplateMessages] = useState([]);
  const [languages, areLanguagesLoading] = useFetch(
    api.admin.getValidLanguages,
  );
  const [univers, areUniverseLoading] = useFetch(api.admin.getUnivers);
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);
  const [isLoading, setIsLoading] = useState(true);

  const getResults = (values = null, callback) => {
    setIsLoading(true);
    service
      .get(`${api.templateMessages.getList}?${qs.stringify(values)}`)
      .then(({ data }) => {
        const sortBySensitivity = (sensitivity) => (a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity });
        const sortData = sortBySensitivity('base');
        setTemplateMessages([...data].sort(sortData));
        setIsLoading(false);
      })
      .catch(() => {
        toaster({
          text: <Translation id='Toasts.Request.Fail' />,
          type: 'error',
        });
      })
      .then(() => {
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    getResults(values, () => {
      setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults(qs.parse(location.search, { ignoreQueryPrefix: true }) ?? null);
  }, []);

  return (
    <>
      <PageHeader>
        <Translation id='Screens.TemplateMessages.List.Title' tagName='h1' />
        <RoleManager entity='TemplateMessages' right='CRUD'>
          <Link
            data-tracking='TemplateMessagesListBtnCreate'
            className='btn btn-primary'
            to={urlSchema.templateMessages.create}
          >
            <Translation id='Buttons.Create' />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          <Formik
            initialValues={{
              codeLanguage: '',
              refOrganization:
                qs.parse(location.search, { ignoreQueryPrefix: true })
                  .refOrganization ?? '',
              searchString: '',
              type: '',
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className='w-100'>
                {areUniverseLoading && areLanguagesLoading ? (
                  <Spinner color='primary' />
                ) : (
                  <Row>
                    <Col md='3'>
                      <Field
                        className='form-control'
                        name='searchString'
                        type='text'
                      >
                        {({ field }) => {
                          const { onChange } = field;
                          return (
                            <Input
                              {...field}
                              id='search-string'
                              onChange={(event) => {
                                onChange(event);
                                debouncedCallback(() => handleSubmit());
                              }}
                              placeholder={intl.formatMessage({
                                id: 'Screens.TemplateMessages.List.PlaceHolder',
                                defaultMessage:
                                  'Error.Screens.TemplateMessages.List.PlaceHolder',
                              })}
                            />
                          );
                        }}
                      </Field>
                    </Col>
                    <Col md='2'>
                      <Field name='type'>
                        {({ field }) => {
                          const { onChange } = field;
                          return (
                            <Input
                              {...field}
                              id='type'
                              onChange={(event) => {
                                onChange(event);
                                debouncedCallback(() => handleSubmit());
                              }}
                              type='select'
                            >
                              <option value=''>
                                <Translation id='Screens.TemplateMessages.List.PlaceholderType' />
                              </option>
                              {Object.keys(TemplateMessagesType).map(
                                (element) => (
                                  <option key={element} value={element}>
                                    {element}
                                  </option>
                                ),
                              )}
                            </Input>
                          );
                        }}
                      </Field>
                    </Col>
                    <Col md='2'>
                      <Field name='codeLanguage'>
                        {({ field }) => {
                          const { onChange } = field;
                          return (
                            <Input
                              {...field}
                              id='code-language'
                              onChange={(e) => {
                                onChange(e);
                                handleSubmit();
                              }}
                              type='select'
                            >
                              <option value=''>
                                <Translation id='Screens.TemplateMessages.List.Languages' />
                              </option>
                              {languages.map((item) => {
                                return (
                                  <option value={item.code} key={item.id}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </Input>
                          );
                        }}
                      </Field>
                    </Col>
                    <Col md='3'>
                      <Field name='refOrganization'>
                        {({ field }) => {
                          const { onChange } = field;
                          return (
                            <Input
                              {...field}
                              id='ref-organization'
                              onChange={(event) => {
                                onChange(event);
                                handleSubmit();
                              }}
                              type='select'
                            >
                              <option value=''>
                                <Translation id='Screens.TemplateMessages.List.Organization' />
                              </option>
                              {univers.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.label} ({item.code})
                                  </option>
                                );
                              })}
                            </Input>
                          );
                        }}
                      </Field>
                    </Col>
                    <Col md='auto' className='ml-auto'>
                      <Button
                        color='primary'
                        data-tracking='TemplateMessagesListBtnResearch'
                        isProcessing={isSubmitting}
                        label='Research'
                        type='submit'
                      />
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          <header className='research-table__header'>
            <Container fluid>
              <Row>
                <Col md='4'>
                  <Translation tagName='h5' id='Shared.Lastname' />
                </Col>
                <Col md='2'>
                  <Translation tagName='h5' id='Model.TemplateMessages.Type' />
                </Col>
                <Col md='1'>
                  <Translation
                    tagName='h5'
                    id='Model.TemplateMessages.CodeLanguage'
                  />
                </Col>
                <Col md='3'>
                  <Translation
                    tagName='h5'
                    id='Model.TemplateMessages.RefOrganization'
                  />
                </Col>
              </Row>
            </Container>
          </header>
          {isLoading ? (
            <Spinner color='primary' size='lg' />
          ) : templateMessages.length > 0 ? (
            <ul className='research-table__results'>
              {templateMessages.map((item) => {
                return (
                  <li key={item.idTemplate}>
                    <Container fluid className='mt-1 mb-0'>
                      <Row className='align-items-center'>
                        <Col md='4'>
                          <p className='mb-0'>{item.name}</p>
                        </Col>
                        <Col md='2'>
                          <p className='mb-0'>{item.type}</p>
                        </Col>
                        <Col md='1'>
                          <p className='mb-0'>{item.codeLanguage}</p>
                        </Col>
                        <Col md='3'>
                          <Link
                            to={`${urlSchema.universes.read}/${item.refOrganization}`}
                          >
                            {item.organizationDisplay}
                          </Link>
                        </Col>
                        <Col
                          md='auto'
                          className='research-table__actions ml-auto'
                        >
                          <RoleManager entity='TemplateMessages' right='CRUD'>
                            <Link
                              aria-label={intl.formatMessage({
                                id: 'Buttons.Edit',
                                defaultMessage: 'Error.Buttons.Edit',
                              })}
                              to={`${urlSchema.templateMessages.update}/${item.idTemplate}`}
                            >
                              <i aria-hidden className='uil uil-pen uil--md' />
                            </Link>
                          </RoleManager>
                          <RoleManager entity='TemplateMessages' right='Reader'>
                            <Link
                              aria-label={intl.formatMessage({
                                id: 'Buttons.Show',
                                defaultMessage: 'Error.Buttons.Show',
                              })}
                              to={`${urlSchema.templateMessages.read}/${item.idTemplate}`}
                            >
                              <i aria-hidden className='uil uil-eye uil--md' />
                            </Link>
                          </RoleManager>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className='text-secondary m-0'>
              <Translation id='Shared.NoElement' />
            </p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
