import React from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Col, Row, Spinner } from 'reactstrap';
import { historyShape } from 'react-router-props';
import { toaster } from '@teladochealth/components';

import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import { Role } from 'models';
import { Button, PageHeader, Translation } from 'components';
import validationSchema from 'models/Role/validationSchema';

const Update = ({ history }) => {
  const { roleId } = useParams();
  const [role, isRoleLoading] = useFetch(api.roles.getDetails(roleId));

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.roles.put(roleId), values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          toaster({
            title: (
              <Translation
                id='Toasts.Roles.Update.Fail'
                values={{ code: role.code }}
              />
            ),
            text: data.errorSet[0].description,
            type: 'error',
          });
        } else {
          toaster({
            text: (
              <Translation
                id='Toasts.Roles.Update.Success'
                values={{ code: values.code }}
              />
            ),
            type: 'success',
          });
          history.push(urlSchema.roles.list);
        }
      })
      .catch((error) => {
        toaster({
          title: (
            <Translation
              id='Toasts.Roles.Update.Fail'
              values={{ code: role.code }}
            />
          ),
          text: (
            <>
              <span className='m-0 small'>
                Code: {error?.response?.status} ({error?.response?.statusText})
              </span>
              <br />
              <span className='m-0 small'>
                URL: {error?.response?.config?.url}
              </span>
            </>
          ),
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation
          tagName='h1'
          values={{ code: role.code || '' }}
          id='Screens.Roles.Update.Title'
        />
      </PageHeader>
      <Row>
        <Col md='6'>
          <section className='section'>
            <div className='section__body'>
              {isRoleLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Formik
                  initialValues={role}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Role />
                      <div className='text-right'>
                        <Button
                          color='primary'
                          isProcessing={isSubmitting}
                          label='Update'
                          size='lg'
                          type='submit '
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

Update.propTypes = { history: historyShape.isRequired };

export default Update;
