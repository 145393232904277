import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Col, Row, Input, FormGroup } from 'reactstrap';
import { Field, Formik, Form } from 'formik';
import qs from 'qs';
import { toaster } from '@teladochealth/components';

import { service } from 'services';
import { api, useFetch, urlSchema } from 'utils';
import {
  Button,
  FormInputFeedback,
  FormLabel,
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  Translation,
} from 'components';
import { Customer } from '../../../models';
import initialValues from './initialValues';
import validationSchema from './validationShema';
import { ButtonsWrapper } from 'themes/jupiter/elements';

const Create = () => {
  const history = useHistory();
  const parameters = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const [formLogoData, setFormLogoData] = useState();
  const { formatMessage } = useIntl();
  const [universes, areUniversesLoading] = useFetch(api.admin.getUniverses);

  const onSubmit = (values, { setSubmitting }) => {
    let newValues = {
      code: values.code,
      label: values.label,
      refUniverse: values.refUniverse,
      refClientSellsy: values.refClientSellsy,
      labelClientSellsy: values.labelClientSellsy,
    };

    service.post(api.admin.postCustomer, newValues).then(({ data }) => {
      if (data.succeeded === false) {
        setSubmitting(false);
        toaster({
          title: <Translation id='Message.Error' />,
          text: <Translation id='Screens.Customers.CreateCustomer.FailBody' />,
          type: 'error',
        });
        return;
      }
      if (values.logoFile === null) {
        service
          .post(api.admin.postCustomerDefaultLogo(data.idClient))
          .then(() => {
            toaster({
              title: <Translation id='Message.Success' />,
              text: (
                <Translation id='Screens.Customers.CreateCustomer.SuccessBody' />
              ),
              type: 'success',
            });
            history.push(`${urlSchema.clients.read}/${data.idClient}`);
          })
          .catch(() => {
            toaster({
              title: <Translation id='Message.Error' />,
              text: (
                <Translation id='Screens.Customers.CreateCustomer.FailBody' />
              ),
              type: 'error',
            });
          });
      } else {
        service
          .post(api.admin.postCustomerLogo(data.idClient), formLogoData)
          .then(() => {
            toaster({
              title: <Translation id='Message.Success' />,
              text: (
                <Translation id='Screens.Customers.CreateCustomer.SuccessBody' />
              ),
              type: 'success',
            });
            history.push(`${urlSchema.clients.read}/${data.idClient}`);
          })
          .catch(() => {
            toaster({
              title: <Translation id='Message.Error' />,
              text: (
                <Translation id='Screens.Customers.CreateCustomer.FailBody' />
              ),
              type: 'error',
            });
          });
      }
    });
  };

  return (
    <>
      <PageHeader>
        <Translation id='Screens.Customers.CreateCustomer.Title' tagName='h1' />
      </PageHeader>
      <Row>
        <Col lg='6'>
          <Section>
            <SectionBody>
              <Formik
                initialValues={{ ...initialValues, ...parameters }}
                validationSchema={validationSchema(formatMessage)}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, ...properties }) => (
                  <Form>
                    <Customer
                      isSubmitting={isSubmitting}
                      setFormLogoData={setFormLogoData}
                      onSubmit={onSubmit}
                      {...properties}
                    />
                    {areUniversesLoading ? (
                      <Spinner size='lg' />
                    ) : (
                      <FormGroup>
                        <FormLabel required for='refUniverse'>
                          <Translation id='Customers.Organization' />
                        </FormLabel>
                        <Field name='refUniverse'>
                          {({ field, meta }) => (
                            <Input
                              {...field}
                              id='ref-universe'
                              type='select'
                              invalid={meta.error && meta.touched}
                            >
                              <option value=''>-</option>
                              {universes.map((universe) => (
                                <option key={universe.id} value={universe.id}>
                                  {universe.label}
                                </option>
                              ))}
                            </Input>
                          )}
                        </Field>
                        <FormInputFeedback name='refUniverse' />
                      </FormGroup>
                    )}
                    <ButtonsWrapper bottom>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Create'
                        size='lg'
                        data-tracking='ApplicationCreateSubmit'
                        type='submit'
                      />
                    </ButtonsWrapper>
                  </Form>
                )}
              </Formik>
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};
export default Create;
