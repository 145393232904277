import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { Formik, Form } from 'formik';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  Section,
  SectionBody,
  SectionFooter,
  Translation,
} from 'components';
import { service } from 'services';
import { api, useFetch, urlSchema } from 'utils';
import CreateOfferForm from '../Create/CreateOfferForm';
import getValidationSchema from './validationSchema';
import formatDefaultData from './initialValues';

const toastUploadFailed = (error) =>
  toaster({
    title: <Translation id='Message.Error' />,
    text: (
      <>
        <p>
          <Translation id='Toasts.Offers.Error.Code' />{' '}
          <code>{error.code}</code>
        </p>
        {error.code === 'DuplicateCode' ? (
          <p>
            <Translation id='Toasts.Offers.Error.Description.DuplicateCode' />
          </p>
        ) : (
          <p>
            <Translation id='Toasts.Offers.Error.Description' />
            <code>{error.description}</code>
          </p>
        )}
      </>
    ),
    type: 'error',
  });

const formatTranslationValues = (values, languages) => {
  const formattedValue = {
    name: [],
    cta: [],
    description: [],
  };

  for (let language of languages) {
    for (let key of Object.keys(formattedValue)) {
      formattedValue[key].push({
        content: values[language.code][key],
        languageCode: language.code,
      });
    }
  }

  return formattedValue;
};

const Update = () => {
  const history = useHistory();
  const { refOffer } = useParams();
  const [formLogoData, setFormLogoData] = useState(null);
  const [defaultOfferData, setDefaultOfferData] = useState(null);
  const [validLanguages, areValidLanguagesLoading] = useFetch(
    api.identity.getValidLanguages,
  );

  useEffect(() => {
    if (validLanguages.length === 0 || !refOffer) return;

    service
      .get(api.admin.getOffer(refOffer))
      .then((response) => {
        const defaultData = formatDefaultData(validLanguages, response.data);
        setDefaultOfferData(defaultData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [validLanguages, refOffer]);

  const uploadLogoOrReturnCurrentReference = (values) => {
    return formLogoData === null
      ? values.refLogo
      : service
          .post(api.admin.postOfferLogo, formLogoData)
          .then((response) => {
            if (response.succeeded === false) {
              toastUploadFailed(response.errorSet[0]);
              return values.refLogo;
            } else {
              return response.data.fileId;
            }
          })
          .catch(() => {
            toastUploadFailed();
            return values.refLogo;
          });
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const newReferenceLogo = await uploadLogoOrReturnCurrentReference(values);
    const formattedTranslations = formatTranslationValues(
      values,
      validLanguages,
    );
    const newValues = {
      code: values.code,
      id: refOffer,
      isValid: values.isValid,
      products: values.products,
      allowFollowUpEncounter: values.allowFollowUpEncounter,
      profiles: values.profiles,
      refSpecialty: values.refSpecialty,
      refLogo: newReferenceLogo,
      ...formattedTranslations,
    };

    try {
      const response = await service.put(api.admin.putOffer, newValues);
      if (response.data.succeeded === false) throw response.data;
      toaster({
        title: <Translation id='Message.Success' />,
        text: <Translation id='Offers.Create.SuccessBody' />,
        type: 'success',
      });
      history.push(`${urlSchema.readOffer}/${refOffer}`);
    } catch (error) {
      console.log(error);
      toastUploadFailed(error.errorSet[0]);
    } finally {
      setSubmitting(false);
    }
  };

  if (areValidLanguagesLoading || !defaultOfferData || !refOffer)
    return <Spinner />;

  return (
    <>
      <PageHeader>
        <Translation id='Offers.Update.Title' tagName='h1' />
      </PageHeader>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          ...defaultOfferData,
          // "options" can be null if none of properties are true
          options: {
            ...defaultOfferData.options,
            isMedicalControlService:
              defaultOfferData.options?.isMedicalControlService ?? false,
          },
        }}
        validationSchema={getValidationSchema(validLanguages)}
      >
        {({ isSubmitting }) => (
          <Section>
            <Form>
              <SectionBody>
                <CreateOfferForm
                  validLanguages={validLanguages}
                  setFormLogoData={setFormLogoData}
                  update
                />
              </SectionBody>
              <SectionFooter>
                <div className='d-flex justify-content-end'>
                  <Button
                    label='Update'
                    type='submit'
                    color='primary'
                    isProcessing={isSubmitting}
                  />
                </div>
              </SectionFooter>
            </Form>
          </Section>
        )}
      </Formik>
    </>
  );
};

export default Update;
