import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { Spinner } from 'reactstrap';
import { historyShape, matchShape } from 'react-router-props';
import { toaster } from '@teladochealth/components';

import { PartnerOperator } from 'models';
import { ApiError, Button, PageHeader, Translation } from 'components';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import validationSchema from 'models/PartnerOperator/validationSchema';
import Clients from './Clients';

const Update = ({ history, match }) => {
  const { formatMessage } = useIntl();
  const [partnerOperator, isPartnerOperatorLoading] = useFetch(
    `${api.partnerOperators.get}/${match.params.partnerOperatorId}`,
  );
  const [universe, setUniverse] = useState(undefined);

  useEffect(() => {
    if (!isPartnerOperatorLoading)
      service
        .get(api.universes.getDetails(partnerOperator.refUniverse))
        .then(({ data }) => {
          setUniverse(data);
        });
  }, [isPartnerOperatorLoading]);

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.partnerOperators.put(values.idUser), values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          setSubmitting(false);
          toaster({
            text: (
              <Translation
                id='Toasts.PartnerOperators.Update.Fail'
                values={{ name: `${values.firstname} ${values.lastname}` }}
              />
            ),
            type: 'error',
          });
          return;
        }
        toaster({
          text: (
            <Translation
              id='Toasts.PartnerOperators.Update.Success'
              values={{ name: `${values.firstname} ${values.lastname}` }}
            />
          ),
          type: 'success',
        });
        history.push(urlSchema.administrators.list);
      })
      .catch((error) => {
        toaster({
          title: (
            <Translation
              id='Toasts.PartnerOperators.Update.Fail'
              values={{ name: `${values.firstname} ${values.lastname}` }}
            />
          ),
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <h1>
          <Translation
            id='Screens.PartnerOperators.Update.Title'
            values={{
              name: partnerOperator
                ? `${partnerOperator.firstname} ${partnerOperator.lastname}`
                : '',
            }}
          />
        </h1>
      </PageHeader>
      <div className='row'>
        <div className='col-7'>
          <section className='section'>
            <div className='section__body'>
              {isPartnerOperatorLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Formik
                  initialValues={partnerOperator}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema(formatMessage)}
                >
                  {(formikProperties) => (
                    <Form>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <Translation id='Model.Administrator.CodeCompany' />
                        </h3>
                        <p className='model__value'>
                          {formikProperties.values.infosAdmin.codeCompany}
                        </p>
                      </div>
                      <div className='model__property'>
                        <h3 className='model__title'>
                          <Translation id='Model.User.RefUniverse' />
                        </h3>
                        <p className='model__value'>
                          {typeof universe !== 'undefined' && universe.label}
                        </p>
                      </div>
                      <PartnerOperator />
                      <Button
                        color='primary'
                        isProcessing={formikProperties.isSubmitting}
                        label='Save'
                        size='lg'
                        type='submit'
                      />
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </section>
        </div>
        <div className='col-5'>
          <section className='section'>
            <header className='section__header'>
              <h2>
                <Translation id='Model.PartnerOperator.Clients' />
              </h2>
            </header>
            <div className='section__body'>
              {isPartnerOperatorLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Clients
                  data={partnerOperator.infosAdmin.clients.map(
                    (element) => element.refClient,
                  )}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

Update.propTypes = {
  history: historyShape.isRequired,
  match: matchShape.isRequired,
};

export default Update;
