/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState, useEffect } from 'react';

import { urlSchema, api } from 'utils';
import { service } from 'services';
import { Form, Formik, Field } from 'formik';
import {
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toaster } from '@teladochealth/components';
import { useIntl } from 'react-intl';

import {
  Button,
  Checkbox,
  FormInput,
  FormInputFeedback,
  Translation,
} from 'components';
import { MESSAGE_MAX_LENGTH } from './constants';
import { getValidationSchema } from './validation-schema';

const FORM_INITIAL_VALUES = {
  id: undefined,
  message: undefined,
  startDate: undefined,
  endDate: undefined,
  valid: false,
  bannersUserTypes: [],
};

function getDayAndHours(isoString) {
  if (!isoString) {
    return { day: undefined, hour: undefined };
  }

  const dateObject = new Date(isoString);
  let year = dateObject.getFullYear();
  let month = String(dateObject.getMonth() + 1).padStart(2, 0);
  let day = String(dateObject.getDate()).padStart(2, 0);

  let hours = String(dateObject.getHours()).padStart(2, 0);
  let minutes = String(dateObject.getMinutes()).padStart(2, 0);

  return {
    day: `${year}-${month}-${day}`,
    hour: `${hours}:${minutes}`,
  };
}

const CreateEditModal = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const [bannerData, setBannerData] = useState({
    data: undefined,
    isLoading: true,
  });

  const closeCancelModal = () =>
    history.push(urlSchema.banners.list, { reload: true });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const {
        bannersUserTypes,
        endDate,
        endHour,
        startDate,
        startHour,
        id,
        message,
        valid,
      } = values;
      const payload = {
        id,
        message,
        valid,
        bannersUserTypes,
        startDate: `${startDate}T${startHour}`,
        endDate: endDate ? `${endDate}T${endHour}` : undefined,
      };

      const { data } = await service({
        method: id ? 'put' : 'post',
        url: id ? api.banners.put : api.banners.post,
        data: payload,
      });

      if (data.succeeded === false) {
        throw new Error(data.errorSet[0].code);
      }

      closeCancelModal();
      toaster({
        title: <Translation id='Shared.Success' />,
        text: id ? (
          <Translation id='Screens.Banners.Toasts.UpdateSuccessBody' />
        ) : (
          <Translation id='Screens.Banners.Toasts.CreateSuccessBody' />
        ),
        type: 'success',
      });

      resetForm();
    } catch (error) {
      console.log(
        'An unexpected error happened on CreateEditBannerModal',
        error,
      );
    } finally {
      setSubmitting(false);
    }
  };

  const fetchBanner = async (bannerId) => {
    try {
      setBannerData((previousState) => ({ ...previousState, isLoading: true }));
      const { data } = await service.get(api.banners.get(bannerId));
      if (data.succeeded === false) {
        throw new Error(data.errorSet[0].code);
      }
      setBannerData({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log(
        `An unexpected error happened with banner ${bannerId}`,
        error,
      );
      closeCancelModal();
    }
  };

  useEffect(() => {
    if (id) {
      fetchBanner(id);
    } else {
      setBannerData({
        data: undefined,
        isLoading: false,
      });
    }
  }, [id]);

  const formattedInitialValues = {
    ...FORM_INITIAL_VALUES,
    ...bannerData.data,
    startDate: getDayAndHours(bannerData.data?.startDate).day,
    endDate: getDayAndHours(bannerData.data?.endDate).day,
    startHour: getDayAndHours(bannerData.data?.startDate).hour || '00:00',
    endHour: getDayAndHours(bannerData.data?.endDate).hour || '23:59',
  };

  const validationSchema = getValidationSchema(formatMessage);

  return (
    <Modal isOpen={true} toggle={closeCancelModal} size='md'>
      <ModalHeader toggle={closeCancelModal}>
        {id ? (
          <Translation id='Screens.Banners.Modal.UpdateTitle' values={{ id }} />
        ) : (
          <Translation id='Screens.Banners.Modal.CreateTitle' />
        )}
      </ModalHeader>
      {bannerData.isLoading ? (
        <ModalBody>
          <div className='d-flex align-items-center justify-content-center'>
            <Spinner color='primary' size='lg' />
          </div>
        </ModalBody>
      ) : (
        <>
          <Formik
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={formattedInitialValues}
          >
            {({ values, errors, isSubmitting }) => (
              <Form>
                <ModalBody>
                  <div className='d-flex flex-column'>
                    <div className='mb-3'>
                      <p className='mb-0 font-weight-bold text-muted'>
                        <Translation id='Screens.Banners.Modal.Labels.App' />
                      </p>
                      <div className='d-flex flex-row gap-4'>
                        <Field name='bannersUserTypes'>
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              value={'PATIENT'}
                              checked={values.bannersUserTypes.includes(
                                'PATIENT',
                              )}
                              id='patient'
                            >
                              <Translation id='Screens.Banners.Item.ClientLabel.PATIENT' />
                            </Checkbox>
                          )}
                        </Field>
                        <Field name='bannersUserTypes'>
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              value={'PRACTITIONER'}
                              checked={values.bannersUserTypes.includes(
                                'PRACTITIONER',
                              )}
                              id='practitioner'
                            >
                              <Translation id='Screens.Banners.Item.ClientLabel.PRACTITIONER' />
                            </Checkbox>
                          )}
                        </Field>
                      </div>
                      <FormInputFeedback name='bannersUserTypes' />
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <FormInput
                          id='startDate'
                          Component={Field}
                          hasRawError
                          label={formatMessage({
                            id: 'Screens.Banners.Modal.Labels.PublishDate',
                            defaultMessage:
                              'Screens.Banners.Modal.Labels.PublishDate',
                          })}
                          name='startDate'
                          type='date'
                          required
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormInput
                          id='startHour'
                          Component={Field}
                          hasRawError
                          label={formatMessage({
                            id: 'Screens.Banners.Modal.Labels.PublishTime',
                            defaultMessage:
                              'Screens.Banners.Modal.Labels.PublishTime',
                          })}
                          name='startHour'
                          type='time'
                          disabled={!values.startDate}
                          required
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <FormInput
                          id='endDate'
                          Component={Field}
                          hasRawError
                          label={formatMessage({
                            id: 'Screens.Banners.Modal.Labels.ExpiryDate',
                            defaultMessage:
                              'Screens.Banners.Modal.Labels.ExpiryDate',
                          })}
                          name='endDate'
                          type='date'
                        />
                      </div>

                      <div className='col-md-6'>
                        <FormInput
                          id='endHour'
                          Component={Field}
                          hasRawError
                          label={formatMessage({
                            id: 'Screens.Banners.Modal.Labels.ExpiryTime',
                            defaultMessage:
                              'Screens.Banners.Modal.Labels.ExpiryTime',
                          })}
                          name='endHour'
                          type='time'
                          disabled={!values.endDate}
                        />
                      </div>
                    </div>
                  </div>
                  <FormInput
                    id='message'
                    label={'message'}
                    name='message'
                    required
                    type='textarea'
                    maxlength={MESSAGE_MAX_LENGTH}
                    hasRawError
                  />
                </ModalBody>
                <ModalFooter>
                  <div className='w-100 d-flex flex-row align-items-center justify-content-between '>
                    <Field name='valid'>
                      {({ field }) => (
                        <Checkbox {...field} checked={values.valid} id='status'>
                          <Translation
                            id='Screens.Banners.Modal.Labels.Status'
                            values={{
                              status: (
                                <Translation
                                  id={
                                    values.valid
                                      ? 'Shared.Activated'
                                      : 'Shared.Deactivated'
                                  }
                                />
                              ),
                            }}
                          />
                        </Checkbox>
                      )}
                    </Field>
                    <Button
                      color='primary'
                      isProcessing={isSubmitting}
                      label='Save'
                      type='submit'
                      disabled={Object.keys(errors).length > 0}
                    />
                  </div>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Modal>
  );
};

export { CreateEditModal };
