import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { Spinner } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import { ApiError, Button, FormList, Translation } from 'components';
import { service } from 'services';
import { api, useFetch } from 'utils';

const Profiles = ({ data }) => {
  const adminId = useParams().adminRef;
  const { formatMessage } = useIntl();
  const [profiles, areProfilesLoading] = useFetch(api.profiles.getListItems, {
    userType: 'ADMIN',
  });

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.administrators.putProfiles(values.idUser), values)
      .then((result) => {
        if (
          typeof result.data !== 'undefined' &&
          result.data.succeeded === false
        ) {
          toaster({
            title: <Translation id='Toasts.Profiles.Fail' />,
            type: 'error',
          });
          return;
        }
        toaster({
          title: <Translation id='Toasts.Profiles.Success' />,
          type: 'success',
        });
      })
      .catch((error) => {
        toaster({
          title: <Translation id='Toasts.Profiles.Fail' />,
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  if (areProfilesLoading) return <Spinner color='primary' size='lg' />;

  return (
    <Formik
      initialValues={{
        idUser: adminId,
        selectedProfiles: data,
      }}
      onSubmit={onSubmit}
    >
      {(formikProperties) => (
        <Form>
          <FormList
            data={profiles}
            itemLabelKey='label'
            itemValueKey='id'
            name='selectedProfiles'
            researchOptions={{
              isEnabled: true,
              placeholder: formatMessage({
                id: 'Components.FormList.ProfilesPlaceholder',
                defaultMessage: 'Error.Components.FormList.ProfilesPlaceholder',
              }),
            }}
          />
          <Button
            color='primary'
            isProcessing={formikProperties.isSubmitting}
            label='Save'
            type='submit'
          />
        </Form>
      )}
    </Formik>
  );
};

export default Profiles;
