import React from 'react';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import { ProductTermsResource } from 'models';
import { Button, PageHeader, Spinner, Translation } from 'components';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import validationSchema from './validationSchema';

const Edit = ({ history, match }) => {
  const { params } = match;
  const [productTermsResource, isProductTermsResourceLoading] = useFetch(
    api.manager.getProductTermsResources(params.idProductTermResource),
  );

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.manager.putProductTermsResources, values)
      .then((response) => {
        if (Boolean(response.data) && response.data.errorSet.length) {
          if (
            response.data.errorSet[0].code ===
            'ProductTermsResourcesForThisLanguageAlreadyExist'
          ) {
            toaster({
              title: <Translation id='Message.Error' />,
              text: (
                <Translation id='Toasts.ProductTermsRessources.AlreadyExist' />
              ),
              type: 'error',
            });
            return;
          }
        }
        toaster({
          title: <Translation id='Message.Success' />,
          text: (
            <Translation id='Screens.ProductTermResources.Edit.SuccessBody' />
          ),
          type: 'success',
        });
        history.push(
          `${urlSchema.productTermsResources.read}/${params.idProductTermResource}`,
        );
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: <Translation id='Message.Api.Error' />,
          type: 'error',
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.ProductTermResources.Edit.Title'
          tagName='h1'
        />
      </PageHeader>
      {isProductTermsResourceLoading ? (
        <Spinner color='primary' size='lg' />
      ) : (
        <Row>
          <Col md='8'>
            <section className='section'>
              <div className='section__body'>
                <Formik
                  initialValues={{
                    ...productTermsResource,
                    idProductTermsResource:
                      productTermsResource.idProductTermsResource,
                    refProductTerm: productTermsResource.refProductTerm,
                    codeLanguage: productTermsResource.codeLanguage,
                    terms: productTermsResource.terms,
                  }}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ isSubmitting, ...formikProperties }) => (
                    <Form>
                      <ProductTermsResource {...formikProperties} />
                      <div className='text-right'>
                        <Button
                          color='primary'
                          disabled={isSubmitting}
                          isProcessing={isSubmitting}
                          label='Update'
                          size='lg'
                          type='submit'
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </section>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Edit;
