import moment from 'moment-timezone';
import { ModalHeader, Modal } from 'reactstrap';
import { Spinner, PDFModal, ImageModal, Translation } from '../../components';
import { useIdentityVerificationNhiStore } from './store';
import { ModalForm } from './ModalForm';
import { DocumentValidationBadge } from './DocumentValidationBadge';
import { useIdentityValidationsNhiQuery } from 'queries/useIdentityValidationsNhiQuery';
import { useIdentityValidationNhiDocument } from 'queries/useIdentityValidationNhiDocument';

const MediaViewer = ({ idDocument }: { idDocument: string }) => {
  const {
    data: document,
    isError,
    isPending,
  } = useIdentityValidationNhiDocument(idDocument);

  if (isPending) {
    return (
      <div className='min-height-50vh align-items-center d-flex justify-content-center'>
        <Spinner color='primary' size='lg' />
      </div>
    );
  }

  if (isError || !document) {
    return <Translation id='Shared.Error' />;
  }

  return document.contentType.includes('image') ? (
    <ImageModal
      key={idDocument}
      options={{
        fileName: document.fileName,
        contentType: document.contentType,
        source: `data:${document.contentType};base64, ${document.documentRawFile}`,
      }}
    />
  ) : (
    <PDFModal
      key={idDocument}
      options={{
        fileName: document.fileName,
        source: `data:${document.contentType};base64, ${document.documentRawFile}`,
      }}
    />
  );
};

export const ValidationModal = () => {
  const selectedPatientId = useIdentityVerificationNhiStore(
    (store) => store.selectedPatientId,
  );
  const setSelectedPatientId = useIdentityVerificationNhiStore(
    (store) => store.setSelectedPatientId,
  );

  const {
    data: users,
    isLoading,
    isPending,
    isError,
  } = useIdentityValidationsNhiQuery();

  const closeModal = () => setSelectedPatientId();

  if (isPending || isLoading) {
    return (
      <Modal isOpen={true} toggle={closeModal} size='xl'>
        <Spinner size='xl' color='primary' />
      </Modal>
    );
  }

  if (isError) {
    throw new Error('Error while loading user query');
  }

  const currentUser = users.find(
    (user) => user.patientId === selectedPatientId,
  );

  if (!currentUser) return;

  return (
    <Modal isOpen={Boolean(selectedPatientId)} toggle={closeModal} size='xl'>
      <ModalHeader toggle={closeModal}>
        <Translation id='Screens.IdentityValidationsIns.Title' />
      </ModalHeader>
      <div className='p-0 col d-flex flex-row'>
        <div className='bg-color-f1f1f6 col-md-7'>
          <MediaViewer idDocument={currentUser.idDocument} />
        </div>
        <div className='col-md-5'>
          <div className='d-flex flex-column align-items-start mt-3 gap-3 p-2'>
            <DocumentValidationBadge
              documentValidationStatus={currentUser.documentValidationStatus}
            />
            <div className='w-100'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <Translation id='Screens.IdentityValidationsIns.ModalForm.BirthLastname' />
                    </h3>
                    <p className='mb-0'>{currentUser?.nhiLastName ?? '/'}</p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <Translation id='Screens.IdentityValidationsIns.ModalForm.BirthFirstname' />
                    </h3>
                    <p className='mb-0'>{currentUser?.nhiFirstName ?? '/'}</p>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <Translation id='Screens.IdentityValidationsIns.ModalForm.BirthDate' />
                    </h3>
                    <p className='mb-0'>
                      {currentUser?.nhiBirthDate ? (
                        <>
                          {moment(currentUser.nhiBirthDate).format(
                            'DD/MM/YYYY',
                          )}{' '}
                          (
                          <Translation
                            id='Shared.Age'
                            values={{
                              age: moment().diff(
                                moment(currentUser.nhiBirthDate),
                                'years',
                              ),
                            }}
                          />
                          )
                        </>
                      ) : (
                        '/'
                      )}
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <Translation id='Screens.IdentityValidationsIns.ModalForm.BirthPlace' />
                    </h3>
                    {currentUser?.birthPlace ? (
                      <p className='mb-0'>{currentUser.birthPlace}</p>
                    ) : (
                      '/'
                    )}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='model__property'>
                    <h3 className='font-size-14px mb-0 text-secondary'>
                      <Translation id='Shared.Gender' />
                    </h3>
                    {currentUser?.nhiGender ? (
                      <p className='mb-0'>
                        <Translation
                          id={`Screens.IdentityValidationsIns.ModalForm.Profile.${currentUser?.nhiGender}`}
                        />
                      </p>
                    ) : (
                      '/'
                    )}
                  </div>
                </div>
                <div className='col-md-6'>
                  {currentUser?.nhiBirthDate ? (
                    <div className='model__property'>
                      <h3 className='font-size-14px mb-0 text-secondary'>
                        <Translation id='Screens.IdentityValidationsIns.ModalForm.Profile' />
                      </h3>
                      <p className='mb-0'>
                        {moment().diff(
                          moment(currentUser.nhiBirthDate),
                          'years',
                        ) > 18 ? (
                          <Translation id='Screens.IdentityValidationsIns.ModalForm.Profile.Main' />
                        ) : (
                          <Translation id='Screens.IdentityValidationsIns.ModalForm.Profile.Beneficiary' />
                        )}
                      </p>
                    </div>
                  ) : (
                    '/'
                  )}
                </div>
              </div>
            </div>
          </div>
          <ModalForm user={currentUser} />
        </div>
      </div>
    </Modal>
  );
};
