import React, { useEffect, useRef } from 'react';
import { observable, runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { ModalBody, ModalFooter, ModalHeader, Modal } from 'reactstrap';
import 'react-day-picker/lib/style.css';
import Select from 'react-select';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { toaster } from '@teladochealth/components';

import {
  Checkbox,
  PageHeader,
  Section,
  SectionBody,
  Translation,
} from 'components';
import { service } from 'services';
import { api } from 'utils';

//the list of fees
const fetchedList = observable.box([]);

//cancel modal state
const cancelModalVisibility = observable.box(false);

//cancel modal state
const downloadingOfFeeNotes = observable.box(false);

//the selected filtered status
const filteredStatus = observable.box([]);

//get the previous month
const previousMonth = moment(moment()).subtract(1, 'months');

//the day picker default range
const dayPickerDefaultRange = {
  from: previousMonth.startOf('month').toDate(),
  to: previousMonth.endOf('month').toDate(),
};

//the day picker range
const dayPickerRange = observable.box(dayPickerDefaultRange);

//the status enums
const statusEnums = {
  GENERATING: {
    ico: 'badge-light',
    i18n: (
      <Translation tagName='span' id='Screens.FeeNotes.Status.Generating' />
    ),
  },
  GENERATED: {
    ico: 'badge-light',
    i18n: <Translation tagName='span' id='Screens.FeeNotes.Status.Generated' />,
  },
  VALIDATED: {
    ico: 'badge-warning',
    i18n: <Translation tagName='span' id='Screens.FeeNotes.Status.Validated' />,
  },
  EXPORTED: {
    ico: 'badge-success',
    i18n: <Translation tagName='span' id='Screens.FeeNotes.Status.Exported' />,
  },
  CANCELLED: {
    ico: 'badge-danger',
    i18n: <Translation tagName='span' id='Screens.FeeNotes.Status.Cancelled' />,
  },
};

//the static observable status enums
const statusEnumsObs = observable.box([]);

//the contextuals observable status enums
const statusEnumsContextualObs = observable.box([]);

//get the doctor note
const getDoctorNote = (searchString = '') =>
  service.get(`${api.feeNotes.doctorsNote}${searchString}`);

//get the mocked doctor note
//const getDoctorNote = (searchString = '') => service.get(`http://demo0306025.mockable.io/mock/FeeNotes${searchString}`);

//update the feeNotes list by date
const updateFetchedListByDate = (searchString = '') =>
  getDoctorNote(searchString).then((response) => {
    const feeNotesData = feeNotesDataDefault(response.data);
    runInAction(() => {
      fetchedList.set(feeNotesData);
      setContextualFilters();
    });
  });

//default feeNotes
const feeNotesDataDefault = (feeNotesData) =>
  feeNotesData.map((d) => ({
    ...d,
    checked: false,
  }));

//get the data
const componentDidMount = () => {
  const fromRelatedMonth = moment(previousMonth).format('YYYY-MM-DD');
  const toRelatedMonth = moment().format('YYYY-MM-DD');
  const searchString = getSearchString(fromRelatedMonth, toRelatedMonth);
  Promise.all([
    service.get(api.feeNotes.status),
    getDoctorNote(searchString),
  ]).then((data) => {
    const status = data[0];
    const feeNotes = data[1];
    const feeNotesData = feeNotesDataDefault(feeNotes.data);
    runInAction(() => {
      statusEnumsObs.set(status.data);
      fetchedList.set(feeNotesData);
      setContextualFilters();
    });
  });
};

//reset all the observables
const componentWillUnMount = () => {
  runInAction(() => {
    filteredStatus.set([]);
    fetchedList.set([]);
    dayPickerRange.set(dayPickerDefaultRange);
  });
};
//query string for the api
const getSearchString = (from, to) =>
  `?FromRelatedMonth=${from}&ToRelatedMonth=${to}`;

//when a checkbox is checked
const onCheckBoxChange = (checkbox, fee) => {
  runInAction(() => {
    fee.checked = checkbox.target.checked;
  });
};

//when the selectAll checkbox is checked
const selectOrUnselectAllFees = (input) => {
  const filteredFetchedList = getFilteredFetchedList();
  const fetchedListCopy = [...fetchedList.get()];
  filteredFetchedList.map((g, i) => {
    const index = fetchedListCopy.findIndex((k) => k.id === g.id);
    return runInAction(() => {
      fetchedListCopy[index].checked = input.target.checked;
    });
  });
  runInAction(() => {
    fetchedList.set(fetchedListCopy);
  });
};

//when filters change
const onSelectFilterChange = (select) => {
  const filters = select === null ? [] : select;
  runInAction(() => {
    filteredStatus.set(filters);
  });
};

//the current downloadable list of fees notes
const downloadList = () =>
  getCheckedFeeList().length > 0
    ? getCheckedFeeList()
        .filter((element) => element.noteDocumentId !== null)
        .map((element) => element.noteDocumentId)
    : [];

//when we click on the download btn
const onDownload = () => {
  downloadingOfFeeNotes.set(true);
  service
    .post(api.feeNotes.download, downloadList(), { responseType: 'blob' })
    .then((result) => {
      const fileName = result.headers['content-disposition']
        .split('attachment; filename=')[1]
        .split('; filename')[0];
      saveAs(result.data, fileName);
      setTimeout(() => downloadingOfFeeNotes.set(false), 1000);
    });
};

//Cancelling some fee notes
const putFeeNotesCancel = () => {
  const doctorsNotesIds = getCheckedFeeList().map((element) => element.id);
  service.put(api.feeNotes.cancel, doctorsNotesIds).then((result) => {
    const { succeeded, errorSet } = result.data;
    if (succeeded) {
      const fromRelatedMonth = moment(dayPickerRange.get().from).format(
        'YYYY-MM-DD',
      );
      const toRelatedMonth = moment(dayPickerRange.get().to).format(
        'YYYY-MM-DD',
      );
      const searchString = getSearchString(fromRelatedMonth, toRelatedMonth);
      updateFetchedListByDate(searchString);
      toaster({
        title: <Translation id='Screens.FeeNotes.SuccessfulOperation' />,
        text: <Translation id='Screens.FeeNotes.CancellationMade' />,
        type: 'success',
      });
    } else {
      toaster({
        title: <>{errorSet[0].code}</>,
        text: <>{errorSet[0].description}</>,
        type: 'error',
      });
    }
  });
};

//when we click on the cancel btn inside the modal
const onEffectiveCancel = () => {
  putFeeNotesCancel();
  closeCancelModal();
};

//retrieve all the checked fees
const getCheckedFeeList = () => {
  const fetchedDownloadList = [...getFilteredFetchedList()]
    .filter((element) => element.checked === true)
    .map((element) => {
      const c = { ...element };
      delete c.checked;
      return c;
    });
  return fetchedDownloadList;
};

//get the total sum of the selected fees
const getTotalSum = () => {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const filtered = getFilteredFetchedList()
    .filter((element) => element.checked === true)
    .map((element) => element.grandTotalAmount);
  const sum = filtered.reduce(reducer, 0);
  return sum.toFixed(2);
};

//get the current filtered Fees list
const getFilteredFetchedList = () => {
  const filteredList = [];
  const potentialList =
    filteredStatus.get() !== null ? filteredStatus.get() : [];
  potentialList.map((status, i) => {
    const t = fetchedList.get().filter((element) => {
      return element.status === status.value;
    });
    filteredList.push(...t);
  });
  const filteredFetchedList =
    filteredList.length > 0 ? filteredList : fetchedList.get();
  return _.sortBy(filteredFetchedList, ['doctorLastname']);
};

//when we click on the cancel btn
const onCancelFees = () => {
  runInAction(() => {
    cancelModalVisibility.set(true);
  });
};

//close the cancel modal
const closeCancelModal = () => {
  runInAction(() => {
    cancelModalVisibility.set(false);
  });
};

//update the filters based on the current dataSet
const setContextualFilters = () => {
  const contextualFiltersId = _.uniqBy(toJS(fetchedList.get()), 'status').map(
    (element) => element.status,
  );
  const contextualFilters = toJS(statusEnumsObs.get()).filter((element) =>
    _.includes(contextualFiltersId, element.id),
  );
  runInAction(() => {
    statusEnumsContextualObs.set(contextualFilters);
  });
};

//Observer component for the download & the cancel btn
const DownloadActions = observer(() => {
  const isChecked = (fee) => fee.checked === true;
  const downloadButtonIsDisabled = !(
    downloadList().length > 0 && downloadingOfFeeNotes.get() === false
  );
  const cancelButtonIsDisabled = !fetchedList.get().some(isChecked);
  const buttonClassName = 'btn w-100 min-width-inherit';

  return (
    <>
      <div className='col-auto mr-2'>
        <span className=' invisible'>&nbsp;</span>
        <button
          onClick={onDownload}
          className={`${buttonClassName} ${
            downloadButtonIsDisabled
              ? 'btn-outline-secondary'
              : 'btn-outline-primary'
          }`}
          disabled={downloadButtonIsDisabled}
        >
          <span className='text-ellipsis d-block'>
            <Translation id='Screens.FeeNotes.Download' />
          </span>
        </button>
      </div>

      <div className='col-auto'>
        <span className=' invisible'>&nbsp;</span>
        <button
          onClick={onCancelFees}
          className={`${buttonClassName} ${
            cancelButtonIsDisabled
              ? 'btn-outline-secondary'
              : 'btn-outline-primary'
          }`}
          disabled={cancelButtonIsDisabled}
        >
          <span className='text-ellipsis d-block'>
            <Translation id='Screens.FeeNotes.Cancel' />
          </span>
        </button>
      </div>
    </>
  );
});

//Observer component for the fees list
const List = observer(() => {
  const filteredFetchedList = getFilteredFetchedList();
  return (
    <>
      {filteredFetchedList.map((k, i) => {
        const status = statusEnumsObs.get().find((e) => e.id === k.status);
        return (
          <div
            key={i}
            className={`row align-items-center py-3 ${
              i !== filteredFetchedList.length - 1 ? 'border-bottom' : ''
            } `}
          >
            <div className='col-1 text-center'>
              <Checkbox
                checked={k.checked}
                id={k.id}
                onChange={(checkbox) => onCheckBoxChange(checkbox, k)}
              />
            </div>
            <div className='col-3'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={`${window.__env.REACT_APP_ADMIN_WEBSITE_URL}/Users/Doctor/${k.doctorId}`}
                className='link-primary'
              >
                <u>
                  {k.doctorFirstname} {k.doctorLastname}
                </u>
              </a>
            </div>
            <div className='col-2'>{k.grandTotalAmount} €</div>
            <div className='col-2  text-capitalize'>
              {moment(k.relatedMonth).format('MMMM YYYY')}
            </div>
            <div className='col-2  text-capitalize'>
              {k.validationDate && (
                <span>{moment(k.validationDate).format('DD/MM/YYYY')}</span>
              )}
            </div>
            <div className='col-2 text-center'>
              <span
                title={status.label}
                className={`badge ${
                  statusEnums[status.code].ico
                } text-uppercase`}
              >
                {statusEnums[status.code].i18n}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
});

//Observer component for the total sum box
const SelectedSum = observer(() => {
  return (
    <>
      <span>
        <Translation id='Screens.FeeNotes.SelectedSum' />
      </span>
      <div className='py-2 bg-light font-weight-bold'>{getTotalSum()} €</div>
    </>
  );
});

//Observer component for the days picker
const DaysPickers = observer(() => {
  const toInput = useRef(null);
  const modifiers = {
    start: dayPickerRange.get().from,
    end: dayPickerRange.get().from,
  };

  const showFromMonth = () => {
    if (!dayPickerRange.get().from) {
      return;
    }
    if (
      moment(dayPickerRange.get().to).diff(
        moment(dayPickerRange.get().from),
        'months',
      ) < 2
    ) {
      toInput.current.getDayPicker().showMonth(dayPickerRange.get().from);
    }
  };

  const handleFromChange = (from) => {
    const c = { ...dayPickerRange.get() };
    c.from = from;
    runInAction(() => {
      dayPickerRange.set(c);
    });
  };

  const handleToChange = (to) => {
    const c = { ...dayPickerRange.get() };
    c.to = to;
    const fromRelatedMonth = moment(c.from).format('YYYY-MM-DD');
    const toRelatedMonth = moment(c.to).format('YYYY-MM-DD');
    const searchString = getSearchString(fromRelatedMonth, toRelatedMonth);
    runInAction(() => {
      dayPickerRange.set(c);
      updateFetchedListByDate(searchString);
    });
    showFromMonth();
  };

  return (
    <>
      <Translation id='Screens.FeeNotes.From' />
      &nbsp;
      <DayPickerInput
        value={dayPickerRange.get().from}
        placeholder={previousMonth.format('MM/YYYY')}
        format='MMMM YYYY'
        className='DayPickerInput'
        inputProps={{ className: 'form-control  w-auto d-inline-block' }}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          selectedDays: [
            dayPickerRange.get().from,
            { from: dayPickerRange.get().from, to: dayPickerRange.get().to },
          ],
          disabledDays: { after: dayPickerRange.get().to },
          toMonth: dayPickerRange.get().to,
          modifiers,
          numberOfMonths: 1,
          onDayClick: () => toInput.current.getInput().focus(),
          locale: 'fr',
          localeUtils: MomentLocaleUtils,
        }}
        onDayChange={handleFromChange}
      />
      &nbsp;
      <Translation id='Screens.FeeNotes.To' />
      &nbsp;
      <DayPickerInput
        ref={toInput}
        value={dayPickerRange.get().to}
        inputProps={{ className: 'form-control  w-auto d-inline-block' }}
        placeholder={previousMonth.format('MM/YYYY')}
        format='MMMM YYYY'
        className='DayPickerInput'
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          selectedDays: [
            dayPickerRange.get().from,
            { from: dayPickerRange.get().from, to: dayPickerRange.get().to },
          ],
          disabledDays: { before: dayPickerRange.get().from },
          modifiers,
          month: dayPickerRange.get().to,
          fromMonth: dayPickerRange.get().from,
          numberOfMonths: 1,
          locale: 'fr',
          localeUtils: MomentLocaleUtils,
        }}
        onDayChange={handleToChange}
      />
    </>
  );
});

//Observer component for the selectAll fees checkbox
const ToggleDownLoadCheckbox = observer(() => {
  const feeIsChecked = (fee) => fee.checked === true;
  const filteredList = getFilteredFetchedList();
  const isChecked = filteredList.length > 0 && filteredList.every(feeIsChecked);
  return (
    <Checkbox
      checked={isChecked}
      id='select-all'
      onChange={selectOrUnselectAllFees}
    />
  );
});

//Observer component for the cancel Modal
const CancelModal = observer(() => {
  const FeeList = () =>
    getCheckedFeeList().map((fee, index) => (
      <div className='row' key={index}>
        <div className='col text-ellipsis'>
          {fee.doctorFirstname} {fee.doctorLastname}
        </div>
        <div className='col text-capitalize'>
          {moment(fee.relatedMonth).format('MMMM YYYY')}
        </div>
        <div className='col'>
          {fee.validationDate && (
            <span>{moment(fee.validationDate).format('DD/MM/YYYY')}</span>
          )}
        </div>
        <div className='col font-weight-bold text-right'>
          {fee.grandTotalAmount} €
        </div>
      </div>
    ));
  return (
    <Modal
      isOpen={cancelModalVisibility.get()}
      toggle={closeCancelModal}
      size='lg'
    >
      <ModalHeader toggle={closeCancelModal}>
        <span className='font-weight-bold'>
          <Translation id='Screens.FeeNotes.ConfirmCancellation' />
        </span>
      </ModalHeader>
      <ModalBody>
        <div className='d-grid gap-5'>
          <div className='text-center'>
            <span className='text-center font-weight-bold font-size-19px'>
              <Translation id='Screens.FeeNotes.ConfirmCancellationLabel' />
            </span>
          </div>
          <div className='container-fluid'>
            <div className='row '>
              <div className='col'>
                <Translation id='Screens.FeeNotes.ListDoctors' />
              </div>
              <div className='col'>
                <Translation id='Screens.FeeNotes.ReferalMonth' />
              </div>
              <div className='col'>
                <Translation id='Screens.FeeNotes.ValidationDate' />
              </div>
              <div className='col text-right'>
                <Translation id='Screens.FeeNotes.Total' />
              </div>
            </div>
          </div>
          <div className='container-fluid d-grid gap-2 overflow-auto max-height-30vh mt-n3'>
            <FeeList />
          </div>
          <div className='container-fluid'>
            <div className='bg-light py-3 row'>
              <div className='col'>
                <Translation id='Screens.FeeNotes.Total' />
              </div>
              <div className='col font-weight-bold text-right'>
                {getTotalSum()} €
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className='w-100'>
          <div className='justify-content-end row'>
            <div className='col-auto'>
              <button className='btn' onClick={closeCancelModal}>
                <Translation id='Screens.FeeNotes.Back' />
              </button>
            </div>
            <div className='col-auto'>
              <button className='btn btn-danger' onClick={onEffectiveCancel}>
                <Translation id='Screens.FeeNotes.CancelDefinitely' />
              </button>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
});

//Observer component for the Select status droplist
const StatusFilter = observer(() => {
  const filtersOptions = Object.keys(toJS(statusEnumsContextualObs.get())).map(
    (e, i) => {
      return {
        value: statusEnumsContextualObs.get()[e].id,
        label: (
          <span className='text-uppercase'>
            {statusEnums[statusEnumsContextualObs.get()[e].code].i18n}
          </span>
        ),
      };
    },
  );

  return filtersOptions.length > 0 ? (
    <Select
      noOptionsMessage={() => (
        <Translation id='Screens.FeeNotes.NoOptionsMessage' />
      )}
      placeholder={<Translation id='Screens.FeeNotes.FilterByStatus' />}
      isMulti
      options={filtersOptions}
      onChange={onSelectFilterChange}
    />
  ) : (
    <div>
      <Translation id='Screens.FeeNotes.NoAvailableFilters' />
    </div>
  );
});

//The main component
export const FeeNotes = () => {
  useEffect(() => {
    componentDidMount();
    return () => componentWillUnMount();
  }, []);

  return (
    <div>
      <CancelModal />
      <PageHeader>
        <Translation id='Screens.FeeNotes.Title' tagName='h1' />
      </PageHeader>
      <Translation id='Screens.FeeNotes.Description' tagName='p' />
      <Section>
        <SectionBody>
          <div className='d-grid gap-3'>
            <div className='filter'>
              <h6 className='font-weight-bold'>
                <Translation id='Screens.FeeNotes.FilterByReferenceMonth' />
              </h6>
              <div>
                <DaysPickers />
              </div>
            </div>

            <div className='action'>
              <div className='row align-items-center'>
                <div className='col-4'>
                  <div className='row no-gutters'>
                    <DownloadActions />
                  </div>
                </div>
                <div className='col-auto text-center'>
                  <SelectedSum />
                </div>
                <div className='col'>
                  <div className='row justify-content-end'>
                    <div className='col col-lg-9 col-xl-8'>
                      <div className='form-group mb-0'>
                        <label className='color-inherit'>
                          <Translation id='Screens.FeeNotes.FilterByStatusLabel' />
                        </label>
                        <StatusFilter />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='list container-fluid border rounded'>
              <div className='row  text-uppercase font-weight-bold bg-light py-3 align-items-center listHeader rounded-top'>
                <div className='col-1 text-center'>
                  <ToggleDownLoadCheckbox />
                </div>
                <div className='col-3 '>
                  <Translation id='Screens.FeeNotes.Doctors' />
                </div>
                <div className='col-2'>
                  <Translation id='Screens.FeeNotes.TotalSum' />
                </div>
                <div className='col-2'>
                  <Translation id='Screens.FeeNotes.ReferalMonth' />
                </div>
                <div className='col-2'>
                  <Translation id='Screens.FeeNotes.ValidationDateTitle' />
                </div>
                <div className='col-2 text-center'>
                  <Translation id='Screens.FeeNotes.Status' />
                </div>
              </div>
              <List />
            </div>
          </div>
        </SectionBody>
      </Section>
    </div>
  );
};
