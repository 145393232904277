import React from 'react';
import { Formik, Form } from 'formik';
import { toaster } from '@teladochealth/components';

import { ProductTerm } from 'models';
import { Button, PageHeader, Translation } from 'components';
import { service } from 'services';
import { api, urlSchema } from 'utils';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import initialValues from './initialValues';
import validationSchema from './validationSchema';

const Create = ({ history }) => {
  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.manager.postProductTerms, values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          toaster({
            title: <Translation id='Message.Error' />,
            text: (
              <>
                {data.errorSet.map((error) => (
                  <p key={error.code}>
                    {error.code}: {error.description}
                  </p>
                ))}
              </>
            ),
            type: 'error',
          });
          setSubmitting(false);
          return;
        }
        toaster({
          title: <Translation id='Message.Success' />,
          text: <Translation id='Screens.ProductTerms.Create.SuccessBody' />,
          type: 'success',
        });
        history.push(`${urlSchema.productTerms.read}/${data.idProductTerm}`);
      })
      .catch(({ data }) => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: (
            <>
              {data.errorSet.map((error) => (
                <p key={error.code}>
                  {error.code}: {error.description}
                </p>
              ))}
            </>
          ),
          type: 'error',
        });
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation id='Screens.ProductTerms.Create.Title' tagName='h1' />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <ProductTerm />
                <ButtonsWrapper bottom>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    isProcessing={isSubmitting}
                    label='Create'
                    size='lg'
                    type='submit'
                  />
                </ButtonsWrapper>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default Create;
