import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { Badge, Col, Container, Input, Row } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import { Link } from 'react-router-dom';
import { toaster } from '@teladochealth/components';

import {
  Button,
  Checkbox,
  PageHeader,
  RoleManager,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, useFetch, urlSchema } from 'utils';
import { IsActiveWrapper } from './elements';

const List = () => {
  const intl = useIntl();
  const [specialties, setSpecialties] = useState([]);
  const [organizationConfigs, areOrganizationConfigsLoading] = useFetch(
    api.organizationConfigs.getListItems,
  );
  const debouncedCallback = useDebouncedCallback((fn) => {
    fn();
  }, 350);
  const [isLoading, setIsLoading] = useState(true);

  const getResults = (values = null, callback) => {
    setIsLoading(true);
    service
      .get(api.admin.getSpecialties, {
        params: values,
      })
      .then(({ data }) => {
        setSpecialties(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: <Translation id='Message.Api.Error' />,
          type: 'error',
        });
      });
  };

  const onSubmit = (values, properties) => {
    getResults(values, () => {
      properties.setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults({
      isValid: true,
      searchString: '',
      refOrganizationConfig: '',
    });
  }, []);

  return (
    <>
      <PageHeader>
        <Translation id='Menu.Specialties' tagName='h1' />
        <RoleManager entity='Specialties' right='CRUD'>
          <Link
            data-tracking='SpecialtiesListBtnCreate'
            className='btn btn-primary'
            to={urlSchema.specialties.create}
          >
            <Translation id='Buttons.Create' />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          <Formik
            initialValues={{
              searchString: '',
              RefOrganizationConfig: '',
              isValid: true,
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className='w-100'>
                <Row className='align-items-center'>
                  <Col md='4'>
                    <Field
                      className='form-control'
                      name='searchString'
                      type='text'
                    >
                      {({ field }) => (
                        <Input
                          {...field}
                          id='search-string'
                          onChange={(event) => {
                            field.onChange(event);
                            debouncedCallback(() => handleSubmit());
                          }}
                          placeholder={intl.formatMessage({
                            id: 'Screens.Specialties.Search',
                            defaultMessage: 'Error.Screens.Specialties.Search',
                          })}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md='4'>
                    {areOrganizationConfigsLoading ? (
                      <Spinner color='primary' size='sm' />
                    ) : (
                      <Field name='RefOrganizationConfig'>
                        {({ field }) => {
                          const { onChange } = field;
                          return (
                            <Input
                              {...field}
                              id='ref-organization-config'
                              onChange={(event) => {
                                onChange(event);
                                debouncedCallback(() => handleSubmit());
                              }}
                              type='select'
                            >
                              <option value=''>
                                <Translation id='Shared.All' />
                              </option>
                              {organizationConfigs.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.label} ({item.code})
                                  </option>
                                );
                              })}
                            </Input>
                          );
                        }}
                      </Field>
                    )}
                  </Col>
                  <Col md='2'>
                    <Field name='isValid'>
                      {({ field, form }) => (
                        <Checkbox
                          {...field}
                          checked={form.values.isValid}
                          id='is-valid'
                          onChange={(event) => {
                            field.onChange(event);
                            handleSubmit();
                          }}
                        >
                          <Translation id='Shared.Valid' />
                        </Checkbox>
                      )}
                    </Field>
                  </Col>
                  <Col md='auto' className='ml-auto'>
                    <Button
                      className='text-ellipsis'
                      color='primary'
                      data-tracking='SpecialtiesBtnSearch'
                      isProcessing={isSubmitting}
                      label='Research'
                      type='submit'
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          <div className='research-table__header'>
            <Container fluid>
              <Row>
                <Col xs='4'>
                  <Translation tagName='h5' id='Screens.Specialties.Code' />
                </Col>
                <Col xs='3'>
                  <Translation tagName='h5' id='Screens.Specialties.Validity' />
                </Col>
                <Col xs='3'>
                  <Translation tagName='h5' id='Screens.Specialties.Offer' />
                </Col>
              </Row>
            </Container>
          </div>
          {isLoading ? (
            <Spinner color='primary' size='lg' />
          ) : specialties.length > 0 ? (
            <ul className='research-table__results'>
              {specialties.map((item) => {
                return (
                  <li key={item.idSpecialty}>
                    <Container fluid>
                      <Row className='align-items-center'>
                        <Col xs='4'>
                          <IsActiveWrapper isActive={item.active}>
                            <i
                              className={`uil uil-${
                                item.active ? 'check-circle' : 'circle'
                              }`}
                              aria-hidden='true'
                            />
                            <div>
                              <h5 className='mb-0'> {item.code}</h5>
                              <small className='d-block'>{item.label}</small>
                            </div>
                          </IsActiveWrapper>
                        </Col>
                        <Col xs='3'>
                          <h3 className='m-0'>
                            {item.valid ? (
                              <Badge color='success'>
                                <Translation id='Shared.Valid' />
                              </Badge>
                            ) : (
                              <Badge color='danger'>
                                <Translation id='Shared.Invalid' />
                              </Badge>
                            )}
                          </h3>
                        </Col>
                        <Col xs='2'>
                          <Link
                            to={`${urlSchema.organizationConfigs.read}/${item.refOrganizationConfig}`}
                          >
                            {item.organizationConfigCode}
                          </Link>
                        </Col>
                        <Col xs='3' className='research-table__actions'>
                          <RoleManager entity='Doctors' right='Reader'>
                            <Link
                              to={`${urlSchema.doctors.list}?specialties=${item.idSpecialty}`}
                              className='btn btn-outline-primary btn-sm'
                            >
                              <Translation id='Screens.Specialties.ReadDoctors' />
                            </Link>
                          </RoleManager>
                          <RoleManager entity='Specialties' right='CRUD'>
                            <Link
                              to={`${urlSchema.specialties.update}/${item.idSpecialty}`}
                            >
                              <i aria-hidden className='uil uil-pen uil--md' />
                            </Link>
                          </RoleManager>
                          <RoleManager entity='Specialties' right='Reader'>
                            <Link
                              to={`${urlSchema.specialties.read}/${item.idSpecialty}`}
                            >
                              <i aria-hidden className='uil uil-eye uil--md' />
                            </Link>
                          </RoleManager>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className='text-secondary m-0'>
              <Translation id='Shared.NoElement' />
            </p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
