import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import { toaster } from '@teladochealth/components';

import {
  Button,
  FormInput,
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  Translation,
} from 'components';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { Organization } from 'models';
import { service } from 'services';
import { api, useFetch } from 'utils';
import validationSchema from './validationShema';

const Update = () => {
  const { formatMessage } = useIntl();
  const { universeId } = useParams();
  const [organization, isOrganizationLoading] = useFetch(
    api.manager.getUniversById(universeId),
  );

  const onSubmit = (values, { setSubmitting }) => {
    let newValues = {
      idUniverse: universeId,
      code: values.code,
      label: values.label,
    };
    service
      .put(api.manager.putUniverses(universeId), newValues)
      .then(() => {
        toaster({
          title: <Translation id='Message.Success' />,
          text: (
            <Translation
              id='Screens.Universes.Update.SuccessBody'
              values={{ code: values.code }}
            />
          ),
          type: 'success',
        });
        setSubmitting(false);
      })
      .catch((error) => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: (
            <Translation
              id='Screens.Universes.Update.FailBody'
              values={{
                code: values.code,
                label: values.label,
                description: `${error.data.errorSet[0].description} ${error.data.errorSet[1]?.description}`,
              }}
            />
          ),
          type: 'error',
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      {isOrganizationLoading ? (
        <Spinner size='lg' />
      ) : (
        <>
          <PageHeader>
            <Translation
              id='Screens.Universes.Update.Title'
              values={{ label: organization.label, code: organization.code }}
              tagName='h1'
            />
          </PageHeader>
          <Row>
            <Col lg='6'>
              <Section>
                <SectionBody>
                  <Formik
                    onSubmit={onSubmit}
                    initialValues={organization}
                    validationSchema={validationSchema}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <Organization organization={organization} />
                        <FormInput
                          id='labelOrganizationConfig'
                          label={formatMessage({
                            id: 'Universes.OrganizationConfigs',
                            defaultMessage:
                              'Error.Universes.OrganizationConfigs',
                          })}
                          name='labelOrganizationConfig'
                          disabled
                          required
                          type='text'
                        />
                        <ButtonsWrapper bottom>
                          <Button
                            color='primary'
                            isProcessing={isSubmitting}
                            label='Update'
                            size='lg'
                            data-tracking='OrganizationCreateSubmit'
                            type='submit'
                          />
                        </ButtonsWrapper>
                      </Form>
                    )}
                  </Formik>
                </SectionBody>
              </Section>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Update;
