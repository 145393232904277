import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  Section,
  SectionBody,
  Translation,
} from 'components';
import { ButtonsWrapper } from 'themes/jupiter/elements';
import { service } from 'services';
import { api, urlSchema } from 'utils';
import { OrganizationConfig } from '../../../models';
import initialValues from './initialValues';
import validationSchema from './validationShema';

const Create = () => {
  const history = useHistory();

  const onSubmit = (values, { setSubmitting }) => {
    service
      .post(api.admin.postOrganizationConfigs, values)
      .then((result) => {
        if (result.data.succeeded === false) {
          toaster({
            title: <Translation id='Message.Error' />,
            text: (
              <Translation
                id='Sreens.OrganizationConfigs.Create.FailBody'
                values={{
                  code: values.code,
                  label: values.label,
                  description: `${result.data.errorSet[0].description} ${result.data.errorSet[1]?.description}`,
                }}
              />
            ),
            type: 'error',
          });
          setSubmitting(false);
          return;
        }
        toaster({
          title: <Translation id='Message.Success' />,
          text: (
            <Translation
              id='Sreens.OrganizationConfigs.Create.SuccessBody'
              values={{
                code: values.code,
              }}
            />
          ),
          type: 'success',
        });
        history.push(urlSchema.organizationConfigs.list);
      })
      .catch((error) => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: (
            <Translation
              id='Sreens.OrganizationConfigs.Create.FailBody'
              values={{
                code: values.code,
                label: values.label,
                description: `${error.data.errorSet[0].description} ${error.data.errorSet[1]?.description}`,
              }}
            />
          ),
          type: 'error',
        });
        setSubmitting(false);
      });
  };
  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.OrganizationConfigs.Create.Title'
          tagName='h1'
        />
      </PageHeader>
      <Row>
        <Col lg='6'>
          <Section>
            <SectionBody>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <OrganizationConfig />
                    <ButtonsWrapper bottom>
                      <Button
                        color='primary'
                        isProcessing={isSubmitting}
                        label='Create'
                        size='lg'
                        data-tracking='OrganizationConfigCreateSubmit'
                        type='submit'
                      />
                    </ButtonsWrapper>
                  </Form>
                )}
              </Formik>
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Create;
