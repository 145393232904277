import React, { useRef } from 'react';
import Papa from 'papaparse';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import CsvStore from '../stores/CsvStore';
import { csvColumns } from '../constants/csvColumns';
import { FilesList } from './FilesList';
import { SubmitFormButton } from './SubmitFormButton';
import { SelectPartners } from './SelectPartners';

const papaParseOptions = {
  worker: true,
  download: true,
  header: true,
  chunkSize: 6000,
  skipEmptyLines: true,
  fastMode: true,
  dynamicTyping: true,
};

const onParseComplete = (row, file, fileName) => {
  const c = toJS(CsvStore.filesToUpload);
  const o = Array.from({ length: 6 }).map(
    (e, i) => Object.keys(csvColumns)[i] === row.meta.fields[i],
  );
  const hasInValidFields = o.some((el) => el === false);
  c[fileName] = file;
  c[fileName].errors = row.errors;
  c[fileName].data = row.data;
  /*hasInValidFields &&
        c[fileName].errors.push(
            {
                "type": "InvalidColumnName",
                "code": "InvalidColumnName",
                "message": `Les en têtes du fichier doivent être "PARTENAIRE;ENTITE;NUMERO ADHERENT;NOM;PRENOM;DATE DE NAISSANCE;ProductTag"`
            }
        );*/
  CsvStore.filesToUpload = c;
};

const checkCsvFiles = (files) => {
  CsvStore.filesToUpload = {};
  Object.keys(files).map((fileName) => {
    const file = files[fileName];
    Papa.parse(file, {
      ...papaParseOptions,
      complete: (row) => onParseComplete(row, file, fileName),
    });
    return null;
  });
};

const InnerForm = () => {
  const csvFormInputFile = useRef(null);
  const onFileChange = ({ target }) => {
    checkCsvFiles(target.files);
    csvFormInputFile.current.value = null;
  };

  return (
    <form
      id='csvValidatorInnerForm'
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className='row align-items-end'>
        <div className='col-6'>
          <SelectPartners />
        </div>
        <div className='col-6 '>
          <input
            ref={csvFormInputFile}
            accept='.csv'
            type='file'
            className='d-none'
            name='filetoUpload'
            id='filetoUpload'
            onChange={onFileChange}
          />
          <button className='btn btn-outline-primary d-flex align-items-center position-relative'>
            <label htmlFor='filetoUpload' className='stretched-link'>
              <i className='uil uil-import mr-2'></i> Séléctionner un fichier
            </label>
          </button>
        </div>
      </div>
      <FilesList />
      <SubmitFormButton />
    </form>
  );
};

export const Form = observer(() =>
  CsvStore.partners.length > 0 ? <InnerForm /> : null,
);
