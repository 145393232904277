import React from 'react';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import { historyShape } from 'react-router-props';
import { toaster } from '@teladochealth/components';

import { ApiError, Button, PageHeader, Translation } from 'components';
import { WebServiceConfiguration } from 'models';
import { service } from 'services';
import { api, urlSchema } from 'utils';
import validationSchema from 'models/WebServiceConfiguration/validationSchema';
import initialValues from './initialValues';

const Create = ({ history }) => {
  const { formatMessage } = useIntl();

  const onSubmit = (values) => {
    service
      .post(api.webServices.postConfiguration, values)
      .then(({ data }) => {
        if (typeof data.succeeded !== 'undefined' && !data.succeeded) {
          toaster({
            title: (
              <Translation
                id='Toasts.WebServicesConfiguration.Create.Fail'
                values={{ name: values.name }}
              />
            ),
            body: data.errorSet[0].description,
            type: 'error',
          });
        } else {
          toaster({
            text: (
              <Translation
                id='Toasts.WebServicesConfiguration.Create.Success'
                values={{ name: values.name }}
              />
            ),
            type: 'success',
          });
          history.push(
            `${urlSchema.webServices.configuration.read}/${data.idWebServiceConfig}`,
          );
        }
      })
      .catch((error) => {
        toaster({
          title: (
            <Translation
              id='Toasts.WebServicesConfiguration.Create.Fail'
              values={{ name: values.name }}
            />
          ),
          body: <ApiError {...{ error }} />,
          type: 'error',
        });
      });
  };

  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.WebServicesConfiguration.Create.Title'
          tagName='h1'
        />
      </PageHeader>
      <section className='section'>
        <div className='section__body'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema(formatMessage)}
          >
            {(formikProperties) => (
              <Form>
                <WebServiceConfiguration {...formikProperties} />
                <Button
                  data-tracking='WebServicesConfigurationCreateBtn'
                  color='primary'
                  isProcessing={formikProperties.isSubmitting}
                  label='Create'
                  size='lg'
                />
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

Create.propTypes = { history: historyShape.isRequired };

export default Create;
