import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { matchShape } from 'react-router-props';
import moment from 'moment';
import { toaster } from '@teladochealth/components';

import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import { ApiError, PageHeader, RoleManager, Translation } from 'components';
import Profiles from './Profiles';

const Property = ({ options }) => (
  <div className='model__property'>
    <h4 className='model__title'>
      <Translation id={`Model.${options.key}`} />
    </h4>
    <p className='model__value text-ellipsis'>{options.value || '-'}</p>
  </div>
);

const getValidityUser = {
  true: { key: 'Valid', icon: 'check' },
  false: { key: 'Invalid', icon: 'times' },
};

const Read = ({ match }) => {
  const { adminRef } = match.params;
  const [admin, isAdminLoading] = useFetch(
    api.administrators.getDetails(adminRef),
  );
  const [universes, areUniversesLoading] = useFetch(api.universes.getListItems);
  const [disabled, setDisabled] = useState(false);
  const keysUser = [
    {
      key: 'User.Email',
      value: (
        <>
          {admin.email}{' '}
          {admin.verifiedEmail ? (
            <i aria-hidden className='uil uil-check-circle uil--sm' />
          ) : (
            ''
          )}
        </>
      ),
    },
    { key: 'User.MobilePhone', value: admin.mobileNumber },
  ];
  const keysAccount = [
    { key: 'Administrator.CodeCompany', value: admin.infosAdmin?.codeCompany },
    { key: 'Administrator.Description', value: admin.infosAdmin?.description },
    { key: 'User.Notification', value: admin.notification },
    {
      key: 'User.CreationDate',
      value: moment(admin.dateCreation).format('LL'),
    },
    { key: 'User.Signature', value: admin.infosAdmin?.description },
  ];

  const putForgotPassword = () => {
    setDisabled(true);
    service
      .put(api.administrators.putForgotPassword(adminRef))
      .then(({ data }) => {
        if (typeof data !== 'undefined' && data.succeeded === false) {
          setDisabled(false);
          toaster({
            text: <Translation id='Toasts.Users.ForgotPassword.Fail' />,
            type: 'error',
          });
          return;
        }
        toaster({
          text: <Translation id='Toasts.Users.ForgotPassword.Success' />,
          type: 'success',
        });
      })
      .catch((error) => {
        toaster({
          title: <Translation id='Toasts.Users.ForgotPassword.Fail' />,
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
      })
      .then(() => {
        setDisabled(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Translation
          id='Screens.Admins.Read.Title'
          values={{ name: `${admin.firstname} ${admin.lastname}` }}
          tagName='h1'
        />
        <RoleManager entity='Admins' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.administrators.update}/${adminRef}`}
          >
            <Translation id='Buttons.Edit' />
          </Link>
        </RoleManager>
      </PageHeader>
      <div className='row'>
        <div className='col-7'>
          <section className='section'>
            <div className='section__body'>
              {isAdminLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <>
                  <div className='row ml-1'>
                    <div>
                      <h2 className='mb-3 text-ellipsis'>{`${admin.firstname} ${admin.lastname}`}</h2>
                      <div>
                        <div
                          className={`badge badge-${
                            admin.valid ? 'success' : 'danger'
                          } mb-2`}
                        >
                          <span className='d-inline-block align-middle mr-1'>
                            <Translation
                              id={`Model.${[getValidityUser[admin.valid].key]}`}
                            />
                          </span>
                          <i
                            aria-hidden
                            className={`uil uil-${
                              getValidityUser[admin.valid].icon
                            }-circle uil--sm align-middle`}
                          />
                        </div>
                        {admin.blocked ? (
                          <div className='badge badge-danger ml-4'>
                            <span className='d-inline-block align-middle mr-1'>
                              <Translation id='Model.User.Blocked' />
                              <i
                                aria-hidden
                                className='uil uil-ban uil--sm align-middle'
                              />
                            </span>
                          </div>
                        ) : null}
                        {!areUniversesLoading && (
                          <Link
                            className='btn btn-outline-info btn-sm ml-2'
                            to={`${urlSchema.universes.read}/${admin.refUniverse}`}
                          >
                            <i aria-hidden className='uil uil-globe' />
                            <span className='ml-2'>
                              {
                                universes.find(
                                  (element) => element.id === admin.refUniverse,
                                ).label
                              }
                            </span>
                          </Link>
                        )}
                        <button
                          className='btn btn-outline-primary btn-sm ml-2'
                          disabled={disabled}
                          onClick={putForgotPassword}
                          type='button'
                        >
                          <Translation id='Buttons.GeneratePassword' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-6'>
                      <Translation
                        id='Model.User.AccountInformation'
                        tagName='h3'
                      />

                      {keysAccount.map((element) => (
                        <Property key={element.key} options={element} />
                      ))}
                    </div>
                    <div className='col-6'>
                      <Translation
                        id='Model.User.UserInformation'
                        tagName='h3'
                      />
                      {keysUser.map((element) => (
                        <Property key={element.key} options={element} />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
        <div className='col-5 '>
          <Profiles
            adminId={adminRef}
            data={admin?.usersProfiles}
            isLoading={isAdminLoading}
          />
        </div>
      </div>
    </>
  );
};

Read.propTypes = { match: matchShape.isRequired };

export default Read;
