import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import { historyShape, matchShape } from 'react-router-props';
import { toaster } from '@teladochealth/components';

import { Admin } from 'models';
import { ApiError, Button, PageHeader, Translation } from 'components';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import validationSchema from 'models/Admin/validationSchema';

import { Formik, Form } from 'formik';
import Profiles from './Profiles';

const Update = ({ history, match }) => {
  const { formatMessage } = useIntl();
  const { adminRef } = match.params;
  const [admin, isAdminLoading] = useFetch(
    api.administrators.getDetails(adminRef),
  );

  admin.birthdate = moment(admin.birthdate).format('YYYY-MM-DD');

  const onSubmit = (values, { setSubmitting }) => {
    service
      .put(api.administrators.put(values.idUser), values)
      .then(({ data }) => {
        if (data.succeeded === false) {
          toaster({
            text: (
              <Translation
                id='Toasts.Admins.Update.Fail'
                values={{ name: `${values.firstname} ${values.lastname}` }}
              />
            ),
            type: 'error',
          });
          setSubmitting(false);
          return;
        }
        toaster({
          text: (
            <Translation
              id='Toasts.Admins.Update.Success'
              values={{ name: `${values.firstname} ${values.lastname}` }}
            />
          ),
          type: 'success',
        });
        history.push(urlSchema.administrators.list);
      })
      .catch((error) => {
        toaster({
          title: (
            <Translation
              id='Toasts.Admins.Update.Fail'
              values={{ name: `${values.firstname} ${values.lastname}` }}
            />
          ),
          text: <ApiError {...{ error }} />,
          type: 'error',
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageHeader>
        <h1>
          <Translation
            id='Screens.Admins.Update.Title'
            values={{
              name: admin ? `${admin.firstname} ${admin.lastname}` : '',
            }}
          />
        </h1>
      </PageHeader>
      <div className='row'>
        <div className='col-7'>
          <section className='section'>
            <div className='section__body'>
              {isAdminLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Formik
                  initialValues={admin}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema(formatMessage)}
                >
                  {(formikProperties) => (
                    <Form>
                      <Admin />
                      <Button
                        color='primary'
                        isProcessing={formikProperties.isSubmitting}
                        label='Save'
                        size='lg'
                        type='submit'
                      />
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </section>
        </div>
        <div className='col-5'>
          <section className='section'>
            <header className='section__header'>
              <h2>
                <Translation id='Model.Admin.Profiles' />
              </h2>
            </header>
            <div className='section__body'>
              {isAdminLoading ? (
                <Spinner color='primary' size='lg' />
              ) : (
                <Profiles
                  data={admin.usersProfiles}
                  isLoading={isAdminLoading}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

Update.propTypes = {
  history: historyShape.isRequired,
  match: matchShape.isRequired,
};

export default Update;
