import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import { api } from 'utils';
import { PageHeader, Translation } from 'components';
import { service } from 'services';
import ExportForm from './ExportForm';
import SearchForm from './SearchForm';

const Marketing = () => {
  const [queryValues, setQueryValues] = useState({});
  const [contactsLength, setContactsLength] = useState(false);
  const offsetTop = 85;

  const searchContacts = (values) => {
    const newValues = {
      ...values,
      lastConsultationDateFrom: values.lastConsultationDateFrom
        ? `${values.lastConsultationDateFrom} 00:00:00`
        : '',
      lastConsultationDateTo: values.lastConsultationDateTo
        ? `${values.lastConsultationDateTo} 23:59:59`
        : '',
      registrationDateFrom: values.registrationDateFrom
        ? `${values.registrationDateFrom} 00:00:00`
        : '',
      registrationDateTo: values.registrationDateTo
        ? `${values.registrationDateTo} 23:59:59`
        : '',
    };
    setQueryValues(newValues);
    service
      .post(api.manager.postMarketingContacts, newValues)
      .then(({ data }) => {
        setQueryValues(values);
        setContactsLength(data.numberOfContacts);
      })
      .catch(() => {
        toaster({
          title: <Translation id='Shared.Error' />,
          text: <Translation id='Screens.Marketing.Toast.ErrorSearch' />,
          type: 'error',
        });
      });
  };

  const exportToSendinBlue = (values, { setSubmitting }) => {
    const { folderId, listName } = values;
    if (queryValues && contactsLength) {
      service
        .post(api.manager.exportToSendinBlue(folderId, listName), queryValues)
        .then(() => {
          toaster({
            title: <Translation id='Shared.Success' />,
            text: (
              <Translation
                values={{ listName }}
                id='Screens.Marketing.Toast.Success'
              />
            ),
            type: 'success',
          });
        })
        .catch(() => {
          toaster({
            title: <Translation id='Shared.Error' />,
            text: <Translation id='Screens.Marketing.Toast.Failed' />,
            type: 'error',
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <PageHeader>
        <div>
          <h1>
            <Translation id='Screens.Marketing.Title' />
          </h1>
          <small>
            <Translation id='Screens.Marketing.Subtitle' />
          </small>
        </div>
      </PageHeader>
      <Row>
        <Col lg='8'>
          <SearchForm onSubmit={(data) => searchContacts(data)} />
        </Col>
        <Col lg='4'>
          <div className='sticky-top' style={{ top: offsetTop + 'px' }}>
            <ExportForm
              contactsLength={contactsLength}
              onSubmit={exportToSendinBlue}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Marketing;
