import { Link, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useOidc } from 'utils';
import messages from './messages';

interface IProtectedRoute {
  component: () => React.ReactNode | React.JSX.Element;
  entity: string;
  path: string;
  right: string;
}

const ProtectedRoute = ({
  component,
  entity,
  path,
  right,
  ...otherProperties
}: IProtectedRoute) => {
  const oidcUser = useOidc();

  if (
    oidcUser.profile.roles.includes(`${entity}${right}`) ||
    oidcUser.profile.roles.includes(`${entity}CRUD`)
  )
    return <Route {...{ component }} {...{ path }} {...otherProperties} />;

  return (
    <div id='access-denied'>
      <div className='mx-auto'>
        <h1>403</h1>
        <FormattedMessage tagName='h2' {...messages.Subtitle} />
        <FormattedMessage tagName='h3' {...messages.Description} />
        <Link to='/'>
          <FormattedMessage {...messages.ButtonBack} />
        </Link>
      </div>
    </div>
  );
};

export { ProtectedRoute };
