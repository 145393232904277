import React, { useState } from 'react';
import {
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { toaster } from '@teladochealth/components';

import { Translation } from 'components';
import { service } from 'services';
import { api } from 'utils';
import {
  NotLinked,
  Ok,
  Unknown,
  WronglyLinkedToWs,
  WrongVerificationType,
} from '../StatusConstants';

const EntitiesActionAndModal = ({
  actionName,
  productId,
  webServiceId,
  setUpdateProductStatus,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const migrateProduct = () => {
    service
      .put(api.webServices.putMigrationProduct(webServiceId, productId))
      .then((response) => {
        if (response.status === 204) {
          toggle();
          setUpdateProductStatus(Math.random());
          toaster({
            title: <Translation id='Message.Success' />,
            text: (
              <Translation
                id={`Toasts.Product.Success.Product.${actionName}`}
              />
            ),
            type: 'succes',
          });
        } else {
          toggle();
          toaster({
            title: <Translation id='Message.Error' />,
            text: <span>{response.data.errorSet[0].description}</span>,
            type: 'error',
          });
        }
      });
  };

  return (
    <>
      <button type='button' className='btn btn-primary btn-sm' onClick={toggle}>
        <Translation id={`Screens.WebServices.Read.Entity.${actionName}`} />
      </button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>
          <Translation
            id={`Screens.WebServices.Read.Entity.Modal.Title.${actionName}`}
          />
        </ModalHeader>
        <ModalBody>
          <Translation
            id={`Screens.WebServices.Read.Entity.Modal.Text.${actionName}`}
          />
        </ModalBody>
        <ModalFooter>
          <div className='w-100 text-right'>
            <button
              type='button'
              className='btn btn-primary btn-sm mr-3'
              onClick={() => migrateProduct()}
            >
              <Translation id='Buttons.Validate' />
            </button>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={toggle}
            >
              <Translation id='Buttons.Cancel' />
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

const EntityStatus = (properties) => {
  let StatusComponent = '';
  switch (properties) {
    case WronglyLinkedToWs: {
      StatusComponent = (
        <Badge color='danger'>
          <i className='uil uil-ban mr-1' />
          <span id='WronglyLinkedToWs'>
            <Translation id='Screens.WebServices.Read.Entity.WronglyLinkedToWs' />
          </span>
          <UncontrolledTooltip placement='top' target='WronglyLinkedToWs'>
            <Translation id='Screens.WebServices.Read.TooltipWronglyLinkedToWs' />
          </UncontrolledTooltip>
        </Badge>
      );
      break;
    }
    case NotLinked: {
      StatusComponent = (
        <Badge color='warning'>
          <span id='NotLinked'>
            <i className='uil uil-exclamation-triangle mr-1' />
            <Translation id='Screens.WebServices.Read.Entity.NotLinked' />
          </span>
          <UncontrolledTooltip placement='top' target='NotLinked'>
            <Translation id='Screens.WebServices.Read.TooltipNotLinked' />
          </UncontrolledTooltip>
        </Badge>
      );
      break;
    }
    case (NotLinked, WrongVerificationType): {
      StatusComponent = (
        <div className='d-flex flex-column'>
          <div>
            <Badge color='warning'>
              <i className='uil uil-exclamation-triangle mr-1' />
              <span id='NotLinkedAndWrongVerif'>
                <Translation id='Screens.WebServices.Read.Entity.NotLinked' />
              </span>
              <UncontrolledTooltip
                placement='top'
                target='NotLinkedAndWrongVerif'
              >
                <Translation id='Screens.WebServices.Read.TooltipNotLinked' />
              </UncontrolledTooltip>
            </Badge>
          </div>
          <div>
            <Badge color='warning'>
              <i className='uil uil-ban mr-1' />
              <span id='WrongVerificationType'>
                <Translation id='Screens.WebServices.Read.Entity.WrongVerificationType' />
              </span>
              <UncontrolledTooltip
                placement='top'
                target='WrongVerificationType'
              >
                <Translation id='Screens.WebServices.Read.TooltipWrongVerificationType' />
              </UncontrolledTooltip>
            </Badge>
          </div>
        </div>
      );
      break;
    }
    case Unknown: {
      StatusComponent = (
        <Badge color='danger'>
          <i className='uil uil-times mr-1' />
          <span id='Unknown'>
            <Translation id='Screens.WebServices.Read.Entity.Unknown' />
          </span>
          <UncontrolledTooltip placement='top' target='Unknown'>
            <Translation id='Screens.WebServices.Read.TooltipUnknown' />
          </UncontrolledTooltip>
        </Badge>
      );
      break;
    }
    case Ok: {
      StatusComponent = (
        <Badge color='success'>
          <i className='uil uil-check mr-1' />
          <span id='OK'>
            <Translation id='Screens.WebServices.Read.Entity.Ok' />
          </span>
          <UncontrolledTooltip placement='top' target='OK'>
            <Translation id='Screens.WebServices.Read.TooltipOK' />
          </UncontrolledTooltip>
        </Badge>
      );
      break;
    }
    default: {
      StatusComponent = <>{properties}</>;
    }
  }
  return StatusComponent;
};

const EntityActions = ({
  status,
  productId,
  webServiceId,
  setUpdateProductStatus,
}) => {
  const link = 'Link';
  const migrate = 'Migrate';
  const fix = 'Fix';
  let ActionComponent = '';
  switch (status) {
    case NotLinked: {
      ActionComponent = (
        <EntitiesActionAndModal
          productId={productId}
          webServiceId={webServiceId}
          actionName={link}
          setUpdateProductStatus={setUpdateProductStatus}
        />
      );
      break;
    }
    case (NotLinked, WrongVerificationType): {
      ActionComponent = (
        <EntitiesActionAndModal
          productId={productId}
          webServiceId={webServiceId}
          actionName={migrate}
          setUpdateProductStatus={setUpdateProductStatus}
        />
      );
      break;
    }
    case WrongVerificationType: {
      ActionComponent = (
        <EntitiesActionAndModal
          productId={productId}
          webServiceId={webServiceId}
          actionName={fix}
          setUpdateProductStatus={setUpdateProductStatus}
        />
      );
      break;
    }
    default: {
      ActionComponent = '';
    }
  }
  return ActionComponent;
};

export { EntityStatus, EntityActions };
