import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { toaster } from '@teladochealth/components';

import {
  PageHeader,
  Section,
  SectionBody,
  Spinner,
  Translation,
} from 'components';
import { Partner } from 'models';
import { service } from 'services';
import { api, urlSchema, useFetch } from 'utils';
import { Methods } from '../../../constants';

const Update = () => {
  const { partnerId } = useParams();
  const history = useHistory();
  const [partner, isPartnerLoading] = useFetch(
    api.manager.getPartner(partnerId),
  );
  const [initialValues, setInitialValues] = useState(undefined);

  const onSubmit = (values, { setSubmitting }) => {
    let newValues = values;

    if (
      values.eligiblePartnersResources.length > 0 &&
      !values.eligiblePartnersResources[0].idEligiblePartnersResources &&
      (values.eligiblePartnersResources[0].eligibilityKeyDescription ||
        values.eligiblePartnersResources[0].eligibilityKey)
    ) {
      newValues = {
        ...values,
        eligiblePartnersResources: [
          {
            codeLanguage: 'fr',
            eligibilityKey:
              values.eligiblePartnersResources[0].eligibilityKey || '',
            eligibilityKeyDescription:
              values.eligiblePartnersResources[0].eligibilityKeyDescription ||
              '',
            refEligiblePartner: values.idEligiblePartner,
          },
        ],
      };
    }

    service
      .put(api.manager.putPartner, newValues)
      .then(() => {
        toaster({
          title: <Translation id='Message.Success' />,
          text: (
            <Translation
              id='Partners.Update.ActionSuccessResponse'
              values={{
                partnerName: values.name,
              }}
            />
          ),
          type: 'success',
        });
        setSubmitting(false);
        history.push(`${urlSchema.readPartner}/${partnerId}`);
      })
      .catch(() => {
        toaster({
          title: <Translation id='Message.Error' />,
          text: (
            <Translation
              id='Partners.Update.ActionErrorResponse'
              values={{
                partnerName: values.name,
              }}
            />
          ),
          type: 'error',
        });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (!isPartnerLoading) {
      setInitialValues(partner);
    }
  }, [isPartnerLoading]);

  if (isPartnerLoading) return <Spinner size='lg' />;

  return (
    <>
      <PageHeader>
        <Translation
          id='Partners.Update.Title'
          values={{
            partnerName: partner.name,
          }}
          tagName='h1'
        />
      </PageHeader>
      <Row>
        <Col md='8' lg='6'>
          <Section>
            <SectionBody>
              <Partner
                initialValues={initialValues}
                isLoading={isPartnerLoading}
                method={Methods.PUT}
                onSubmit={onSubmit}
              />
            </SectionBody>
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Update;
