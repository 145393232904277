import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Row, Col, Collapse, Container, Input } from 'reactstrap';
import cn from 'classnames';
import qs from 'qs';
import { toaster } from '@teladochealth/components';

import {
  Button,
  Checkbox,
  LinksList,
  PageHeader,
  RoleManager,
  Spinner,
  Translation,
} from 'components';
import { service } from 'services';
import { api, removeKeysWithEmptyString, urlSchema, useFetch } from 'utils';
import { IsAvailableWrapper } from './elements';

const List = () => {
  const location = useLocation();
  const intl = useIntl();
  const [doctors, setDoctors] = useState([]);
  const [isCollapseOpen, setCollapseIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [universes, areUniversesLoading] = useFetch(api.universes.getListItems);
  const [specialties, areSpecialtiesLoading] = useFetch(
    api.admin.getSpecialties,
  );

  const getResults = (values = {}, callback) => {
    const parameters = qs.stringify(removeKeysWithEmptyString(values), {
      skipNulls: true,
    });
    setIsLoading(true);
    service
      .get(`${api.doctors.get}?${parameters}`)
      .then(({ data }) => {
        setDoctors(data);
        setIsLoading(false);
        if (typeof callback === 'function') callback();
      })
      .catch(() => {
        setIsLoading(false);
        toaster({
          title: <Translation id='Message.Error' />,
          text: <Translation id='Screens.Applications.Create.FailBody' />,
          type: 'error',
        });
      });
  };

  const onSubmit = (values, properties) => {
    getResults(values, () => {
      properties.setSubmitting(false);
    });
  };

  useEffect(() => {
    getResults(qs.parse(location.search, { ignoreQueryPrefix: true }));
  }, []);

  return (
    <>
      <PageHeader>
        <Translation id='Screens.Doctors.List.Title' tagName='h1' />
        <RoleManager entity='Doctors' right='CRUD'>
          <Link
            aria-label={intl.formatMessage({
              id: 'Buttons.Create',
              defaultMessage: 'Error.Buttons.Create',
            })}
            title={intl.formatMessage({
              id: 'Buttons.Create',
              defaultMessage: 'Error.Buttons.Create',
            })}
            className='btn btn-primary'
            to={urlSchema.doctors.create}
          >
            <Translation id='Buttons.Create' />
          </Link>
        </RoleManager>
      </PageHeader>
      <section className='section'>
        <header className='section__header'>
          {/* TODO: Ajouter le refUniverse */}
          <Formik
            initialValues={{
              onlyAvailable: true,
              searchString: '',
              specialties: qs.parse(location.search, {
                ignoreQueryPrefix: true,
              }).specialties
                ? [
                    qs.parse(location.search, { ignoreQueryPrefix: true })
                      .specialties,
                  ]
                : [],
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting, values }) => (
              <Form className='w-100'>
                <Row className='align-items-center mb-3'>
                  <Col md='5'>
                    <Field
                      className='form-control'
                      name='searchString'
                      type='text'
                    >
                      {({ field }) => {
                        const { onChange } = field;
                        return (
                          <Input
                            {...field}
                            id='search-string'
                            onChange={(event) => {
                              onChange(event);
                            }}
                            placeholder={intl.formatMessage({
                              id: 'Screens.Doctors.List.Search',
                              defaultMessage: 'Screens.Doctors.List.Search',
                            })}
                          />
                        );
                      }}
                    </Field>
                  </Col>
                  <Col md='auto'>
                    <Field name='onlyAvailable'>
                      {({ field, form }) => {
                        return (
                          <Checkbox
                            {...field}
                            checked={form.values.onlyAvailable}
                            id='only-available'
                            onChange={(event) => {
                              field.onChange(event);
                              handleSubmit();
                            }}
                          >
                            <Translation id='Screens.Doctors.List.AvailableOnly' />
                          </Checkbox>
                        );
                      }}
                    </Field>
                  </Col>
                  <Col md='1'>
                    <Button
                      color='primary'
                      type='button'
                      label='Specialties'
                      onClick={() => {
                        setCollapseIsOpen(!isCollapseOpen);
                      }}
                    />
                  </Col>
                  <Col md='auto' className='ml-auto'>
                    <Button
                      color='primary'
                      data-tracking='DoctorsBtnSearch'
                      isProcessing={isSubmitting}
                      type='submit'
                      label='Research'
                    />
                  </Col>
                </Row>
                <Collapse isOpen={isCollapseOpen}>
                  <Container fluid>
                    <FieldArray
                      name='specialties'
                      render={({ push, remove }) => (
                        <>
                          {/* We filter the specialties that correspond to a specific doctor */}
                          {specialties
                            .filter(
                              (element) =>
                                element.label.substring(0, 2) !== 'DR',
                            )
                            .map((specialty) => {
                              return (
                                <button
                                  className={cn('combined-btn', {
                                    'combined-btn--success':
                                      values.specialties.findIndex(
                                        (id) => id === specialty.idSpecialty,
                                      ) >= 0,
                                  })}
                                  key={specialty.idSpecialty}
                                  onClick={() => {
                                    const index = values.specialties.findIndex(
                                      (id) => {
                                        return id === specialty.idSpecialty;
                                      },
                                    );
                                    if (index >= 0) {
                                      remove(index);
                                    } else push(specialty.idSpecialty);
                                  }}
                                  type='button'
                                >
                                  <span> {specialty.label}</span>
                                  {values.specialties.findIndex(
                                    (element) =>
                                      element === specialty.idSpecialty,
                                  ) >= 0 ? (
                                    <i className='uil uil-check' />
                                  ) : (
                                    <i className='uil uil-plus' />
                                  )}
                                </button>
                              );
                            })}
                        </>
                      )}
                    />
                  </Container>
                </Collapse>
              </Form>
            )}
          </Formik>
        </header>
        <div className='section__body'>
          <div className='research-table__header'>
            <Container fluid>
              <Row>
                <Col xs='5' lg='3'>
                  <Translation tagName='h5' id='Screens.Doctors.List.Doctor' />
                </Col>
                <Col className='d-none d-lg-block' md='3'>
                  <Translation tagName='h5' id='Screens.Doctors.List.Contact' />
                </Col>
                <Col className='d-none d-lg-block' md='2'>
                  <Translation
                    tagName='h5'
                    id='Screens.Doctors.List.Specialties'
                  />
                </Col>
                <Col xs='4' lg='2'>
                  <Translation
                    tagName='h5'
                    id='Screens.Doctors.List.Organization'
                  />
                </Col>
              </Row>
            </Container>
          </div>
          {isLoading || areSpecialtiesLoading || areUniversesLoading ? (
            <Spinner size='lg' />
          ) : doctors.length > 0 ? (
            <ul className='research-table__results'>
              {doctors.map((doctor) => {
                console.log(doctor);
                return (
                  <li key={doctor.idUser}>
                    <Container fluid>
                      <Row className='align-items-center'>
                        <Col xs='5' lg='3'>
                          <IsAvailableWrapper
                            isAvailable={doctor.infosDoctor.available}
                          >
                            <i
                              className={`uil uil-${
                                doctor.infosDoctor.available
                                  ? 'check-circle'
                                  : 'circle'
                              }`}
                              aria-hidden='true'
                            />
                            <div>
                              <p className='m-0 text-dark'>
                                {doctor.title} {doctor.firstname}{' '}
                                {doctor.lastname}
                              </p>
                            </div>
                          </IsAvailableWrapper>
                        </Col>
                        <Col className='d-none d-lg-block' md='3'>
                          {doctor.email && (
                            <a
                              className='d-block text-ellipsis text-secondary'
                              href={`mailto:${doctor.email}`}
                            >
                              <i
                                className='align-middle uil uil-envelope mr-2'
                                aria-hidden
                              />
                              <small>{doctor.email}</small>
                            </a>
                          )}
                          {doctor.mobileNumber && (
                            <a
                              className='d-block text-ellipsis text-secondary'
                              href={`tel:${doctor.mobileNumber}`}
                            >
                              <i
                                className='align-middle uil uil-phone mr-2'
                                aria-hidden
                              />
                              <small>{doctor.mobileNumber}</small>
                            </a>
                          )}
                        </Col>
                        <Col className='d-none d-lg-block' md='2'>
                          <LinksList
                            areInnerLinks
                            color='warning'
                            data={doctor.usersSpecialties}
                            idKey={(element) => element}
                            labelKey={(listElement) =>
                              specialties.find(
                                (specialtyElement) =>
                                  specialtyElement.idSpecialty === listElement,
                              ).label
                            }
                            limit={3}
                            urlBase={urlSchema.specialties.read}
                          />
                        </Col>
                        <Col xs='4' lg='2'>
                          <Link
                            to={`${urlSchema.universes.read}/${doctor.refUniverse}`}
                          >
                            {
                              universes.find(
                                (element) => element.id === doctor.refUniverse,
                              ).label
                            }
                          </Link>
                        </Col>
                        <Col xs='1' className='ml-auto'>
                          <div className='research-table__actions'>
                            <RoleManager entity='Doctors' right='CRUD'>
                              <Link
                                aria-label={intl.formatMessage({
                                  id: 'Buttons.Edit',
                                  defaultMessage: 'Error.Buttons.Edit',
                                })}
                                title={intl.formatMessage({
                                  id: 'Buttons.Edit',
                                  defaultMessage: 'Error.Buttons.Edit',
                                })}
                                to={`${urlSchema.doctors.update}/${doctor.idUser}`}
                              >
                                <i
                                  className='uil uil-pen uil--md'
                                  aria-hidden
                                />
                              </Link>
                            </RoleManager>
                            <RoleManager entity='Doctors' right='Reader'>
                              <Link
                                aria-label={intl.formatMessage({
                                  id: 'Buttons.Read',
                                  defaultMessage: 'Error.Buttons.Read',
                                })}
                                title={intl.formatMessage({
                                  id: 'Buttons.Read',
                                  defaultMessage: 'Error.Buttons.Read',
                                })}
                                to={`${urlSchema.doctors.read}/${doctor.idUser}`}
                              >
                                <i
                                  aria-hidden
                                  className='uil uil-eye uil--md'
                                />
                              </Link>
                            </RoleManager>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className='text-secondary m-0'>
              <Translation id='Shared.NoElement' />
            </p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
