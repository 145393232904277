import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { toaster } from '@teladochealth/components';

import { Inputs } from '../inputs';
import validationSchema from '../validationShema';
import { PageHeader, Translation } from 'components';
import { urlSchema } from 'utils';
import Store from '../store';

const CustomForm = () => {
  const history = useHistory();
  return (
    <Formik
      enableReinitialize={false}
      validationSchema={validationSchema}
      initialValues={{
        codeClient: '',
        dateLastProcess: new Date(),
        email: '',
        filePath: '',
        idFileConfig: '',
        status: true,
      }}
      onSubmit={(values, { setSubmitting }) => {
        Store.addFileImportConfig(values)
          .then((result) => {
            history.push(
              `${urlSchema.fileImportConfigDetail(result.data.idFileConfig)}`,
            );
            setSubmitting(false);
            toaster({
              title: (
                <Translation id='Screens.FileImportConfigs.toast.add.success.header' />
              ),
              text: (
                <Translation id='Screens.FileImportConfigs.toast.add.success.body' />
              ),
              type: 'success',
            });
          })
          .catch(() => {
            setSubmitting(false);
            toaster({
              title: (
                <Translation id='Screens.FileImportConfigs.toast.add.error.header' />
              ),
              text: (
                <Translation id='Screens.FileImportConfigs.toast.add.error.body' />
              ),
              type: 'error',
            });
          });
      }}
    >
      {({ isSubmitting }) => (
        <Inputs
          {...{ isSubmitting }}
          submitLabel={'list.create'}
          disabledStatus={true}
        />
      )}
    </Formik>
  );
};

const Create = () => (
  <>
    <PageHeader>
      <h1>
        <Translation id='Screens.FileImportConfigs.create.title' />
      </h1>
    </PageHeader>

    <section className='section voucherGroupsEdit'>
      <div className='section__body'>
        <CustomForm />
      </div>
    </section>
  </>
);

export default Create;
