import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import { Translation } from '../../../../components';

export const PersonalInformation = ({ data }) => {
  return (
    <>
      <div className='mb-3'>
        <Translation id='Model.User.UserInformation' tagName='h2' />
      </div>
      <Row>
        <Col md='6'>
          <div className='model__property'>
            <h3 className='model__title'>
              <Translation id='Shared.Email' />
            </h3>
            <p className='model__value'>{data.email}</p>
          </div>
        </Col>
        <Col md='6'>
          <div className='model__property'>
            <h3 className='model__title'>
              <Translation id='Screens.Doctors.Read.MobileNumber' />
            </h3>
            <p className='model__value'>{data.mobileNumber ?? '-'}</p>
          </div>
        </Col>
        <Col md='6'>
          <div className='model__property'>
            <h3 className='model__title'>
              <Translation id='Shared.Birthdate' />
            </h3>
            <p className='model__value'>
              {moment(data.birthdate).format('LL')}
            </p>
          </div>
        </Col>
        <Col md='6'>
          <div className='model__property'>
            <h3 className='model__title'>
              <Translation id='Model.Admin.Notification' />
            </h3>
            <p className='model__value'>{data.notification}</p>
          </div>
        </Col>
        <Col md='6'>
          <div className='model__property'>
            <h3 className='model__title'>
              <Translation id='Model.User.Address1' />
            </h3>
            <p className='model__value'>
              {data.address1} {data.zipCode}, {data.city}
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

PersonalInformation.displayName = 'Screens_Doctors_Read_Personal_Information';
