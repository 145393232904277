import { Redirect, Route, Switch } from 'react-router-dom';

import { useOidc } from 'utils';
import { ProtectedRoute } from 'components';
import {
  Actualities,
  Admins,
  Applications,
  Banners,
  Customers,
  Dialogues,
  Doctors,
  FeeNotes,
  FileImportClients,
  FileImportConfigs,
  FileImports,
  IdentityVerifications,
  IdentityValidationsNhi,
  Marketing,
  Offers,
  OrganizationConfigs,
  Partners,
  PartnerOperations,
  PartnerOperators,
  Patients,
  Products,
  ProductTerms,
  ProductTermsResources,
  Profiles,
  Questionnaires,
  Roles,
  Sepa,
  Specialties,
  Statistics,
  TemplateMessages,
  Token,
  Universes,
  Vouchers,
  VoucherGroups,
  WebServices,
  Hangfire,
} from 'screens';
import { urlSchema } from 'utils';

const getHomePage = (roles) => {
  const usersRoles = Array.isArray(roles) ? roles : [roles];
  if (usersRoles.includes('StatisticsReader')) {
    return urlSchema.statistics.list;
  }
  if (usersRoles.includes('PartnerOperationsCRUD')) {
    return urlSchema.partnerOperations;
  }
  return urlSchema.patients.list;
};

const HomePageRoute = () => {
  const oidcUser = useOidc();
  const { roles } = oidcUser.profile;

  const url = getHomePage(roles);

  return (
    <Route exact path='/'>
      <Redirect to={url} />
    </Route>
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route path={urlSchema.hangfire} component={Hangfire} />
      <Route path={urlSchema.actualities.list} component={Actualities} />
      <Route path={urlSchema.doctors.list} component={Doctors} />
      <Route path='/Vouchers' component={Vouchers} />
      <Route path='/Universes' component={Universes} />
      <Route path='/Specialties' component={Specialties} />
      <Route path={urlSchema.administrators.list} component={Admins} />
      <Route path={urlSchema.fileImports} component={FileImports} />
      <Route path={urlSchema.universes.list} component={Universes} />
      <Route path={urlSchema.statistics.list} component={Statistics} />
      <Route
        path={urlSchema.fileImportClients.list}
        component={FileImportClients}
      />
      <Route path='/ProductTerms' component={ProductTerms} />
      <Route path='/ProductTermsResources' component={ProductTermsResources} />
      <Route path={urlSchema.clients.list} component={Customers} />
      <Route path={urlSchema.applications.list} component={Applications} />
      <Route path={urlSchema.offers} component={Offers} />
      <Route path={urlSchema.dialogues.read} component={Dialogues} />
      <Route
        path={urlSchema.organizationConfigs.list}
        component={OrganizationConfigs}
      />
      <Route path={urlSchema.partners} component={Partners} />
      <Route path={urlSchema.token} component={Token} />
      <Route path={urlSchema.products.list} component={Products} />
      <Route path={urlSchema.profiles.list} component={Profiles} />
      <Route path={urlSchema.patients.list} component={Patients} />
      <Route path={urlSchema.roles.list} component={Roles} />
      <Route path={urlSchema.vouchersGroups} component={VoucherGroups} />
      <Route path={urlSchema.fileImportConfigs} component={FileImportConfigs} />
      <Route
        exact
        path={urlSchema.partnerOperations}
        component={PartnerOperations}
      />
      <ProtectedRoute
        entity='Banners'
        right='Reader'
        path={urlSchema.banners.list}
        component={Banners}
      />
      <ProtectedRoute
        entity='Marketing'
        right='CRUD'
        path={urlSchema.marketing.search}
        component={Marketing}
      />
      <ProtectedRoute
        entity='Fees'
        right='CRUD'
        path={urlSchema.feesNotes}
        component={FeeNotes}
      />
      <ProtectedRoute
        entity='IdentityVerifications'
        right='CRUD'
        path={urlSchema.identityVerifications}
        component={IdentityVerifications}
      />
      <ProtectedRoute
        entity='IdentityValidationsNhi'
        right='CRUD'
        path={urlSchema.identityValidationsNhi}
        component={IdentityValidationsNhi}
      />
      <Route path={urlSchema.webServices.root} component={WebServices} />
      <ProtectedRoute
        entity='Fees'
        right='CRUD'
        path={urlSchema.sepa}
        component={Sepa}
      />
      <ProtectedRoute
        component={PartnerOperators}
        entity='Users'
        right='CRUD'
        path='/Users/PartnerOperators'
      />
      <ProtectedRoute
        entity='Questionnaires'
        right='Reader'
        path={urlSchema.questionnaires}
        component={Questionnaires}
      />
      <ProtectedRoute
        entity='TemplateMessages'
        right='Reader'
        path={urlSchema.templateMessages.list}
        component={TemplateMessages}
      />
      <HomePageRoute />
    </Switch>
  );
};

export default Routes;
