import moment from 'moment';
import { useIdentityVerificationNhiStore } from './store';
import { Spinner, Translation } from '../../components';
import { DocumentValidationBadge } from './DocumentValidationBadge';
import { useIdentityValidationsNhiQuery } from 'queries/useIdentityValidationsNhiQuery';

const TableHeader = () => (
  <div className='mt-3'>
    <div className='research-table__header'>
      <div className='container-fluid'>
        <div className='row fw-bold'>
          <div className='col-4'>
            <Translation id='Screens.IdentityValidationsIns.Table.Patient' />
          </div>
          <div className='col-3'>
            <Translation id='Screens.IdentityValidationsIns.Table.Status' />
          </div>
          <div className='col-3'>
            <Translation id='Screens.IdentityValidationsIns.Table.Sent' />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const Table = () => {
  const setSelectedPatientId = useIdentityVerificationNhiStore(
    (store) => store.setSelectedPatientId,
  );

  const {
    data: nhiUsers,
    isPending,
    isError,
  } = useIdentityValidationsNhiQuery();

  if (isPending) {
    return (
      <div>
        <TableHeader />
        <div className='py-4'>
          <Spinner color='primary' size='md' />
        </div>
      </div>
    );
  }

  if (isError) {
    throw new Error('Error while loading identity validations nhi');
  }

  if (nhiUsers.length === 0) {
    return (
      <div>
        <TableHeader />
        <div className='d-flex py-4 flex-column align-items-center'>
          <p className='text-secondary mb-0'>
            <Translation id='Screens.IdentityValidationsIns.NoResults' />
          </p>
        </div>
      </div>
    );
  }

  const sortedUsers = nhiUsers.sort((a, b) => {
    return (
      new Date(b.requestDate).getTime() - new Date(a.requestDate).getTime()
    );
  });

  return (
    <>
      <TableHeader />
      <div className='container-fluid'>
        {sortedUsers.map((user) => (
          <div key={user.patientId} className='row py-3 border-bottom'>
            <div className='col-4'>
              {user.firstname} {user.lastname} <br />
              <small className='text-muted'>
                <i className='uil uil-envelope mr-2' />
                {user.email}
              </small>
            </div>
            <div className='col-3 text-uppercase'>
              <DocumentValidationBadge
                documentValidationStatus={user.documentValidationStatus}
              />
            </div>
            <div className='col-3'>
              <span className='text-muted'>
                {moment(new Date(user.requestDate)).fromNow()}
              </span>
            </div>
            <div className='col-2 text-right'>
              <button
                disabled={false}
                className='btn btn-link list-action-anchor'
                onClick={() => setSelectedPatientId(user.patientId)}
                type='button'
              >
                <i aria-hidden className='uil uil-eye uil--md' />
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
