import React from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { RoleManager, Translation } from 'components';
import { api, urlSchema, useFetch } from 'utils';

const Profiles = ({ adminId, data, isLoading }) => {
  const [profiles, areProfilesLoading] = useFetch(api.profiles.get);

  return (
    <section className='section'>
      <header className='section__header'>
        <Translation id='Model.User.Profiles' tagName='h2' />
        <RoleManager entity='Admins' right='CRUD'>
          <Link
            className='btn btn-outline-primary btn-sm ml-auto'
            to={`${urlSchema.administrators.update}/${adminId}`}
          >
            <Translation id='Buttons.Manage' />
          </Link>
        </RoleManager>
      </header>
      <div className='section__body'>
        {isLoading || areProfilesLoading ? (
          <Spinner color='primary' size='lg' />
        ) : (
          data.map((id) => (
            <Link
              className='btn btn-outline-warning btn-sm mr-1 mb-1'
              key={id}
              to={`${urlSchema.profiles.read}/${id}`}
            >
              {profiles.find((element) => element.idProfile === id).label}
            </Link>
          ))
        )}
      </div>
    </section>
  );
};

export default Profiles;
