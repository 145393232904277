import * as yup from 'yup';

const fieldRequiredString = 'Model.FieldRequired';

export default (formatMessage) =>
  yup.object().shape({
    code: yup.string().required(fieldRequiredString),
    label: yup.string().required(fieldRequiredString),
    labelClientSellsy: yup
      .string()
      .required(formatMessage({ id: fieldRequiredString })),
    refClientSellsy: yup
      .string()
      .required(formatMessage({ id: fieldRequiredString })),
  });
